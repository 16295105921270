/**
 * When attempting to apply an advanced filter at render, the `value` should be verified depending on its type.
 * This is due to the existing implementation filters across the application (see FiltersModal/index.js)
 *
 * Value can indeed be a number, a string or an object with minValue and maxValue properties.
 */
export const isValidFilterValue = (value) => {
  if (Number.isFinite(value)) {
    return true;
  }

  if (typeof value === 'string' && value.trim() !== '') {
    return true;
  }

  if (typeof value === 'object' && value !== null) {
    return Number.isFinite(value.minValue) && Number.isFinite(value.maxValue);
  }

  return false;
};

// Utility function for better readability
export const hasValidAdvancedFilters = (advancedFilters) =>
  advancedFilters &&
  advancedFilters.length &&
  advancedFilters.every(({ value }) => isValidFilterValue(value));

/**
 * Goes through defined filters and filter the given data accordingly
 * See DeepsightAnalyticsHeaders/constants.js
 *
 * @param {*} advancedFilters as defined and used in most pages
 * @param {[]} data the data to be filtered
 * @returns An array containing the result of the filtering (can be empty)
 */
export const applyAdvancedFilters = (advancedFilters, data) =>
  advancedFilters.reduce(
    (filteredData, { propertyKey, doFilter, value }) => doFilter(filteredData, propertyKey, value),
    data,
  );
