import * as yup from 'yup';
import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const INPUTS = {
  INVOICE_DATE: 'invoiceDate',
  FILENAME: 'fileName',
  INVOICE_NUMBER: 'invoiceNumber',
  STORE: 'store',
  SUPPLIER: 'supplier',
  TOTAL: 'total',
};

const DEFAULT_DATA = { store: {}, supplier: {} };

export const FIELDS_TO_CHECK = ['invoiceNumber', 'invoiceDate', 'total'];

export const getInputs = (props, currency, storeName) => {
  const { link, store, supplier } = props || DEFAULT_DATA;

  return [
    {
      name: INPUTS.SUPPLIER,
      width: INPUT_WIDTH.LARGE,
      type: INPUT_TYPES.SINGLE_SELECT,
      label: `${i18next.t('GENERAL.SUPPLIER')} :`,
      isRequired: true,
      isDisabled: () => true,
      selectedItem: supplier,
    },
    {
      name: INPUTS.STORE,
      width: INPUT_WIDTH.LARGE,
      type: INPUT_TYPES.SINGLE_SELECT,
      label: `${getClientStoreNameTranslation(storeName)} :`,
      isRequired: true,
      isDisabled: () => true,
      selectedItem: store,
    },
    {
      name: INPUTS.FILENAME,
      type: INPUT_TYPES.TEXT,
      width: INPUT_WIDTH.LARGE,
      label: `* ${i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_LINK_FIELD',
      )}`,
      isRequired: true,
      isDisabled: () => true,
      link: link
        ? {
            onClick: () => {
              window.open(link, '_blank');
            },
            customStyle: { marginLeft: '4px' },
          }
        : null,
    },
    {
      name: INPUTS.INVOICE_NUMBER,
      type: INPUT_TYPES.TEXT,
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_NUMBER_FIELD'),
      isRequired: true,
    },
    {
      name: INPUTS.INVOICE_DATE,
      width: INPUT_WIDTH.LARGE,
      label: `${i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_DATE_FIELD',
      )} :`,
      type: INPUT_TYPES.SINGLE_DATEPICKER,
      showClearDate: false,
      rule: yup.date().nullable(),
    },
    {
      name: INPUTS.TOTAL,
      type: INPUT_TYPES.TEXT,
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD', {
        currency: currency && currency.alphabeticCode,
      }),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .nullable()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t(
                    'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD',
                    { currency: currency && currency.alphabeticCode },
                  ),
                  value: 0,
                }),
              ),
      ),
      isRequired: true,
    },
  ];
};

export default {
  getInputs,
};
