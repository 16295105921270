import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { CHANNELS } from '@commons/constants/channel';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import { getChannels } from '@services/channel';
import { product as productService } from '@services/product';

import { getChannelLabel, getCompositionLabel } from '../../utils/getProductExportLabels';
import { getPriceExcludingVAT } from '../../detail/components/ProductInfo/utils/getPriceExcludingVAT';

const DEFAULT_TITLE_EXPORT_FAILURE = i18next.t('GENERAL.EXPORT_FAILURE');
const DEFAULT_TITLE_EXPORT_READY = i18next.t('GENERAL.EXPORT_SUCCESS');
const BATCH_PRODUCTS_TO_FETCH_AT_ONCE = 50;

const FILENAME = i18next.t('GENERAL.PRODUCT_PLURAL');
const SHEET_NAME = i18next.t('GENERAL.PRODUCT_PLURAL');

const COLUMN_BRAND = {
  propertyKey: 'product.lnkBrandProductrel.name',
  name: i18next.t('GENERAL.BRAND'),
};

const COLUMN_CASHIER_PRODUCT_SKU = {
  propertyKey: 'product.cashierProductSku',
  name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_SYNC_CASHIER_PRODUCT_SKU'),
};

const COLUMNS_SETTINGS = [
  {
    propertyKey: 'product.id',
    name: i18next.t('GENERAL.ID'),
  },
  {
    propertyKey: 'product.name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    propertyKey: 'product.status',
    name: i18next.t('GENERAL.STATUS'),
  },
  {
    propertyKey: 'product.category',
    name: i18next.t('GENERAL.CATEGORY'),
  },
  {
    propertyKey: 'product.subCategory',
    name: i18next.t('GENERAL.SUB_CATEGORY'),
  },
  {
    propertyKey: 'product.composition',
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_PRODUCT_COMPOSITION'),
  },
  {
    propertyKey: 'recipe.channel',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_RECIPE_CHANNEL'),
  },
  {
    type: 'currency',
    propertyKey: 'recipe.recipeCost',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_RECIPE_COST'),
  },
  {
    type: 'currency',

    propertyKey: 'product.priceHT',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_PRICE_HT'),
  },
  {
    type: 'percentage',
    propertyKey: 'recipe.ratioCost',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_RECIPE_RATIO_COST'),
  },
  {
    propertyKey: 'product.vatRate',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_VAT_RATE'),
  },
  {
    type: 'currency',
    propertyKey: 'product.priceWithTaxes',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_PRICE_INCLUDING_TAXES'),
  },
  {
    propertyKey: 'product.sku',
    name: i18next.t('GENERAL.SKU'),
  },
  {
    propertyKey: 'product.shelfLife',
    name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_SHELF_LIFE'),
  },
];

/**
 * Format a given data related to a product and make sure to get all information
 * that would be necessary for generating the export file
 *
 * @param {Object} data - The data to format
 * @param {Object} data.product - The product being treated
 * @param {Object[]} data.recipes - The list of recipes associated to the product
 * @param {Object} defaultChannelId - The default channel id of the client
 *
 * @return {Object[]} The list of formatted data from the given product and its associated recipes
 */
export const formatDataForXLSFile = ({ product, recipes }, channels, defaultChannelId) => {
  product.status = product.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE');
  product.category = product.category || i18next.t('GENERAL.NONE_VALUE');
  product.subCategory = product.subCategory || i18next.t('GENERAL.NONE_VALUE');

  const priceHT = getPriceExcludingVAT(product);
  product.priceHT = priceHT || '';

  product.composition = getCompositionLabel(product.lnkEntityProductrel);

  if (!recipes.length) {
    return [
      {
        product,
        recipe: {
          channel: i18next.t('ADMIN.RECIPES.EXPORT_VALUE_NO_ENTITY'),
        },
      },
    ];
  }

  return recipes.map((recipe) => {
    const isIngredient = recipe.isIngredient;

    const channel = channels.find(({ name }) => name === recipe.channel) || {};

    const channelId = channel.id || defaultChannelId;

    const totalCost = Math.round((isIngredient ? recipe.cost : recipe.cost[channelId]) * 100) / 100;

    const formattedPriceHT =
      getPriceExcludingVAT(product, recipe.channel === CHANNELS.DELIVERY) || '';

    const totalRatio =
      formattedPriceHT && totalCost
        ? Math.round((totalCost / formattedPriceHT) * 100 * 100) / 100
        : '';

    return {
      product: {
        ...product,
        priceHT: formattedPriceHT,
        vatRate: recipe.channel === CHANNELS.DELIVERY ? product.deliveryVatRate : product.vatRate,
        priceWithTaxes:
          recipe.channel === CHANNELS.DELIVERY
            ? product.deliveryPriceWithTaxes
            : product.priceWithTaxes,
      },
      recipe: {
        ...recipe,
        recipeCost: totalCost,
        ratioCost: totalRatio,
        channel: getChannelLabel(recipe),
      },
    };
  });
};

/**
 * Update the progress value from the elements that are still left to treat
 *
 * @param {String[]} productIds           - The list of product ids that are still to be treated
 * @param {Object[]} detailedProductList  - The list of product that has already been treated
 * @param {Function} setProgress          - Method to set the local state of the progress value
 *
 * @return {void}
 */
export function updateProgress(productIds, detailedProductList, setProgress) {
  const productLeft = productIds.length;
  const totalProducts = productLeft + detailedProductList.length;
  const updatedProgress = 100 - (productLeft / totalProducts) * 100;

  setProgress(updatedProgress);
}

/**
 * Handle the progressive loading of products from the list of productIds that are still to fetch
 *
 * @returns {void}
 */
export async function loadProductsByBatchOfIds(
  productIds,
  detailedProductList,
  hasMultipleBrands,
  defaultChannelId,
  setLoading,
  setTitle,
  setProgress,
  setProductIds,
  setDetailedProductList,
  currency,
) {
  if (!productIds.length && !detailedProductList.length) {
    return;
  }

  // If no more ids, then all data has been fetched
  if (!productIds.length) {
    setProgress(100);

    if (hasMultipleBrands) {
      COLUMNS_SETTINGS.splice(2, 0, COLUMN_BRAND);
    }

    for (const list of detailedProductList) {
      const cashierProductSku = get(list, 'product.cashierProductSku', null);
      if (!!cashierProductSku) {
        COLUMNS_SETTINGS.push(COLUMN_CASHIER_PRODUCT_SKU);
        break;
      }
    }

    const sheet = utilsXLS.generateDefaultSheet(
      SHEET_NAME,
      COLUMNS_SETTINGS,
      detailedProductList,
      currency,
    );

    utilsXLS.makeXLS(FILENAME, [sheet]);

    return exportReady(setLoading, setTitle);
  }

  const selectedProductIds = productIds.splice(0, BATCH_PRODUCTS_TO_FETCH_AT_ONCE);

  // Perform request
  try {
    const productsExportData = await productService.exportProducts(selectedProductIds);

    let productItemsList = [...detailedProductList];

    const channels = await getChannels();
    // Format data
    for (const data of productsExportData) {
      const formattedData = formatDataForXLSFile(data, channels, defaultChannelId);

      productItemsList = productItemsList.concat(formattedData);
    }

    setDetailedProductList(productItemsList);

    updateProgress(productIds, productItemsList, setProgress);

    setProductIds(productIds);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }
}

/**
 * Close the modal
 *
 * @returns {void}
 */
export function exitModal(setLoading, closeModal) {
  setLoading(false);

  closeModal();
}

/**
 * Set component with export success state
 *
 * @returns {void}
 */
export function exportReady(setLoading, setTitle) {
  setTitle(DEFAULT_TITLE_EXPORT_READY);
  setLoading(false);
}

/**
 * Set component with export failure state
 *
 * @returns {void}
 */
export function exportFailure(setLoading, setTitle) {
  setTitle(DEFAULT_TITLE_EXPORT_FAILURE);
  setLoading(false);
}

export const ProductExportModal = (props) => {
  const {
    params: { title, products },
    client: { hasMultipleBrands, defaultChannelId },
    currency,
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [titleModal, setTitle] = useState(title || '');
  const [productIdsToProcess, setProductIds] = useState([]);
  const [detailedProductList, setDetailedProductList] = useState([]);

  useEffect(() => {
    const productIds = products.map((product) => product.id);

    setProductIds(productIds);
  }, [products]);

  useEffect(() => {
    loadProductsByBatchOfIds(
      [...productIdsToProcess],
      detailedProductList,
      hasMultipleBrands,
      defaultChannelId,
      setLoading,
      setTitle,
      setProgress,
      setProductIds,
      setDetailedProductList,
      currency,
    );
  }, [productIdsToProcess]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps, null)(ProductExportModal);
