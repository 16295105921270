import { connect } from 'react-redux';
import { get } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildSchema } from '@commons/GenericForm';
import { Button, HoverTooltip } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DisabledTooltip } from '@commons/DisabledTooltip';
import {
  getFileNameFromUrl,
  removeConcatenatedDateSuffixFromFileName,
} from '@commons/utils/fileUtils';
import { isForbiddenAPIError } from '@commons/utils/errors';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import WhiteCardForm from '@commons/WhiteCardForm';

import invoiceService from '@services/invoice';

import { getClientInfo } from '@selectors/client';

import { exportGapAnalysis, exportICLsOcerization } from '../commons/export';
import {
  formatAssociatedOrdersDataForExport,
  formatICLGapsDataForExport,
  formatICLInformationDataForExport,
  formatICLListDataForExport,
  formatICLOcerizationDataForExport,
} from '../commons/export/format';

import { INVOICE_STATUS } from '../commons/constants';

import utilsForm, { FIELDS_TO_CHECK } from './utils/form';

import { Container, ContainerContent, NavBarContainer, TitleContainer } from './styledComponents';
import {
  Container as ContainerWhiteCard,
  ContentContainer as ContentContainerWhiteCard,
  TitleContainer as TitleContainerWhiteCard,
} from './components/AssociatedOrders/styledComponents';
import AssociatedOrders from './components/AssociatedOrders';

export const InvoiceControlDetails = (props) => {
  const {
    // Props
    history,
    currency,
    client: { storeName },
    match: { path, params },
    // Dispatch methods
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  // Invoice
  const [invoiceControlList, setInvoiceControlList] = useState(null);
  const [isInvoiceLocked, setIsInvoiceLocked] = useState(false);
  const [hasAssociatedOrders, setHasAssociatedOrders] = useState(null);

  // Form
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [inputs, setInputs] = useState(
    utilsForm.getInputs(invoiceControlList, currency, storeName),
  );
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);

  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(
      buildSchema(utilsForm.getInputs(invoiceControlList, currency, storeName)),
    ),
  });

  const formFields = useWatch({
    control: form.control,
  });

  // Retrieve invoiceControlList information
  useEffect(() => {
    if (!params.id) {
      return;
    }

    getInvoiceById(params.id);
  }, [params.id]);

  // Initialize values
  useEffect(() => {
    if (!invoiceControlList) {
      return;
    }

    setIsInvoiceLocked(invoiceControlList.status === INVOICE_STATUS.FAILURE);

    setInputs(utilsForm.getInputs(invoiceControlList, currency, storeName));

    const originalFileName =
      getFileNameFromUrl(invoiceControlList.link, {
        withExtension: true,
        shouldDecodeURI: true,
      }) || '';

    // Remove the date suffix from the file name (e.g. invoice_231224080955.pdf -> invoiceControlList.pdf)
    // This suffix is automatically added during upload to ensure uniqueness of the file name
    const modifiedFileName = removeConcatenatedDateSuffixFromFileName(originalFileName);

    form.setValue('fileName', modifiedFileName);
    form.setValue('total', invoiceControlList.total);
    form.setValue('store', invoiceControlList.store);
    form.setValue('invoiceNumber', invoiceControlList.invoiceNumber);
    form.setValue(
      'invoiceDate',
      invoiceControlList.invoiceDate ? moment(invoiceControlList.invoiceDate) : null,
    );
    form.setValue('supplier', invoiceControlList.supplier);
  }, [invoiceControlList]);

  // Check whether form is dirty
  useEffect(() => {
    (async () => {
      const fieldsValidation = await form.trigger(FIELDS_TO_CHECK);

      setIsSaveDisabled(!fieldsValidation);

      const isFormDirty = get(form, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(form, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        // not being sensitive to type change (case on total property)
        (field) => invoiceControlList[field] != formFields[field],
      );

      if (isFormDirty && hasAtLeastOneDifferentField) {
        setIsSaveDisabled(false);

        return;
      }

      setIsSaveDisabled(true);
    })();
  }, [formFields]);

  const getInvoiceById = async (invoiceId) => {
    pageLoading();

    try {
      const result = await invoiceService.getById(invoiceId);

      setInvoiceControlList(result);
    } catch (error) {
      setInvoiceControlList(null);

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FETCH_ERROR'));

      if (isForbiddenAPIError(error)) {
        history.goBack();
      }
    } finally {
      pageLoaded();
    }
  };

  const validateForm = async () => {
    const fieldsValidation = await form.trigger();

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation) {
      setIsSaveDisabled(true);

      return;
    }

    form.handleSubmit(saveForm)();
  };

  const saveForm = async () => {
    const fieldsToUpdate = {
      invoiceDate: formFields.invoiceDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      total: formFields.total,
      invoiceNumber: formFields.invoiceNumber,
    };

    pageLoading();

    try {
      await invoiceService.updateById(invoiceControlList.id, fieldsToUpdate);

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_SUCCESS'));

      history.goBack();
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const exportOcerizationById = async () => {
    pageLoading();

    try {
      const fetchedICLOcerizationData = await invoiceService.getOcerizationById(
        invoiceControlList.id,
      );

      const formattedICLOcerizationData = formatICLOcerizationDataForExport(
        invoiceControlList,
        fetchedICLOcerizationData,
      );

      const associatedOrders = await invoiceService.getOrdersByInvoiceControlListId(
        invoiceControlList.id,
      );

      const formatICLListData = formatICLListDataForExport(invoiceControlList, associatedOrders);

      exportICLsOcerization(formattedICLOcerizationData, [formatICLListData], storeName, currency, {
        customFileName: i18next.t(
          'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_FILENAME',
          {
            date: moment(invoiceControlList.createdAt).format(
              DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
            ),
          },
        ),
      });
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const exportAnalysisById = async () => {
    pageLoading();

    try {
      const [fetchedGapsAnalysisData, associatedOrders] = await Promise.all([
        invoiceService.analyseAndExportInvoiceControlListDetails(invoiceControlList.id),
        invoiceService.getOrdersByInvoiceControlListId(invoiceControlList.id),
      ]);

      const formattedICLsGapsData = formatICLGapsDataForExport(fetchedGapsAnalysisData);

      const formattedICLInformationData = formatICLInformationDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      const formattedAssociatedOrdersData = formatAssociatedOrdersDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      exportGapAnalysis(
        formattedICLsGapsData,
        [formattedICLInformationData],
        formattedAssociatedOrdersData,
        storeName,
        currency,
      );

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  return (
    <>
      <NavBarContainer>
        <NavigationBar
          action={
            <Button
              color={'inpulse-default'}
              handleClick={validateForm}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={isSaveDisabled}
              label={i18next.t('GENERAL.SAVE')}
            />
          }
          bigTopBar={true}
          invoice={invoiceControlList}
          path={path}
        />
      </NavBarContainer>
      <Container>
        <ContainerContent>
          <WhiteCardForm
            form={form}
            inputs={inputs}
            isEditionAllowed={!isInvoiceLocked}
            shouldDisplayError={isSaveAlreadyTriggered}
            title={
              <TitleContainer>
                <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H2_INTER}>
                  {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_TITLE')}
                </Text>
                {isInvoiceLocked && (
                  <Text color={ENUM_COLORS.INFO_RED} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24}>
                    {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.WARNING_INVOICE_LOCKED')}
                  </Text>
                )}
              </TitleContainer>
            }
          />
        </ContainerContent>
        {invoiceControlList?.id && (
          <ContainerContent>
            <AssociatedOrders
              invoice={invoiceControlList}
              isInvoiceLocked={isInvoiceLocked}
              setHasAssociatedOrders={setHasAssociatedOrders}
            />
          </ContainerContent>
        )}
        <ContainerContent>
          <ContainerWhiteCard>
            <TitleContainerWhiteCard>
              {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.SECTION_ACTIONS_TITLE')}
            </TitleContainerWhiteCard>
            <ContentContainerWhiteCard>
              <Button
                buttonCustomStyle={{ paddingLeft: '0px' }}
                color={'inpulse-outline'}
                handleClick={exportOcerizationById}
                icon={
                  !invoiceControlList
                    ? '/images/inpulse/file-download-dmgrey-small.svg'
                    : '/images/inpulse/file-download-black-small.svg'
                }
                isDisabled={!invoiceControlList || isInvoiceLocked}
                label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION')}
                noBorder={true}
              />

              <HoverTooltip
                isTooltipDisplayed={!hasAssociatedOrders}
                renderContent={() => (
                  <DisabledTooltip
                    text={i18next.t(
                      'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_DISABLED_TOOLTIP',
                    )}
                  />
                )}
              >
                <Button
                  buttonCustomStyle={{ paddingLeft: '0px' }}
                  color={'inpulse-outline'}
                  handleClick={exportAnalysisById}
                  icon={
                    !invoiceControlList
                      ? '/images/inpulse/bolt-dmgrey-small.svg'
                      : '/images/inpulse/bolt-black-small.svg'
                  }
                  isDisabled={!hasAssociatedOrders || isInvoiceLocked}
                  label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_ANALYZE_AND_EXPORT')}
                  noBorder
                />
              </HoverTooltip>
            </ContentContainerWhiteCard>
          </ContainerWhiteCard>
        </ContainerContent>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControlDetails);
