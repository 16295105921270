import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { statusLegend } from '@orders/OrderList/common/constants';

import { INVOICE_CONTROL_STATUS_TRANSLATED } from '../constants';

// ICL list format
export const formatICLListDataForExport = (invoiceControlList, associatedOrders = []) => {
  const invoiceDate = get(invoiceControlList, 'invoiceDate', null);
  const importedAt = get(invoiceControlList, 'createdAt', null);

  const orderTotals = associatedOrders.reduce((acc, { orderTotal }) => acc + orderTotal, 0);

  return {
    ...invoiceControlList,
    invoiceDate: invoiceDate
      ? moment(invoiceDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    importedAt: importedAt
      ? moment(importedAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    invoiceNumber: invoiceControlList.invoiceNumber || '',
    nbAssociatedOrder: associatedOrders.length,
    gapExcludingTax: associatedOrders.length ? orderTotals - invoiceControlList.total : null,
  };
};

// Ocercization format
export const formatICLOcerizationDataForExport = (
  invoiceControlList,
  fetchedICLOcerizationData,
) => {
  const invoiceDate = get(invoiceControlList, 'invoiceDate', null);
  const importedAt = get(invoiceControlList, 'createdAt', null);

  const formattedICLsOcerizationDataToExport = fetchedICLOcerizationData.map((item) => ({
    ...item,
    storeName: get(invoiceControlList, 'store.name', ''),
    supplierName: get(invoiceControlList, 'supplier.name', ''),
    computedTotal: item.invoicePrice * item.invoiceQuantity,
    invoiceDate: invoiceDate
      ? moment(invoiceDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    importedAt: importedAt
      ? moment(importedAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    invoiceNumber: invoiceControlList.invoiceNumber || '',
  }));

  return formattedICLsOcerizationDataToExport;
};

// Gaps data format
export const formatICLGapsDataForExport = (fetchedGapsAnalysisData) =>
  fetchedGapsAnalysisData.map((iclGapData) => ({
    ...iclGapData,
    status: INVOICE_CONTROL_STATUS_TRANSLATED[iclGapData.status] || '',
  }));

// ICL information format
export const formatICLInformationDataForExport = (invoiceControlList, associatedOrders = []) => {
  const invoiceDate = get(invoiceControlList, 'invoiceDate', null);
  const importedAt = get(invoiceControlList, 'createdAt', null);

  const orderTotals = associatedOrders.reduce((acc, { orderTotal }) => acc + orderTotal, 0);

  return {
    ...invoiceControlList,
    storeName: get(invoiceControlList, 'store.name', ''),
    supplierName: get(invoiceControlList, 'supplier.name', ''),
    invoiceDate: invoiceDate
      ? moment(invoiceDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    importedAt: importedAt
      ? moment(importedAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '',
    invoiceNumber: invoiceControlList.invoiceNumber || '',
    invoicedTotal: invoiceControlList.total != null ? invoiceControlList.total : '',
    nbAssociatedOrder: associatedOrders.length,
    receivedTotal: orderTotals,
    gapExcludingTax: associatedOrders.length ? orderTotals - invoiceControlList.total : null,
  };
};

// Associated orders format
export const formatAssociatedOrdersDataForExport = (invoiceControlList, associatedOrders) => {
  const invoiceDate = get(invoiceControlList, 'invoiceDate', null);
  const importedAt = get(invoiceControlList, 'createdAt', null);

  return associatedOrders.map((order) => {
    const deliveryDate = get(order, 'startOrderDate', null);

    return {
      ...order,
      storeName: get(invoiceControlList, 'store.name', ''),
      supplierName: get(invoiceControlList, 'supplier.name', ''),
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
        : '',
      importedAt: importedAt
        ? moment(importedAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
        : '',
      invoiceNumber: invoiceControlList.invoiceNumber || '',
      invoicedTotal: invoiceControlList.total != null ? invoiceControlList.total : '',
      orderReference: order.reference || '',
      deliveryDate: deliveryDate
        ? moment(deliveryDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
        : '',
      orderStatus: i18next.t(statusLegend[order.status].name),
      receivedTotal: order.orderTotal || '',
    };
  });
};
