import { APIcaller } from './APIcaller';

const getCentralKitchenFoodCostByCategory = (storeIds, startDate, endDate) =>
  APIcaller.apiPost(`/analytics/central-kitchen/food-cost`, {
    storeIds,
    startDate,
    endDate,
  });

export const analyticsService = {
  getCentralKitchenFoodCostByCategory,
};
