import { capitalize, get, keyBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { DEFAULT_TIMEZONE, getStoresMostCommonTimezone } from '@commons/utils/timezone';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import CustomDrawer from '@commons/Drawer';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { supplier as supplierService } from '@services/supplier';
import invoiceService from '@services/invoice';

import { getClientInfo } from '@selectors/client';

import { exportGapAnalysis, exportICLList, exportICLsOcerization } from '../commons/export';
import {
  formatAssociatedOrdersDataForExport,
  formatICLGapsDataForExport,
  formatICLInformationDataForExport,
  formatICLListDataForExport,
  formatICLOcerizationDataForExport,
} from '../commons/export/format';
import { INVOICE_STATUS } from '../commons/constants';

import { getDeleteWarningModalParams } from './utils/modals';
import { getListActions, getRowActions } from './utils/actions';
import { getListColumns } from './utils/columns';

import InvoiceControlListsAnalyseAndExportModal from './components/invoiceControlListsAnalyseAndExportModal';
import InvoiceControlListsOcerizationExportModal from './components/invoiceControlListsOcerizationExportModal';
import resetOrderInvoiceAssociationModal from './components/resetOrderInvoiceAssociationModal';
import StoresCreditDrawerContent from './components/StoresCreditDrawerContent';

import {
  Container,
  ContentContainer,
  CreditInfoContainer,
  FilterButtonContainer,
  FilterContainer,
  LoaderContainer,
} from './styledComponents';

const theme = getTheme();

const INVOICE_MODAL_STEPS = {
  IMPORT_STEP: 0,
  CONTROL_STEP: 1,
  RESET_STEP: 2,
};

const INTERVAL_CHECK_NEWLY_AVAILABLE_INVOICE_IN_MS = 30 * 1000; // 30 seconds

const InvoiceControls = (props) => {
  const {
    match: { path },
    // redux states
    client: { clientId, storeName },
    currency,
    history,
    user,
    // dispatch methods
    openGenericModal,
    showErrorMessage,
    showSuccessMessage,
    openModalExportInfo,
    stores,
    refreshGenericModal,
    closeGenericModal,
    modal,
    pageLoading,
    pageLoaded,
  } = props;

  const clientStoreName = getClientStoreNameTranslation(storeName);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const storeIds = stores.map(({ id }) => id);

  moment.locale(userLanguageCode);

  const [columns] = useState(getListColumns(userLanguageCode, storeName));

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [invoiceControls, setInvoiceControls] = useState([]);
  const [selectedInvoiceControls, setSelectedInvoiceControls] = useState([]);
  const [filteredInvoiceControls, setFilteredInvoiceControls] = useState([]);
  const [displayEmptyStates, setDisplayEmptyStates] = useState(false);

  // Check asynchronous invoice controls
  const [pendingInvoiceControlsCount, setPendingInvoiceControlsCount] = useState(0);

  // Modal states
  const [invoiceControlModalStep, setInvoiceControlModalStep] = useState(-1);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [importNewSelectedSupplier, setImportNewSelectedSupplier] = useState({});
  const [importNewSelectedStore, setImportNewSelectedStore] = useState({});

  // Filter states
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  // Drawer states
  const [displayStoresCreditDrawer, setDisplayStoresCreditDrawer] = useState(false);
  const [drawerSubTitle, setDrawerSubTitle] = useState(
    capitalize(moment.tz(DEFAULT_TIMEZONE).format(DATE_DISPLAY_FORMATS.MONTH_YEAR)),
  );
  const [formattedCreditsByStoreId, setFormattedCreditsByStoreId] = useState({});
  const [isDrawerLoading, setIsDrawerloading] = useState(false);

  /** USE EFFECTS */
  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    const mostImportantTimezone = getStoresMostCommonTimezone(stores);

    setDrawerSubTitle(
      capitalize(moment.tz(mostImportantTimezone.timezone).format(DATE_DISPLAY_FORMATS.MONTH_YEAR)),
    );
  }, [stores]);

  useEffect(() => {
    if (!displayStoresCreditDrawer) {
      return;
    }

    getStoresAvailableCredits();
  }, [displayStoresCreditDrawer]);

  useEffect(() => {
    if (!pendingInvoiceControlsCount) {
      return;
    }

    const intervalSync = setInterval(
      checkForNewlyAvailableInvoices,
      INTERVAL_CHECK_NEWLY_AVAILABLE_INVOICE_IN_MS,
    );

    return () => clearInterval(intervalSync);
  }, [pendingInvoiceControlsCount]);

  useEffect(() => {
    if (!selectedSuppliers.length) {
      return;
    }

    (async () => await getInvoiceControls())();
  }, [selectedSuppliers]);

  useEffect(() => {
    const iclsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const hideExportAnalysis = iclsToExport.some(({ orders }) => !orders.length);

    setActions(
      getListActions({
        handleDeleteById,
        hideExportAnalysis,
        goToBatchImport,
        handleResetOrderInvoiceAssociation,
        selectedItems: selectedInvoiceControls,
        handleExport: handleExportInvoiceControl,
        handleOcerizationExport: handleICLsOcerizationExport,
        handleICLsExportAnalysisByICLIds: handleICLsExportAnalysisByICLIds,
      }),
    );

    setRowActions(
      getRowActions({
        handleDeleteById,
        handleOpenInvoice,
        handleExportAnalysisById,
        handleInvoiceOcerizationExport,
        handleResetOrderInvoiceAssociation,
      }),
    );
  }, [filteredInvoiceControls, selectedInvoiceControls]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const filteredInvoices = invoiceControls.filter((invoiceList) =>
      selectedSuppliers.some(
        ({ name }) => name.toUpperCase() === get(invoiceList, 'supplier.name', '').toUpperCase(),
      ),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredInvoiceControls(filteredInvoices);
      return;
    }

    const filteredInvoicesWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredInvoices,
    );

    setFilteredInvoiceControls(filteredInvoicesWithAdvancedFilters);
  }, [invoiceControls, applyFilters, advancedFilters]);

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    handleModalContentUpdate();
  }, [
    invoiceFile,
    editingInvoice,
    importNewSelectedStore,
    invoiceControlModalStep,
    importNewSelectedSupplier,
  ]);

  /** FUNCTIONS */
  const getStoresAvailableCredits = async () => {
    setIsDrawerloading(true);

    try {
      const creditsByStore = await invoiceService.getStoresAvailableCredits(storeIds);

      const storeKeyById = keyBy(stores, 'id');

      const formattedCreditsByStoreId = Object.entries(creditsByStore).reduce(
        (acc, [storeId, credits]) => {
          const store = storeKeyById[storeId];

          if (!store) {
            return acc;
          }

          acc[storeId] = {
            storeName: store.name,
            insufficientCredit: false,
            ...credits,
          };

          return acc;
        },
        {},
      );

      setFormattedCreditsByStoreId(formattedCreditsByStoreId);
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.FETCH_AVAILABLE_CREDITS_ERROR'));
    } finally {
      setIsDrawerloading(false);
    }
  };

  const getSuppliers = async () => {
    setIsLoading(true);
    try {
      const storeIds = stores.map(({ id }) => id);

      const suppliersOfStores = await supplierService.getSuppliersOfStores(storeIds);

      setSelectedSuppliers(suppliersOfStores);
      setSuppliers(suppliersOfStores);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    }
  };

  const getInvoiceControls = async () => {
    setIsLoading(true);

    try {
      const supplierIds = selectedSuppliers.map(({ id }) => id);
      // [TODO Invoice Control] Filter by user master catalog

      const fetchedInvoiceControls = await invoiceService.getInvoiceControlList(
        clientId,
        supplierIds,
      );

      setDisplayEmptyStates(!fetchedInvoiceControls.length);

      if (!fetchedInvoiceControls.length) {
        return;
      }

      // Make distinction between invoiceList being treated and those available
      const { availableInvoiceControls, pendingInvoiceControls } = fetchedInvoiceControls.reduce(
        (result, item) => {
          if ([INVOICE_STATUS.SUCCESS, INVOICE_STATUS.FAILURE].includes(item.status)) {
            result.availableInvoiceControls.push(item);
          }

          if (item.status === INVOICE_STATUS.PENDING) {
            result.pendingInvoiceControls.push(item);
          }

          return result;
        },
        { availableInvoiceControls: [], pendingInvoiceControls: [] },
      );

      // Handle available invoices
      const formattedInvoiceControls = availableInvoiceControls.map((invoice) => ({
        ...invoice,
        computedOrderReferences: invoice.orders.map(({ reference }) => reference).join(';'), // to make search on this field possible
        gapExcludingTax: invoice.orders.length ? invoice.ordersTotal - invoice.total : null,
        nbAssociatedOrder: invoice.orders.length,
        storeName: get(invoice, 'store.name', ''),
        supplierName: get(invoice, 'supplier.name', ''),
      }));

      setInvoiceControls(formattedInvoiceControls);
      setPendingInvoiceControlsCount(pendingInvoiceControls.length);
    } catch {
      setInvoiceControls([]);

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const checkForNewlyAvailableInvoices = async () => {
    try {
      // [TODO Invoice Control] Filter by user master catalog
      const supplierIds = suppliers.map(({ id }) => id);

      const result = await invoiceService.getInvoiceControlList(clientId, supplierIds);

      const pendingInvoiceControls = result.filter(
        ({ status }) => status === INVOICE_STATUS.PENDING,
      );

      if (pendingInvoiceControls.length !== pendingInvoiceControlsCount) {
        await getInvoiceControls();
      }
    } catch (err) {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_FETCH_ERROR'));
    }
  };

  const deleteInvoiceControlListById = async (invoiceControlListById) => {
    pageLoading();

    try {
      await invoiceService.deleteById(invoiceControlListById);

      getInvoiceControls();
    } catch (err) {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_DELETE_INVOICE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleExportInvoiceControl = () => {
    const invoiceControlsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const formattedICLsListData = invoiceControlsToExport.map((invoiceControlList) => {
      const formattedOrders = invoiceControlList.orders.map((order) => ({
        ...order,
        orderTotal: order.orderTotalPrice,
      }));

      return formatICLListDataForExport(invoiceControlList, formattedOrders);
    });

    exportICLList(formattedICLsListData, currency, storeName);
  };

  // Can be multiple export in global actions
  const handleICLsOcerizationExport = () => {
    const iclToOcerizarionExports = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const invoiceControlListIds = iclToOcerizarionExports.map(({ id }) => id);

    openModalExportInfo({
      component: InvoiceControlListsOcerizationExportModal,
      invoiceControlListIds,
      currency,
      storeName,
    });
  };

  const handleICLsExportAnalysisByICLIds = () => {
    const iclsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const invoiceControlListIds = iclsToExport.map(({ id }) => id);

    openModalExportInfo({
      component: InvoiceControlListsAnalyseAndExportModal,
      invoiceControlListIds,
      currency,
      storeName,
    });
  };

  // Unique export in row actions
  const handleInvoiceOcerizationExport = async (invoiceControlListId) => {
    try {
      const invoiceControlList = await invoiceService.getById(invoiceControlListId);

      const fetchedICLOcerizationData = await invoiceService.getOcerizationById(
        invoiceControlListId,
      );

      const formattedICLOcerizationData = formatICLOcerizationDataForExport(
        invoiceControlList,
        fetchedICLOcerizationData,
      );

      const associatedOrders = await invoiceService.getOrdersByInvoiceControlListId(
        invoiceControlList.id,
      );

      const formatICLListData = formatICLListDataForExport(invoiceControlList, associatedOrders);

      exportICLsOcerization(formattedICLOcerizationData, [formatICLListData], storeName, currency, {
        customFileName: i18next.t(
          'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_FILENAME',
          {
            date: moment(invoiceControlList.createdAt).format(
              DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
            ),
          },
        ),
      });
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_ERROR'),
      );
    }
  };

  const handleExportAnalysisById = async (invoiceControlListId) => {
    pageLoading();

    try {
      const [invoiceControlList, fetchedGapsAnalysisData, associatedOrders] = await Promise.all([
        invoiceService.getById(invoiceControlListId),
        invoiceService.analyseAndExportInvoiceControlListDetails(invoiceControlListId),
        invoiceService.getOrdersByInvoiceControlListId(invoiceControlListId),
      ]);

      const formattedICLsGapsData = formatICLGapsDataForExport(fetchedGapsAnalysisData);

      const formattedICLInformationData = formatICLInformationDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      const formattedAssociatedOrdersData = formatAssociatedOrdersDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      exportGapAnalysis(
        formattedICLsGapsData,
        [formattedICLInformationData],
        formattedAssociatedOrdersData,
        storeName,
        currency,
      );
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleOpenInvoice = async (invoiceControlList) => {
    if (!invoiceControlList.link) {
      return;
    }

    window.open(invoiceControlList.link);
  };

  const handleDeleteById = (invoiceControlListId) => {
    const params = getDeleteWarningModalParams({
      invoiceControlListId,
      deleteById: deleteInvoiceControlListById,
    });

    openGenericModal(params);
  };

  const resetOrderInvoiceAssociation = async (invoiceControlListId) => {
    pageLoading();

    setInvoiceControlModalStep(INVOICE_MODAL_STEPS.CONTROL_STEP);

    try {
      await invoiceService.resetOrderInvoiceAssociation(
        invoiceControlListId,
        importNewSelectedStore.id,
        importNewSelectedSupplier.id,
      );

      getInvoiceControls();

      showSuccessMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_SUCCESS'),
      );
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_ERROR'),
      );
    } finally {
      handleCloseCleanUp();
      pageLoaded();
    }
  };

  const handleResetOrderInvoiceAssociation = (invoice) => {
    setEditingInvoice(invoice);

    const selectedStore = stores.find(({ id }) => invoice.storeId === id);

    setImportNewSelectedStore(selectedStore);

    const selectedSupplier = suppliers.find(({ id }) => invoice.supplierId === id);

    setImportNewSelectedSupplier(selectedSupplier);

    setInvoiceControlModalStep(INVOICE_MODAL_STEPS.RESET_STEP);

    const params = resetOrderInvoiceAssociationModal.getModalConfig({
      stores,
      invoice,
      suppliers,
      selectedStore,
      selectedSupplier,
      clientStoreName,
      setSelectedStore: setImportNewSelectedStore,
      setSelectedSupplier: setImportNewSelectedSupplier,
      callback: resetOrderInvoiceAssociation,
      handleCloseCleanUp,
    });

    openGenericModal(params);
  };

  const goToDetailsPage = (invoiceControlList) => {
    history.push(`/invoice/invoice-controls/${invoiceControlList.id}/details`);
  };

  const goToBatchImport = () => {
    history.push('/invoice/invoice-controls/batch-import');
  };

  const handleModalContentUpdate = () => {
    let params = {};

    switch (invoiceControlModalStep) {
      case INVOICE_MODAL_STEPS.RESET_STEP:
        params = resetOrderInvoiceAssociationModal.getModalConfig({
          stores,
          suppliers,
          clientStoreName,
          invoice: editingInvoice,
          selectedStore: importNewSelectedStore,
          selectedSupplier: importNewSelectedSupplier,
          setSelectedStore: setImportNewSelectedStore,
          setSelectedSupplier: setImportNewSelectedSupplier,
          callback: resetOrderInvoiceAssociation,
          handleCloseCleanUp,
        });
        break;
      default:
        return;
    }

    refreshGenericModal(params);
  };

  const handleCloseCleanUp = () => {
    setImportNewSelectedSupplier({});
    setImportNewSelectedStore({});
    setEditingInvoice(null);
    setInvoiceFile(null);

    closeGenericModal();
    setInvoiceControlModalStep(-1);
  };

  const handleDrawerClose = () => {
    setDisplayStoresCreditDrawer(false);
  };

  if (displayEmptyStates) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={path} />
        <ContentContainer>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/catalogs.svg'}
            renderAction={() => (
              <Button
                color="inpulse-default"
                handleClick={goToBatchImport}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ADD_NEW_CONTROL')}
              />
            )}
            subtitle={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_EMPTY_STATE_SUBTITLE')}
            title={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_EMPTY_STATE_TITLE')}
          />
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <ListView
          actionOnClick={goToDetailsPage}
          actions={actions}
          columns={columns}
          data={filteredInvoiceControls}
          defaultOrderBy={'createdAt'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          markerConfiguration={{
            isHidden: ({ status }) => status === INVOICE_STATUS.SUCCESS,
            backgroundColor: theme.colors.infoOrange,
            icon: { src: '/images/inpulse/warning-white-small.svg' },
          }}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <FilterButtonContainer>
              <FilterContainer>
                <DeepsightFiltersButton
                  advancedFilters={advancedFilters}
                  applyFilters={applyFilters}
                  columnsFilterList={columns.filter(({ filterType }) => !!filterType)}
                  filters={filters}
                  isLoading={isLoading}
                  readOnly={isLoading}
                  selectedSuppliers={selectedSuppliers}
                  setAdvancedFilters={setAdvancedFilters}
                  setApplyFilters={setApplyFilters}
                  setFilters={setFilters}
                  setSelectedSuppliers={setSelectedSuppliers}
                  suppliers={suppliers}
                  textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                />
              </FilterContainer>
              <CreditInfoContainer>
                <Button
                  buttonCustomStyle={{ padding: '0px' }}
                  color={'inpulse-outline'}
                  handleClick={() => setDisplayStoresCreditDrawer(true)}
                  icon={'/images/inpulse/list-bordered-black.svg'}
                  iconCustomStyle={{ width: '24px', height: '24px' }}
                  iconOnLeft={false}
                  label={i18next.t('INVOICE.INVOICE_CONTROLS.VIEW_CREDITS')}
                  noBorder
                />
              </CreditInfoContainer>
              <CreditInfoContainer>
                <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
                  {i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROL_IN_PROGRESS', {
                    count: pendingInvoiceControlsCount,
                  })}
                </Text>
                {pendingInvoiceControlsCount > 0 && (
                  <LoaderContainer>
                    <DeepsightComponentLoader height={16} width={16} />
                  </LoaderContainer>
                )}
              </CreditInfoContainer>
            </FilterButtonContainer>
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedInvoiceControls}
        />
      </ContentContainer>
      <CustomDrawer
        isOpened={displayStoresCreditDrawer}
        overlayOpacity={0}
        onClick={handleDrawerClose}
      >
        <StoresCreditDrawerContent
          credits={Object.values(formattedCreditsByStoreId)}
          isLoading={isDrawerLoading}
          subtitle={drawerSubTitle}
          onClose={handleDrawerClose}
        />
      </CustomDrawer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControls);
