import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { isForbiddenAPIError } from '@commons/utils/errors';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { canSeeAllCatalogs } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

// SERVICES
import catalogService from '@services/catalogs';
import storageAreaService from '@services/storageArea';

import { ContentContainer, PageContainer } from '../styledComponents';
import StorageAreaMappings from '../storageAreaMappings';

const StorageAreaSupplierProducts = (props) => {
  const {
    history,
    match: { path, params },
    client: { clientId, hasLocalCatalogs },
    pageLoaded,
    pageLoading,
    showErrorMessage,
    user,
  } = props;

  const storageAreaId = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [storageArea, setStorageArea] = useState({});
  const [catalogs, setCatalogs] = useState([]);

  const getClientCatalogs = async () => {
    if (!hasLocalCatalogs) {
      return;
    }

    try {
      const clientCatalogs = await catalogService.getCatalogsByClientId(clientId);

      const filteredCatalogs = clientCatalogs.filter(({ active }) => active);

      if (!canSeeAllCatalogs(user, { hasLocalCatalogs })) {
        setCatalogs([user.catalog]);

        return;
      }

      setCatalogs(filteredCatalogs);

      return;
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
      setCatalogs([]);
    }
  };

  const getStorageArea = async () => {
    try {
      const fetchedStorageArea = await storageAreaService.getStorageAreaById(clientId, params.id);

      const mappings = fetchedStorageArea.supplierProductMappings || [];

      const formattedSPMappings = mappings.map((mapping) => {
        const supplierRelation = mapping.lnkSupplierProductStorageareamappingrel || {};

        return {
          ...mapping,
          supplierName: supplierRelation.supplierName,
          catalogName: supplierRelation.catalogName,
          catalogId: supplierRelation.catalogId,
          active: supplierRelation.active,
        };
      });

      setStorageArea({
        ...fetchedStorageArea,
        supplierProductMappings: formattedSPMappings,
      });
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.STORAGE_AREA_DETAIL_FETCHING_ERROR'));

      if (isForbiddenAPIError(error)) {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      const filteredCatalogs = await getClientCatalogs();

      await getStorageArea(filteredCatalogs);

      setIsLoading(false);
      pageLoaded();
    })();
  }, []);

  return (
    <>
      <NavigationBar
        module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
        path={path}
        storageArea={{
          id: storageAreaId,
          name: storageArea.name,
        }}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer>
          <StorageAreaMappings
            catalogs={catalogs}
            existingStorageArea={storageArea || null}
            isLoading={isLoading}
            storeIds={storageArea.storeId ? [storageArea.storeId] : []}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreaSupplierProducts);
