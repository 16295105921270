import { orderBy, sortBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

import {
  getAssociatedOrdersSheetColumns,
  getGapSheetColumns,
  getICLExportListColumns,
  getICLInformationsSheetColumns,
  getOcerizationSheetColumns,
} from './columns';

// ICL list sheet
export const getICLExportListSheet = (
  invoiceControlLists,
  clientStoreName,
  currency,
  { title = null } = {},
) => {
  const sheetName = title || i18next.t('GENERAL.EXPORT_LIST_XLS');

  const columns = getICLExportListColumns(clientStoreName);

  const formattedICLData = sortBy(invoiceControlLists, ({ supplier }) =>
    supplier.name.toLowerCase(),
  );

  const sheet = {
    data: formattedICLData,
    title: sheetName,
    headers: columns,
  };

  return utilsXLS.generateDefaultSheet(sheet.title, sheet.headers, sheet.data, currency);
};

// Ocerization sheet
export const getICLOcerizationSheet = (formattedICLsOcerizationData, clientStoreName, currency) => {
  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_MAIN_SHEET_NAME',
  );

  const columns = getOcerizationSheetColumns(clientStoreName);

  const sheet = {
    data: formattedICLsOcerizationData,
    title: sheetName,
    headers: columns,
  };

  return utilsXLS.generateDefaultSheet(sheet.title, sheet.headers, sheet.data, currency);
};

// Gaps sheet
export const getGapsSheet = (formattedGapsData, currency) => {
  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_GAPS_SHEET_NAME',
  );

  const columns = getGapSheetColumns();

  const sortedGapsData = orderBy(formattedGapsData, 'totalGap', 'desc');

  const sheet = {
    data: sortedGapsData,
    title: sheetName,
    headers: columns,
  };

  return utilsXLS.generateDefaultSheet(sheet.title, sheet.headers, sheet.data, currency);
};

// ICL information sheet
export const getICLInformationSheet = (formattedICLsInformationData, storeName, currency) => {
  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_SECOND_SHEET_NAME',
  );

  const columns = getICLInformationsSheetColumns(storeName);

  const sortedICLsInformationData = orderBy(formattedICLsInformationData, 'totalGap', 'desc');

  const sheet = {
    data: sortedICLsInformationData,
    title: sheetName,
    headers: columns,
  };

  return utilsXLS.generateDefaultSheet(sheet.title, sheet.headers, sheet.data, currency);
};

// Associated orders sheet
export const getAssociatedOrdersSheet = (formattedAssociatedOrdersData, storeName, currency) => {
  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_ASSOCIATED_ORDERS_SHEET_NAME',
  );

  const columns = getAssociatedOrdersSheetColumns(storeName);

  const sortedAssociatedOrdersData = sortBy(formattedAssociatedOrdersData, ({ invoiceNumber }) =>
    invoiceNumber.toLowerCase(),
  );

  const sheet = {
    data: sortedAssociatedOrdersData,
    title: sheetName,
    headers: columns,
  };

  return utilsXLS.generateDefaultSheet(sheet.title, sheet.headers, sheet.data, currency);
};
