import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { ENTITY_UNITS } from '@commons/constants/units';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

export const StocksAnalyticsCurrentExportModal = (props) => {
  const {
    params: { title, mainSheetData },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  useEffect(() => {
    (async function launchExport() {
      try {
        const mainSheet = createMainSheet();
        utilsXLS.makeXLS(i18next.t('STOCKS.CURRENT_STOCKS.EXPORT_FILENAME'), [mainSheet]);
        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
        exitModal();
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  const getMainSheetColumnsSettings = () => [
    {
      displayName: i18next.t('GENERAL.NAME'),
      keyName: 'name',
      transform: (value) => value,
    },
    {
      displayName: i18next.t('STOCKS.CURRENT_STOCKS.TYPE_OF_STOCK'),
      keyName: 'isReal',
      transform: (value) =>
        value
          ? i18next.t('STOCKS.CURRENT_STOCKS.STOCK_REAL')
          : i18next.t('STOCKS.CURRENT_STOCKS.STOCK_THEORETICAL'),
    },
    {
      displayName: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_PREVIOUS_DAY_STOCK'),
      keyName: 'stockValue',
      transform: (value) => value,
    },
    {
      displayName: i18next.t('ANALYSIS.STOCKS.DAILY_FORECASTED_CONSUMPTION_FULL'),
      keyName: 'meanForecastedConsumption',
      transform: (value) => value,
    },
    {
      displayName: i18next.t('GENERAL.UNIT'),
      keyName: 'stockUnit',
      transform: (value) => (value === ENTITY_UNITS.UNIT ? 'unité' : value),
    },
    {
      displayName: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_DAYS_BEFORE_STOCKOUT'),
      keyName: 'daysBeforeBreakage',
      transform: (value) => {
        // When no day of breakage could be determined there is always a positive stock on the range of time
        if (value === null) {
          return i18next.t('GENERAL.IN_15_DAYS_AND_MORE');
        }

        if (value >= 1) {
          return i18next.t('ANALYSIS.STOCKS.DAYS_BEFORE_BREAKAGE', {
            count: value,
            value,
          });
        }

        if (value <= 0) {
          return i18next.t('GENERAL.IN_BREAKAGE');
        }

        return '';
      },
    },
  ];

  const createMainSheet = () => {
    const headersSettings = getMainSheetColumnsSettings();

    const headers = headersSettings.map(({ displayName }) => displayName);

    const mappedData = mainSheetData.map((item) =>
      headersSettings.map(({ keyName, transform }) => {
        if (keyName !== 'isReal' && keyName !== 'daysBeforeBreakage' && !_.get(item, keyName)) {
          return '';
        } else {
          return transform(_.get(item, keyName));
        }
      }),
    );

    return {
      title: i18next.t('STOCKS.CURRENT_STOCKS.EXPORT_SHEET_NAME'),
      headers,
      data: mappedData,
    };
  };

  const exitModal = () => {
    setIsLoading(false);
    closeModal();
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showConfirmationMessage(message, 'error'));
  },
});

export default connect(null, mapDispatchToProps)(StocksAnalyticsCurrentExportModal);
