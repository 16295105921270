import { capitalize, cloneDeep, get, sortBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { Button, NestedList, ToggleButton } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Filters } from '@commons/Filters';
import { formatNumberToExcelUsage, getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import {
  GenericContentContainer,
  GenericGapContainer,
  GenericHeaderContainer,
  GenericPageContainer,
} from '@commons/Layout/styledComponents';
import { getFormattedCurrencyName } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { METRIC_KEY } from '@commons/constants/metricKey';
import EmptyState from '@commons/EmptyState';
import KpiDisplay from '@commons/KpiDisplay';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import RangeDatePicker from '@orders/OrderAnalyticsModulesContainer/OrderAnalyticsCategory/components/RangeDatePicker';

import { useFiltersButtonState } from '@hooks/useFiltersButtonState';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';
import useLocalStorage from '@hooks/useLocalStorage';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';

import { openSmallModal } from '@actions/modal.js';

import { analyticsService } from '@services/analytics';

import { OrderAnalyticsByCategoryExportModal } from '@orders/OrderAnalyticsModulesContainer/OrderAnalyticsCategory/components/OrderAnalyticsCategoryExportModal/index.js';
import orderService from '@orders/OrderAnalyticsModulesContainer/OrderAnalyticsCategory/utils/fetch.js';

import { applyAdvancedFilters, hasValidAdvancedFilters } from './utils';
import { getEntityUnit } from '@stocks/utils/index.js';
import { getFoodCostColumns } from './columns';
import { NestedListContainer } from './styledComponents';

const FILTER_TYPES = ['date', 'string', 'numeric'];

const AnalyticsFoodCostByCategory = (props) => {
  const {
    kitchenStores,
    salesPointStores,
    currency,
    showMessage,
    showErrorMessage,
    openSmallModal,
  } = props;

  const USER_TIMEZONE = getUserTimezone();
  const YESTERDAY = moment.tz(USER_TIMEZONE).subtract(1, 'day');
  const METRICS = [
    {
      key: 'turnover',
      name: capitalize(getFormattedCurrencyName(currency.shortenedName, true)),
    },
    { key: METRIC_KEY.UNIT, name: i18next.t('GENERAL.UNIT') },
  ];

  const path = get(props.match, 'path');

  // Depending on this, different:
  // - stores (kitchenStores or salesPointStores)
  // - endpoint for fetching data
  // - filters (no brands, retailers, locations & groups for kitchenStores)
  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  const stores = isInCentralMode ? kitchenStores : salesPointStores;

  /*******************
   ***** STATES *****
   *******************/

  // Loader states
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const filtersButtonState = useFiltersButtonState(advancedFilters);

  // Brands dropdown
  // eslint-disable-next-line no-unused-vars
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Retailers dropdown
  // eslint-disable-next-line no-unused-vars
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  // Locations dropdown
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Stores dropdown
  const [selectedStores, setSelectedStores] = useState(stores);

  const periodPickerState = usePeriodDatePickerState(
    moment(YESTERDAY).subtract(1, 'month'),
    YESTERDAY,
    USER_TIMEZONE,
  );
  // eslint-disable-next-line no-unused-vars
  const [maxFutureDate, setMaxFutureDate] = useState(null);

  const [startMonth, setStartMonth] = useState(
    moment
      .tz(USER_TIMEZONE)
      .subtract(1, 'month')
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );
  const [endMonth, setEndMonth] = useState(
    moment.tz(USER_TIMEZONE).endOf('month').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );
  const [formattedInventoryStockDay, setFormattedInventoryStockDay] = useState({});

  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);

  const [headers, setHeaders] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [turnoverByStoreId, setTurnoverByStoreId] = useState(null);
  const [totalLossByStoreId, setTotalLossByStoreId] = useState(null);
  const [totalRow, setTotalRow] = useState(null);
  const [turnover, setTurnover] = useState(null);
  const [lossRate, setLossRate] = useState(null);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(false);

  const columnsFilterList = headers?.filter((column) => FILTER_TYPES.includes(column.filterType));

  /*******************
   ***** EFFECTS *****
   *******************/
  // First page load
  useEffect(() => {
    if (!stores || !stores.length) {
      return;
    }

    resetHeaders();
  }, []);

  // Handles metrics toggle switch
  useEffect(() => {
    resetHeaders();
  }, [selectedMetric]);

  // Handles analytics fetch and formatting
  useEffect(() => {
    if (selectedStores.length === 0 || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setIsLoading(true);
    setIsLoadingFilters(true);

    (async function loadData() {
      try {
        const result = await fetchAnalytics();

        const { total, dataByCategory, turnoverByStoreId, lossByStoreId } = result;

        const formattedTotalRow = formatTotalRow(total);
        const formattedData = formatCategoryData(dataByCategory);

        setAnalyticsData(formattedData);
        setTurnoverByStoreId(turnoverByStoreId);
        setTotalLossByStoreId(lossByStoreId);
        setTotalRow(formattedTotalRow);

        const totalTurnover = turnoverByStoreId.total;
        setTurnover(getNumberFormattedWithDecimals(totalTurnover, 0));

        if (totalTurnover) {
          const computedLossRate = (total[METRIC_KEY.TURNOVER].loss * 100) / totalTurnover;

          setLossRate(getNumberFormattedWithDecimals(computedLossRate, 2));
        }

        resetHeaders();
      } catch {
        showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));

        resetStates();

        setIsRetrievingAnalytics(false);
      } finally {
        setIsLoading(false);
        setIsLoadingFilters(false);
      }
    })();
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  // Retrieve inventory dates when single store being selected
  useEffect(() => {
    if (selectedStores.length !== 1) {
      return;
    }

    const storeIds = selectedStores.map(({ id }) => id);

    try {
      (async function loadData() {
        const formattedDate = await orderService.fetchInventoryDateOfStoreByDates(
          storeIds,
          startMonth,
          endMonth,
          USER_TIMEZONE,
          showMessage,
        );

        setFormattedInventoryStockDay(formattedDate);
      })();
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
    }
  }, [selectedStores, startMonth, endMonth]);

  /*******************
   ***** METHODS *****
   *******************/
  const fetchAnalytics = async () => {
    let result;

    const storeIds = selectedStores.map(({ id }) => id);

    if (isInCentralMode) {
      result = await analyticsService.getCentralKitchenFoodCostByCategory(
        storeIds,
        periodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        periodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    } else {
      // From fetchAnalytics in OrderAnalyticsModulesContainer/OrderAnalyticsCategory/utils/fetch.js
      result = await orderService.getOrdersAnalyticsByCategory(
        storeIds,
        periodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        periodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    }
    return result;
  };

  const resetStates = () => {
    setAnalyticsData(null);
    setTurnoverByStoreId(null);
    setTotalLossByStoreId(null);
    setTotalRow(null);
    setTurnover(null);
    setLossRate(null);
  };

  const resetHeaders = () => {
    setHeaders(
      getFoodCostColumns(
        currency,
        periodPickerState.startDate,
        periodPickerState.endDate,
        selectedMetric.key,
        isInCentralMode,
      ),
    );
  };

  // Format the Total row to be displayed at the top of the NestedList (refer to NestedList's fixedRowsData prop)
  const formatTotalRow = (total) =>
    METRICS.reduce((result, { key }) => {
      result[key] = {
        ...total[key],
        name: i18next.t('GENERAL.TOTAL').toUpperCase(),
        isFixed: true,
      };
      return result;
    }, {});

  // Make sure each ingredient listed in the expanded sections has a direct link to its details page
  const formatEntities = (entities, key) =>
    entities.map(({ id, name, unit, stats }) => ({
      id,
      name,
      unit,
      link: `/admin/products/ingredients/${id}/details`,
      ...stats[key],
    }));

  /**
   * Format the nestedData property for each category (refer to NestedList's hasNestedData prop)
   * @returns {Object} - of shape {
   *  turnover: [{ name: <categoryName>, nestedData: [<entitiesData>] }],
   *  unit: [{ name: <categoryName>, nestedData: [<entitiesData>] }]
   * }
   **/
  const formatCategoryData = (dataByCategory) =>
    METRICS.reduce((result, { key }) => {
      result[key] = Object.entries(dataByCategory).map(([category, { stats, entities }]) => ({
        name: category,
        ...stats[key],
        nestedData: formatEntities(entities, key),
      }));
      return result;
    }, {});

  const exportAnalytics = () => {
    const filteredData = filterData();

    const data = METRICS.reduce(
      (acc, metric) => {
        filteredData[metric.key].forEach((element) => {
          const childItems = get(element, 'nestedData', null);
          const category = get(element, 'name', null);

          if (childItems) {
            childItems.forEach((entity) => {
              const result = {
                ...entity,
                category,
                unit: getEntityUnit(entity.unit, false),
              };

              if (metric.key === METRIC_KEY.TURNOVER) {
                acc.turnoverSheetData.push(result);
              }

              if (metric.key === METRIC_KEY.UNIT) {
                acc.unitSheetData.push(result);
              }
            });
          }
        });

        return acc;
      },
      { turnoverSheetData: [], unitSheetData: [] },
    );

    const contextSheetData = [];
    selectedStores.forEach((store) => {
      const turnover = get(turnoverByStoreId, `[${store.id}].value`, null);

      const lossRate = formatNumberToExcelUsage(
        (get(totalLossByStoreId, `${store.id}.total`, null) / turnover) * 100,
        2,
      );

      contextSheetData.push({
        storeName: store.name,
        startDate: periodPickerState.startDate,
        endDate: periodPickerState.endDate,
        turnover: get(turnoverByStoreId, `[${store.id}].value`),
        lossRate: isNaN(lossRate) || !turnover ? '' : `${lossRate} %`,
      });
    });

    openSmallModal({
      component: OrderAnalyticsByCategoryExportModal,
      title: i18next.t('ORDERS.BY_CATEGORY.EXPORT_IN_PROGRESS'),
      turnoverSheetData: sortBy(data.turnoverSheetData, ['category', 'name']),
      unitSheetData: sortBy(data.unitSheetData, ['category', 'name']),
      contextSheetData: sortBy(contextSheetData, 'storeName'),
      isCentralKitchenMode: isInCentralMode,
      currency,
      showMessage,
    });
  };

  /*******************
   ***** OTHER *****
   *******************/

  const actions = [
    {
      label: i18next.t('ORDERS.BY_CATEGORY.EXPORT_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: () => {
        exportAnalytics();
      },
    },
  ];

  const filterCategoryData = (categoryData) => {
    const filteredCategory = cloneDeep(categoryData);

    filteredCategory.nestedData = applyAdvancedFilters(
      advancedFilters,
      filteredCategory.nestedData,
    );

    return filteredCategory.nestedData.length ? filteredCategory : null;
  };

  const filterData = () => {
    if (!hasValidAdvancedFilters(advancedFilters)) {
      // start filtering only if all advanced filters have a value (minValue and maxValue are in the case of "between" filter)
      return analyticsData;
    }

    return METRICS.reduce((acc, { key }) => {
      acc[key] = analyticsData[key]
        .map((categoryData) => filterCategoryData(categoryData))
        .filter(Boolean); // Remove null values
      return acc;
    }, {});
  };

  /**
   * Filtering data is precisely a case of derived data state. With that in mind, filtering is done
   * instead of keeping track of a separate state, which also removes the need for a dedicated useEffect.
   * */
  const filteredData = filterData();

  const fixedRowsData =
    selectedMetric.key === 'turnover' && totalRow ? [totalRow[selectedMetric.key]] : [];

  return (
    <GenericPageContainer>
      <NavigationBreadCrumb featurePath={path} />
      <GenericContentContainer verticalPaddingOnly>
        <GenericHeaderContainer>
          <LeftRightSplitter
            left={
              <GenericGapContainer>
                <Filters
                  customWidth={240}
                  filtersButtonState={filtersButtonState}
                  filtersModalParams={{
                    // Modal Filters
                    applyFilters,
                    setApplyFilters,
                    filters,
                    setFilters,
                    advancedFilters,
                    setAdvancedFilters,
                    columnsFilterList,
                    // Brands
                    brands,
                    selectedBrands,
                    setSelectedBrands,
                    // Retailers
                    retailers,
                    selectedRetailers,
                    setSelectedRetailers,
                    // Locations
                    locations,
                    selectedLocations,
                    setSelectedLocations,
                    // Store
                    stores,
                    selectedStores,
                    setSelectedStores,
                  }}
                  filterText={i18next.t('GENERAL.ANALYTICS_BUTTON')}
                  isDisabled={isLoadingFilters || isLoading}
                />

                <RangeDatePicker
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  formattedInventoryStockDay={formattedInventoryStockDay}
                  handleSelectedDates={(dates) => periodPickerState.handleSelectedDates(dates)}
                  readOnly={isLoading}
                  setEndMonth={setEndMonth}
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  setStartMonth={setStartMonth}
                  singleStore={selectedStores.length === 1}
                  startDate={periodPickerState.startDate}
                />

                <ToggleButton
                  choices={METRICS}
                  isDisabled={false}
                  selectedChoice={selectedMetric}
                  setSelectedChoice={setSelectedMetric}
                />

                <KpiDisplay
                  currency={currency}
                  isLoading={isLoading}
                  label={i18next.t('GENERAL.TURNOVER_EX_TAX')}
                  tooltip={i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_TOOLTIP', {
                    currencyName: getFormattedCurrencyName(currency.shortenedName, true),
                  })}
                  value={turnover}
                />

                <KpiDisplay
                  currency={currency}
                  isLoading={isLoading}
                  label={i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE')}
                  value={lossRate}
                  isPercentage
                />
              </GenericGapContainer>
            }
            right={
              <Button
                actions={actions}
                color={'inpulse-outline'}
                iconDropdown={'/images/inpulse/carret-black.svg'}
                iconOnLeft={false}
                label={i18next.t('GENERAL.ACTIONS')}
              />
            }
          />
        </GenericHeaderContainer>
        <NestedListContainer>
          {!(filteredData || isLoading || isRetrievingAnalytics) && (
            <EmptyState label={i18next.t('GENERAL.NO_DATA_TO_DISPLAY')} />
          )}
          {(filteredData || isLoading || isRetrievingAnalytics) && (
            <NestedList
              data={get(filteredData, `${[selectedMetric.key]}`, [])}
              defaultOrderBy={'name'}
              defaultOrderType={'asc'}
              fixedRowsData={fixedRowsData}
              headers={headers}
              isLoading={isLoading || isRetrievingAnalytics}
              minWidth={'978px'}
              hasNestedData
              isExpandable
            />
          )}
        </NestedListContainer>
      </GenericContentContainer>
    </GenericPageContainer>
  );
};

const mapStateToProps = (state) => ({
  kitchenStores: getCentralKitchenStores(state.baseReducer.activeStores),
  salesPointStores: getSalesPointStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsFoodCostByCategory);
