import styled from 'styled-components';

export const Container = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmall};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// The following are required for a proper text alignment because of the Tooltip component
export const TooltipContainer = styled.div`
  position: relative;
  bottom: 4px;
`;
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: ${(props) => (props.hasTooltip ? '0px' : '4px')};
`;
