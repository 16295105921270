import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import ComponentLoader from '../../DeepsightComponents/ComponentLoader';

import {
  BodyContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  LoadingContainer,
} from './styledComponents';

const ScrollableWhiteCard = (props) => {
  const { isLoading, renderHeader, renderContent, className } = props;

  const bodyContainerRef = useRef(null);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [hasReachedTop, setHasReachedTop] = useState(false);

  const checkScrollbar = (event) => {
    if (!bodyContainerRef || !bodyContainerRef.current) {
      return;
    }

    const isScrollable =
      bodyContainerRef.current.scrollHeight > bodyContainerRef.current.clientHeight;

    setHasVerticalScrollbar(isScrollable);
    setHasReachedTop(true);

    if (event) {
      setHasReachedTop(event.target.scrollTop === 0);

      setHasReachedBottom(
        event.target.scrollTop >=
          bodyContainerRef.current.scrollHeight - bodyContainerRef.current.clientHeight,
      );
    }
  };

  useEffect(() => {
    if (!bodyContainerRef || !bodyContainerRef.current) {
      return;
    }

    checkScrollbar();

    const instance = bodyContainerRef.current;

    if (instance) {
      instance.addEventListener('scroll', checkScrollbar);
    }

    return () => {
      if (instance) {
        instance.removeEventListener('scroll', checkScrollbar);
      }
    };
  }, [bodyContainerRef, isLoading]);

  return (
    <Container className={className}>
      {isLoading && (
        <LoadingContainer>
          <ComponentLoader height={16} width={16} />
        </LoadingContainer>
      )}
      {!isLoading && (
        <ContentContainer>
          <HeaderContainer>{renderHeader}</HeaderContainer>
          <BodyContainer
            hasReachedBottom={hasReachedBottom}
            hasReachedTop={hasReachedTop}
            ref={bodyContainerRef}
            scrollable={hasVerticalScrollbar}
          >
            {renderContent}
          </BodyContainer>
        </ContentContainer>
      )}
    </Container>
  );
};

ScrollableWhiteCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  renderHeader: PropTypes.object,
  renderContent: PropTypes.object,
};

ScrollableWhiteCard.defaultProps = {
  className: '',
  isLoading: false,
};

export default ScrollableWhiteCard;
