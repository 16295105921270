import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { isForbiddenAPIError } from '@commons/utils/errors';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { canEditStorageArea } from '@selectors/actions/storageAreaActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

// SERVICES
import storageAreaService from '@services/storageArea';

import { ButtonsContainer, ContentContainer, PageContainer } from '../styledComponents';
import { STORAGE_AREA_FORM_INPUTS, FIELDS_TO_CHECK } from '../utils/formInputsConfigurations';
import StorageAreaInformations from '../storageAreaInformations';

const StorageAreaDetails = (props) => {
  const {
    match: { path, params },
    client: { clientId },
    pageLoaded,
    pageLoading,
    history,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    authorizedActions,
  } = props;

  const storageAreaId = params.id;

  const [storageArea, setStorageArea] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);

  const storageAreaForm = useForm({
    defaultValues: {},
    resolver: yupResolver(
      buildSchema(STORAGE_AREA_FORM_INPUTS({ storageArea, authorizedActions })),
    ),
  });

  const formFields = useWatch({
    control: storageAreaForm.control,
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      try {
        const fetchedStorageArea = await storageAreaService.getStorageAreaById(clientId, params.id);

        const { name, storeId, storeName } = fetchedStorageArea;

        setStorageArea(fetchedStorageArea);
        storageAreaForm.setValue('name', name);

        if (storeId) {
          storageAreaForm.setValue('stores', [{ id: storeId, name: storeName }]);
        }
      } catch (error) {
        showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.STORAGE_AREA_DETAIL_FETCHING_ERROR'));

        if (isForbiddenAPIError(error)) {
          history.goBack();
        }
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  /** USE EFFECTS */

  useEffect(() => {
    (async () => {
      const fieldsValidation = await storageAreaForm.trigger(FIELDS_TO_CHECK);
      setIsSaveDisabled(!fieldsValidation || !canEditStorageArea(authorizedActions));

      const isFormDirty = get(storageAreaForm, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(storageAreaForm, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        (field) => !isEqual(storageArea[field], formFields[field]),
      );

      if (isFormDirty && hasAtLeastOneDifferentField) {
        setIsFooterDisplayed(true);
        return;
      }

      setIsFooterDisplayed(false);
    })();
  }, [formFields]);

  /** FUNCTIONS */

  const returnToStorageAreasList = () => history.goBack();

  const validateForm = async () => {
    const fieldsValidation = await storageAreaForm.trigger(FIELDS_TO_CHECK);

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation || !canEditStorageArea(authorizedActions)) {
      setIsSaveDisabled(true);
      return;
    }

    handleUpdateStorageArea();
  };

  const handleUpdateStorageArea = () => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.STORAGE_AREAS.UPDATE_NAME_CONFIRMATION_MESSAGE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.STORAGE_AREAS.UPDATE_NAME_CONFIRMATION_TITLE'),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => updateStorageArea(),
        },
      ],
    };
    openGenericModal(params);
  };

  const updateStorageArea = async () => {
    pageLoading();
    const updatedName = get(formFields, 'name', '');

    setIsSaveDisabled(true);

    try {
      await storageAreaService.updateStorageArea(clientId, storageAreaId, updatedName);
      showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));

      pageLoaded();
      returnToStorageAreasList();
    } catch {
      pageLoaded();
      showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
    }
  };

  return (
    <>
      <NavigationBar
        path={path}
        storageArea={{
          id: storageAreaId,
          name: storageArea.name || '',
        }}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer isFooterDisplayed={isFooterDisplayed}>
          <StorageAreaInformations
            authorizedActions={authorizedActions}
            isSaveAlreadyTriggered={isSaveAlreadyTriggered}
            storageAreaForm={storageAreaForm}
          />
        </ContentContainer>
        {isFooterDisplayed && (
          <Footer>
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={returnToStorageAreasList}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={validateForm}
                icon={'/images/inpulse/save-white-small.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.SAVE')}
              />
            </ButtonsContainer>
          </Footer>
        )}
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/inventories/storage-areas/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreaDetails);
