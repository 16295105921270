import i18next from 'i18next';

const CATEGORY_FOOD_COST = {
  getText: () => i18next.t('FEATURE.ANALYTICS.CATEGORY'),
  path: '/central-kitchen-food-cost/analytics/by-category',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.CATEGORY_BREADCRUMB'),
};

const FOOD_COST_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.FOOD_COST'),
  icon: '/images/savings.svg',
  children: [CATEGORY_FOOD_COST],
};

const CENTRAL_KITCHEN_RECIPE_STOCKS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.CENTRAL_KITCHEN_RECIPE_STOCKS'),
  path: '/central-kitchen-stocks/analytics-past/recipes',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.CENTRAL_KITCHEN_RECIPE_STOCKS_BREADCRUMB'),
};

const CENTRAL_KITCHEN_INGREDIENT_STOCKS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.CENTRAL_KITCHEN_INGREDIENT_STOCKS'),
  path: '/central-kitchen-stocks/analytics-past/ingredients',
  getBreadCrumbText: () =>
    i18next.t('FEATURE.ANALYTICS.CENTRAL_KITCHEN_INGREDIENT_STOCKS_BREADCRUMB'),
};

const CENTRAL_KITCHEN_STOCKS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.STOCKS'),
  icon: '/images/icon-stock.svg',
  children: [CENTRAL_KITCHEN_RECIPE_STOCKS, CENTRAL_KITCHEN_INGREDIENT_STOCKS],
};

export const CENTRAL_KITCHEN_ANALYTICS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.ANALYTICS'),
  path: '/analytics',
  icon: '/images/bar-chart.svg',
  children: [FOOD_COST_SUBMODULE, CENTRAL_KITCHEN_STOCKS_SUBMODULE],
};
