import { connect } from 'react-redux';
import { round } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import ExportModalContent from '@lib/inpulse/ExportModal';

import utilsXLS from '@commons/utils/makeXLS';

import { getCompositionLabel } from '@admin/products/products/utils/getProductExportLabels';
import { getPriceExcludingVAT } from '@admin/products/products/detail/components/ProductInfo/utils/getPriceExcludingVAT';

const FILENAME = i18next.t('ADMIN.CENTRAL_PRODUCTS.EXPORT_LIST_FILENAME');
const SHEET_NAME = i18next.t('ADMIN.CENTRAL_PRODUCTS.EXPORT_LIST_MAIN_SHEET_NAME');

export const CentralKitchenProductExport = (props) => {
  const {
    currency,
    params: { title, products },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const COLUMNS_SETTINGS = [
    {
      propertyKey: 'id',
      name: i18next.t('GENERAL.ID'),
    },
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
    },
    {
      propertyKey: 'mappedStoreName',
      name: i18next.t('GENERAL.CENTRAL'),
    },
    {
      propertyKey: 'status',
      name: i18next.t('GENERAL.STATUS'),
    },
    {
      propertyKey: 'category',
      name: i18next.t('GENERAL.CATEGORY'),
    },
    {
      propertyKey: 'subCategory',
      name: i18next.t('GENERAL.SUB_CATEGORY'),
    },
    {
      propertyKey: 'composition',
      name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_PRODUCT_COMPOSITION'),
    },
    {
      type: 'currency',
      propertyKey: 'computedCost',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_RECIPE_COST'),
    },
    {
      type: 'currency',
      propertyKey: 'priceHT',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_PRICE_HT'),
    },
    {
      type: 'percentage',
      propertyKey: 'ratio',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_RECIPE_RATIO_COST'),
    },
    {
      propertyKey: 'vatRate',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_VAT_RATE'),
    },
    {
      type: 'currency',
      propertyKey: 'priceWithTaxes',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_PRICE_INCLUDING_TAXES'),
    },
    {
      propertyKey: 'sku',
      name: i18next.t('GENERAL.SKU'),
    },
    {
      propertyKey: 'shelfLife',
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_SHELF_LIFE'),
    },
  ];

  const formatDataForXLSFile = (products) => {
    const productsToBeExported = [];

    products.forEach((product) => {
      const productSupplierProductMappings = product.productSupplierProductMappings;

      const priceHT = getPriceExcludingVAT(product);
      const mappedEntity = product.lnkEntityProductrel;

      const computedCost = round(mappedEntity.cost, 2);
      const ratio = priceHT && computedCost ? round((computedCost / priceHT) * 100, 2) : '';

      productSupplierProductMappings.forEach((pspm) => {
        productsToBeExported.push({
          ...product,
          status: product.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE'),
          priceHT: priceHT || '',
          mappedStoreName: pspm.mappedStoreName,
          composition: getCompositionLabel(product.lnkEntityProductrel),
          computedCost,
          ratio,
        });
      });
    });

    return productsToBeExported;
  };

  // Will be improved by exporting by little batch of products
  useEffect(() => {
    try {
      const mainSheet = utilsXLS.generateDefaultSheet(
        SHEET_NAME,
        COLUMNS_SETTINGS,
        formatDataForXLSFile(products),
        currency,
      );

      utilsXLS.makeXLS(FILENAME, [mainSheet]);

      setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
      exitModal();
    }
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  const exitModal = () => {
    setIsLoading(false);
    closeModal();
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showConfirmationMessage(message, 'error'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CentralKitchenProductExport);
