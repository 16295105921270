import { connect } from 'react-redux';
import { get } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { isForbiddenAPIError } from '@commons/utils/errors';
import catalogsService from '@services/catalogs';

import NavigationBar from '@commons/NavigationBar';

import CatalogUserMappings from '../Creation/UserMappings';

import { PageContainer, ContentContainer } from './styledComponents';

const CatalogUsers = (props) => {
  const {
    history,
    match: { path, params },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const catalogForm = useForm({
    defaultValues: {},
  });

  const formFields = useWatch({
    control: catalogForm.control,
  });

  const [catalog, setCatalog] = useState({ name: '' });

  const getCatalog = async () => {
    pageLoading();

    try {
      const fetchedCatalog = await catalogsService.getCatalogById(params.id);

      const formattedAccounts = fetchedCatalog.accounts.map((account) => ({
        ...account,
        name: account.firstName || '---',
        role: get(account, 'lnkAccountRoleAccountrel.name', ''),
        storesCount: get(account, 'stores.length', 0),
      }));

      catalogForm.setValue('accountMappings', formattedAccounts);

      setCatalog(fetchedCatalog);
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.CATALOG_DETAILS_FETCH_ERROR'));

      if (isForbiddenAPIError(error)) {
        history.goBack();
      }
    } finally {
      pageLoaded();
    }
  };

  const updateMappings = async () => {
    pageLoading();

    try {
      const accountIds = formFields.accountMappings.map(({ id }) => id);

      await catalogsService.updateCatalog(catalog.id, null, accountIds);

      showSuccessMessage(i18next.t('ADMIN.CATALOGS.UPDATE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    getCatalog();
  }, []);

  useEffect(() => {
    if (!!catalog.id && !!formFields.accountMappings) {
      updateMappings();
    }
  }, [formFields]);

  return (
    <>
      <NavigationBar
        bigTopBar={true}
        catalog={catalog}
        enableActionBottomOrder={true}
        path={path}
      />
      <PageContainer>
        <ContentContainer>
          <CatalogUserMappings catalogForm={catalogForm} formFields={formFields} />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(CatalogUsers);
