import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import ExportModalContent from '@lib/inpulse/ExportModal';

import invoiceService from '@services/invoice';

import { exportICLsOcerization } from '@src/routes/invoice/commons/export';
import {
  formatICLListDataForExport,
  formatICLOcerizationDataForExport,
} from '@src/routes/invoice/commons/export/format';

export const InvoiceControlListsOcerizationExportModal = (props) => {
  const {
    closeModal,
    params: { invoiceControlListIds, currency, storeName },
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [formattedICLsOcerizationData, setFormattedICLsOcerizationData] = useState([]);
  const [invoiceContolListsInfo, setInvoiceContolListsInfo] = useState([]);
  const [ICLTotalFetchedCount, setICLTotalFetchedCount] = useState(0);

  const ICLTotalToFetchNb = invoiceControlListIds.length;

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(
    i18next.t('INVOICE.INVOICE_CONTROLS.CONTENT_EXPORT_MODAL_TITLE'),
  );

  const exitModal = () => {
    setLoading(false);
    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const updateProgress = (totalFetched) => {
    const invoiceControlsLeft = ICLTotalToFetchNb - totalFetched;
    const updatedProgress = 100 - (invoiceControlsLeft / ICLTotalToFetchNb) * 100;

    setProgress(updatedProgress);
  };

  const getInvoiceControlListsOcerizationData = async (invoiceControlListId) => {
    try {
      const invoiceControlList = await invoiceService.getById(invoiceControlListId);

      const fetchedICLOcerizationData = await invoiceService.getOcerizationById(
        invoiceControlListId,
      );

      const formattedICLOcerizationData = formatICLOcerizationDataForExport(
        invoiceControlList,
        fetchedICLOcerizationData,
      );

      const associatedOrders = await invoiceService.getOrdersByInvoiceControlListId(
        invoiceControlList.id,
      );

      const formatICLListData = formatICLListDataForExport(invoiceControlList, associatedOrders);

      const updatedFetchedNb = ICLTotalFetchedCount + 1;

      setFormattedICLsOcerizationData([
        ...formattedICLsOcerizationData,
        ...formattedICLOcerizationData,
      ]);

      setInvoiceContolListsInfo([...invoiceContolListsInfo, formatICLListData]);

      setICLTotalFetchedCount(updatedFetchedNb);
      updateProgress(updatedFetchedNb);
    } catch {
      exportFailure();
    }
  };

  const exportData = () => {
    exportICLsOcerization(
      formattedICLsOcerizationData,
      invoiceContolListsInfo,
      storeName,
      currency,
    );

    return exportReady();
  };

  useEffect(() => {
    if (isLoading && ICLTotalFetchedCount < ICLTotalToFetchNb) {
      // Get invoice control list ocerization data one by one
      const nextInvoiceControlListId = invoiceControlListIds[ICLTotalFetchedCount];

      (async () => {
        await getInvoiceControlListsOcerizationData(nextInvoiceControlListId);
      })();

      return;
    }

    exportData();
  }, [ICLTotalFetchedCount]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={title}
    />
  );
};

export default InvoiceControlListsOcerizationExportModal;
