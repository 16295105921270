import React from 'react';

import Text, { ENUM_FONTS, ENUM_COLORS } from '../Text';

import {
  Container,
  TitleContainer,
  CategoryTitleContainer,
  RowItem,
  RowContainer,
} from './styledComponents';

/**
 * @typedef {Object} AnalyticsData
 * @property {string?} categoryName
 * @property {string?} categoryValue
 * @property {string?} name
 * @property {string?} value
 * @property {AnalyticsData[]?} [children]
 */

/**
 * Used for the tooltip in food cost analytics
 * See formatDataForTooltip in analytics/foodCost/byCategory/columns.js for reference
 *
 * @param {AnalyticsData} data
 *
 * @returns
 */
const AnalyticsList = ({ title, data, isNested }) => (
  <Container>
    {!isNested && (
      <TitleContainer>
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_14}>
          {title}
        </Text>
      </TitleContainer>
    )}
    {data.map(({ categoryName, categoryValue, name, value, children }) => (
      <div key={categoryName || name}>
        {!isNested && (
          <CategoryTitleContainer>
            <RowItem width={'120px'}>
              <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_MICRO_BOLD}>
                {categoryName}
              </Text>
            </RowItem>
            <RowItem width={'96px'}>
              <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_MICRO_BOLD}>
                {categoryValue}
              </Text>
            </RowItem>
          </CategoryTitleContainer>
        )}
        {children &&
          children.length > 0 &&
          children.map(({ name, value, children: nestedChildren }) => (
            <div key={name}>
              <RowContainer>
                <RowItem width={'104px'}>
                  <Text
                    color={nestedChildren ? ENUM_COLORS.DARKER : ENUM_COLORS.DARK}
                    font={
                      nestedChildren
                        ? ENUM_FONTS.TEXT_BIG_MICRO_BOLD
                        : ENUM_FONTS.TEXT_SMALL_HEIGHT_12
                    }
                  >
                    {name}
                  </Text>
                </RowItem>
                <RowItem width={'96px'}>
                  <Text
                    color={nestedChildren ? ENUM_COLORS.DARKER : ENUM_COLORS.DARK}
                    font={
                      nestedChildren
                        ? ENUM_FONTS.TEXT_BIG_MICRO_BOLD
                        : ENUM_FONTS.TEXT_SMALL_HEIGHT_12
                    }
                  >
                    {value}
                  </Text>
                </RowItem>
              </RowContainer>
              {nestedChildren && nestedChildren.length > 0 && (
                <AnalyticsList data={nestedChildren} isNested />
              )}
            </div>
          ))}
        {isNested && (
          <RowContainer>
            <RowItem isNested={isNested} width={'104px'}>
              <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_12}>
                {name}
              </Text>
            </RowItem>
            <RowItem width={'96px'}>
              <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_12}>
                {value}
              </Text>
            </RowItem>
          </RowContainer>
        )}
      </div>
    ))}
  </Container>
);

export default AnalyticsList;
