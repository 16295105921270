import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

// ICL list sheet columns
export const getICLExportListColumns = (storeName) => {
  const columns = [
    {
      propertyKey: 'supplier.name',
      name: i18next.t('GENERAL.SUPPLIER'),
    },
    {
      propertyKey: 'store.name',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'importedAt',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
    },
    {
      propertyKey: 'invoiceDate',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    },
    {
      propertyKey: 'invoiceNumber',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
    },
    {
      propertyKey: 'nbAssociatedOrder',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_ASSOCIATED_ORDERS'),
    },
    {
      propertyKey: 'gapExcludingTax',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_GAP_EXCLUDING_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'total',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
      type: 'currency',
    },
  ];

  return columns;
};

// Ocerization sheet columns
export const getOcerizationSheetColumns = (storeName) => {
  const columns = [
    {
      propertyKey: 'supplierName',
      name: i18next.t('GENERAL.SUPPLIER'),
    },
    {
      propertyKey: 'storeName',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'importedAt',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
    },
    {
      propertyKey: 'invoiceDate',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    },
    {
      propertyKey: 'invoiceNumber',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
    },
    {
      propertyKey: 'sku',
      name: i18next.t('GENERAL.SKU'),
    },
    {
      propertyKey: 'invoiceName',
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_NAME_ARTICLE',
      ),
    },
    {
      propertyKey: 'invoicePackaging',
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PACKAGING'),
    },
    {
      propertyKey: 'invoicePrice',
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PRICE_EXCLUDING_TAXES',
      ),
    },
    {
      propertyKey: 'invoiceQuantity',
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_QUANTITY'),
    },
    {
      propertyKey: 'computedTotal',
      name: i18next.t('GENERAL.TOTAL_EX_TAX'),
      type: 'currency',
    },
  ];

  return columns;
};

// Gap sheet columns
export const getGapSheetColumns = () => {
  const columns = [
    {
      propertyKey: 'invoiceNumber',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
    },
    {
      propertyKey: 'orderReference',
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDERS'),
    },
    {
      propertyKey: 'invoiceSku',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE'),
    },
    {
      propertyKey: 'spSku',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE_INPULSE'),
    },
    {
      propertyKey: 'invoiceSpName',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_ARTICLE_NAME'),
    },
    {
      propertyKey: 'spName',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_ARTICLE_NAME'),
    },
    {
      propertyKey: 'invoiceSpPrice',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PRICE_EXCL_TAXES'),
    },
    {
      propertyKey: 'invoicePackaging',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PACKAGING'),
    },
    {
      propertyKey: 'spPrice',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_INVOICE_PRICE_EXCL_TAXES'),
    },
    {
      propertyKey: 'spPackagingName',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_PACKAGING_NAME'),
    },
    {
      propertyKey: 'priceDiff',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PRICE_DIFF_EXCL_TAXES'),
    },
    {
      propertyKey: 'invoiceSpQuantity',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_QUANTITY'),
    },
    {
      propertyKey: 'spReceivedQuantity',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_QUANTITY'),
    },
    {
      propertyKey: 'quantityDiff',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_QUANTITY_DIFF'),
    },
    {
      propertyKey: 'invoicedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICED_TOTAL_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'receivedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.RECEIVED_TOTAL_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'totalGap',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_TOTAL_GAP_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'priceGap',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.PRICE_GAP_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'quantityGap',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.QUANTITY_GAP_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'status',
      name: i18next.t('GENERAL.STATUS'),
    },
  ];

  return columns;
};

// ICL informations sheet columns
export const getICLInformationsSheetColumns = (storeName) => {
  const columns = [
    {
      propertyKey: 'supplierName',
      name: i18next.t('GENERAL.SUPPLIER'),
    },
    {
      propertyKey: 'storeName',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'importedAt',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
    },
    {
      propertyKey: 'invoiceDate',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    },
    {
      propertyKey: 'invoiceNumber',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
    },
    {
      propertyKey: 'invoicedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICED_TOTAL_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'nbAssociatedOrder',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_ASSOCIATED_ORDERS'),
    },
    {
      propertyKey: 'receivedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.RECEIVED_TOTAL_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'gapExcludingTax',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_TOTAL_GAP_EX_TAX'),
      type: 'currency',
    },
  ];

  return columns;
};

// Associated orders sheet columns
export const getAssociatedOrdersSheetColumns = (storeName) => {
  const columns = [
    {
      propertyKey: 'supplierName',
      name: i18next.t('GENERAL.SUPPLIER'),
    },
    {
      propertyKey: 'storeName',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'importedAt',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
    },
    {
      propertyKey: 'invoiceDate',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    },
    {
      propertyKey: 'invoiceNumber',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
    },
    {
      propertyKey: 'invoicedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICED_TOTAL_EX_TAX'),
      type: 'currency',
    },
    {
      propertyKey: 'orderReference',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_ASSOCIATED_ORDERS'),
    },
    {
      propertyKey: 'deliveryDate',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.DELIVERY_DATE'),
    },
    {
      propertyKey: 'orderStatus',
      name: i18next.t('GENERAL.STATUS'),
    },
    {
      propertyKey: 'receivedTotal',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.RECEIVED_TOTAL_EX_TAX'),
      type: 'currency',
    },
  ];

  return columns;
};
