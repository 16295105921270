import { first, get, groupBy, sortBy, sumBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import { getUserTimezone } from '@commons/utils/date';

import { ORDER_PREPARATION_STATUS } from '@orders/OrderList/components/OrderForm/constants/status';
import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

import { preparationStatusLegend, statusLegend } from '../../../constants';

export default (productOrders) => {
  const userTimezone = getUserTimezone();

  const productOrdersGroupByOrderId = groupBy(productOrders, 'order.id');

  const orders = Object.values(productOrdersGroupByOrderId).reduce(
    (result, filteredProductOrders) => {
      if (!filteredProductOrders.length) {
        return result;
      }

      const { order, store } = first(filteredProductOrders);

      const totalPriceBDC = sumBy(filteredProductOrders, ({ ordered: { price, quantity } }) =>
        price && quantity ? price * quantity : 0,
      );

      const orderNotPreparedAndSent =
        parseInt(order.status) === ORDER_STATUS.SENT &&
        parseInt(order.preparationStatus) === ORDER_PREPARATION_STATUS.TO_BE_PREPARED;

      // If order status is SENT and preparation status is 'TO_BE_PREPARED', we do not have invoiced POs data yet.
      // As we don't have invoiced POs data, we'll use orderedPOs data
      const totalPriceBDL = orderNotPreparedAndSent
        ? totalPriceBDC
        : sumBy(filteredProductOrders, ({ invoiced: { price, quantity } }) =>
            price && quantity ? price * quantity : 0,
          );

      const totalPriceReceived = sumBy(filteredProductOrders, ({ received: { price, quantity } }) =>
        price && quantity ? price * quantity : 0,
      );

      const totalPackagingWeight = sumBy(
        filteredProductOrders,
        ({ ordered, received, supplierProduct: { packagingWeight } }) =>
          packagingWeight * (ordered.quantity || received.quantity), // fallback on received to handle added reference
      );

      result.push({
        ...order,
        store,
        totPackagingWeight: totalPackagingWeight,
        status: i18next.t(statusLegend[order.status].name),
        preparationStatus:
          order.preparationStatus != null
            ? i18next.t(preparationStatusLegend[order.preparationStatus].name)
            : null,
        totalPriceBDC: formatNumberToExcelUsage(totalPriceBDC, 2),
        totalPriceBDL:
          order.preparationStatus === null && order.status <= ORDER_STATUS.SENT
            ? null
            : formatNumberToExcelUsage(totalPriceBDL, 2),
        totalPriceReceived:
          order.status <= ORDER_STATUS.SENT
            ? null
            : formatNumberToExcelUsage(totalPriceReceived, 2),
        orderDate: moment.tz(order.orderDate, userTimezone).format('L'),
        endOrderDate: moment.tz(order.endOrderDate, store.timezone).format('L'),
        startOrderDate: moment.tz(order.startOrderDate, store.timezone).format('L'),
        invoiceControlListId: get(order, 'associatedInvoiceControlList.id'),
        invoiceDate: order.associatedInvoiceControlList?.invoiceDate
          ? moment.tz(order.associatedInvoiceControlList.invoiceDate, userTimezone).format('L')
          : null,
        invoiceTotal: get(order, 'associatedInvoiceControlList.total'),
        invoiceNumber: get(order, 'associatedInvoiceControlList.invoiceNumber', null),
      });

      return result;
    },
    [],
  );

  return sortBy(orders, ['orderDate']).reverse();
};
