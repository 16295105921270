import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { getAuditButtonLabel } from '@commons/HistoryDrawer/utils';
import Drawer from '@commons/Drawer';
import HistoryDrawerContent from '@commons/HistoryDrawer/HistoryDrawerContent';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import auditService from '@services/auditLogs';

// UTILS
import { isForbiddenAPIError } from '@commons/utils/errors';

import { PageContainer } from './styledComponents';
import SupplierProductMappings from './components/SupplierProductMappings';

const SupplierProductStores = (props) => {
  const {
    match: { path, params },
    client: { storeName },
    history,
    pageLoaded,
    pageLoading,
    showErrorMessage,
  } = props;

  const [supplierProduct, setSupplierProduct] = useState({});

  // AuditLogs states
  const [isLoading, setIsLoading] = useState(true);
  const [displaySupplierProductHistory, setDisplaySupplierProductHistory] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [labelUpdatedAt, setLabelUpdatedAt] = useState('');

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async function loadData() {
      try {
        const supplierProductFetched = await supplierProductService.getSingleSupplierProduct(
          params.id,
        );
        setSupplierProduct(supplierProductFetched);

        fetchSupplierProductHistory(supplierProductFetched.id);
      } catch (error) {
        pageLoaded();
        showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.SINGLE_FETCH_ERROR'));

        if (isForbiddenAPIError(error)) {
          history.goBack();
        }
      }
    })();
  }, []);

  useEffect(() => {
    const auditButtonLabel = getAuditButtonLabel(auditLogs);

    setLabelUpdatedAt(auditButtonLabel);
  }, [auditLogs]);

  const fetchSupplierProductHistory = async (supplierProductId) => {
    setIsLoading(true);
    try {
      const supplierProductAuditLogs = await auditService.getTargetIdAuditLogs(supplierProductId);
      setAuditLogs(supplierProductAuditLogs);
    } catch {
      showErrorMessage(i18next.t('ORDERS.AUDIT_EVENTS.ERROR_FETCHING'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavigationBar
        module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
        path={path}
        rightButtonLabel={labelUpdatedAt}
        rightButtonLoading={isLoading}
        rightButtonOnClick={() => setDisplaySupplierProductHistory(!displaySupplierProductHistory)}
        storeName={storeName}
        supplierProduct={supplierProduct}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <SupplierProductMappings supplierProduct={supplierProduct} />
      </PageContainer>

      <Drawer
        isOpened={displaySupplierProductHistory}
        overlayOpacity={0}
        onClick={() => {
          setDisplaySupplierProductHistory(false);
        }}
      >
        <HistoryDrawerContent
          auditLogs={auditLogs}
          subtitle={supplierProduct.name}
          onClose={() => setDisplaySupplierProductHistory(false)}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductStores);
