import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const CategoryTitleContainer = styled.div`
  width: 100%;
  height: 20px;

  display: flex;
  flex-direction: row;
  column-gap: 8px;

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  height: 20px;

  padding-left: 16px;

  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const RowItem = styled.div`
  width: ${(props) => props.width};
  height: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: ${(props) => (props.isNested ? '8px' : 0)};
`;
