import i18next from 'i18next';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { Tags } from '@commons/Tags';
import Text from '@commons/Text';

import SupplierProfileModalAssociations from '@admin/suppliers/suppliers/SupplierProfileModal/SupplierProfileModalAssociations';

const ListViewColumns = [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    render: (name) => <Text highlighted>{name}</Text>,
  },
  {
    id: 'isKitchen',
    propertyKey: 'isKitchen',
    name: i18next.t('GENERAL.STORE_TYPE'),
    render: (isKitchen) => (
      <Text highlighted Text>
        {isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}
      </Text>
    ),
  },
  {
    id: 'supplierProfileName',
    propertyKey: 'supplierProfileName',
    name: i18next.t('GENERAL.SUPPLIER_PROFILE'),
    tooltipText: i18next.t('ADMIN.SUPPLIERS.ADD_STORE_SUPPLIER_PROFILE_TOOLTIP'),
    render: (supplierProfileName) => <Text highlighted>{supplierProfileName}</Text>,
  },
  {
    id: 'groups',
    propertyKey: 'groups',
    name: i18next.t('GENERAL.GROUPS'),
    filterType: 'string',
    render: (groups) => <Tags items={groups.map(({ name }) => name)} />,
    transform: (groups) => groups.map(({ name }) => name).join(', '),
  },
  {
    id: 'country',
    propertyKey: 'country',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'city',
    propertyKey: 'city',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
];

const AssociateSupplierProfileStoresModal = (props) => {
  const {
    clientStoreName,
    availableStoreAssociations,
    handleSelectStoreAssociations,
    groups,
    isLoading,
  } = props;

  return (
    <SupplierProfileModalAssociations
      columns={ListViewColumns}
      data={availableStoreAssociations}
      defaultOrderBy={'name'}
      defaultOrderType={'asc'}
      emptyStateText={i18next.t('ADMIN.SUPPLIERS.NO_AVAILABLE_STORE_EMPTY_STATE', {
        storeName: getClientStoreNameTranslation(clientStoreName).toLowerCase(),
      })}
      forceEnableSelection={true}
      groups={groups}
      isLoading={isLoading}
      setSelectedItems={(items) => handleSelectStoreAssociations(items)}
    />
  );
};

export default AssociateSupplierProfileStoresModal;
