import 'moment/locale/fr';
import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingFailure, loadingSuccess } from '@actions/loading';
import {
  openSupplierIngredientAlreadyAssociatedWarningModal,
  openGenericModal,
} from '@actions/modal';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { ENUM_MODULE_NAME } from '@commons/utils/features';
import NavBar from '@commons/NavigationBar';

import { getClientInfo } from '@selectors/client';

import { ingredient as ingredientService } from '@services/ingredient';

import { getActivationWarningModal } from '../utils/modalConfigurations';

import {
  Container,
  ContainerContent,
  Section,
  NavigationBar,
  NavigationPage,
  NavBarContainer,
} from './styledComponents';
import {
  fetchIngredient,
  fetchSuppliersOfClient,
  fetchIngredientCostFromSupplierList,
} from './commons/services';
import { isForbiddenAPIError } from '@commons/utils/errors';
import IngredientDetailsBottomBar from './components/BottomBar';
import IngredientInfoSection from './components/IngredientInfo';
import IngredientParentAssociations from './components/IngredientParentAssociations';
import IngredientSupplierProductAssociation from './components/IngredientSupplierProductAssociation';

const INGREDIENT_ID_CREATE_MODE = 'create';
const EMPTY_INGREDIENT = {
  id: null,
  name: '',
  category: '',
  unit: '',
  supplierProducts: [],
  allergens: [],
};

const SUPPLIER_PRODUCTS_SUB_PAGE = {
  name: i18next.t('ADMIN.INGREDIENTS.SUPPLIER_PRODUCTS_SUB_PAGE_TITLE'),
  render: (props) => (
    <IngredientSupplierProductAssociation
      clientId={props.clientId}
      displayShowMoreResult={props.displayShowMoreResult}
      fetchSupplierProducts={props.fetchSupplierProducts}
      filterByUserCatalog={props.hasLocalCatalogs}
      history={props.history}
      ingredient={props.ingredient}
      isLoadingSuppliers={props.isLoadingSuppliers}
      supplierIds={props.supplierIds}
      supplierProductsAvailable={props.supplierProductsAvailable}
      suppliers={props.suppliers}
      onIngredientChange={props.onIngredientChange}
      onSupplierProductsAvailableChange={props.onSupplierProductsAvailableChange}
    />
  ),
};

const PARENTS_SUB_PAGE = {
  name: i18next.t('ADMIN.INGREDIENTS.PARENT_SUBPAGE_TITLE'),
  render: (props) => (
    <IngredientParentAssociations
      entitiesMapped={props.entitiesMapped}
      history={props.history}
      ingredient={props.ingredient}
      showMessage={props.showMessage}
      supplierProductsAvailable={props.supplierProductsAvailable}
      onEntitiesMappedChange={props.onEntitiesMappedChange}
      onIngredientChange={props.onIngredientChange}
      onSupplierProductsAvailableChange={props.onSupplierProductsAvailableChange}
    />
  ),
};

export function renderSectionIngredientSubPages(props) {
  const { activeSubPages } = props;

  return (
    <div>
      {activeSubPages.length > 1 && (
        <Section>
          <NavigationBar>
            {activeSubPages.map((page, indexPage) => (
              <NavigationPage
                key={indexPage}
                selected={props.selectedIngredientSubPage.name === page.name}
                onClick={() => props.onSelectedIngredientSubPage(page)}
              >
                {page.name}
              </NavigationPage>
            ))}
          </NavigationBar>
        </Section>
      )}

      <Section nbSubPages={props.activeSubPages.length} sectionType="subpage">
        {props.selectedIngredientSubPage.render(props)}
      </Section>
    </div>
  );
}

export function renderContent(props) {
  const path = get(props, 'match.path');

  return (
    <>
      <NavBarContainer>
        <NavBar
          bigTopBar={true}
          ingredient={props.ingredient}
          ingredientId={props.ingredientId}
          module={ENUM_MODULE_NAME.INGREDIENT_DETAIL}
          path={path}
        />
        <IngredientDetailsBottomBar
          handleIngredientActivation={props.handleIngredientActivation}
          history={props.history}
          ingredient={props.ingredient}
          isReadOnly={props.isReadOnly}
          openModal={props.openModal}
          supplierProductsAlreadyMapped={props.supplierProductsAlreadyMapped}
          onDisplayMappingErrorModalChange={props.onDisplayMappingErrorModalChange}
          onSupplierProductsAlreadyMappedChange={props.onSupplierProductsAlreadyMappedChange}
        />
      </NavBarContainer>
      <ContainerContent>
        <Section>
          <IngredientInfoSection
            ingredient={props.ingredient}
            ingredientId={props.ingredientId}
            isReadOnly={props.isReadOnly}
            onIngredientChange={props.onIngredientChange}
          />
        </Section>

        {renderSectionIngredientSubPages(props)}
      </ContainerContent>

      <IngredientDetailsBottomBar
        handleIngredientActivation={props.handleIngredientActivation}
        history={props.history}
        ingredient={props.ingredient}
        isReadOnly={props.isReadOnly}
        supplierProductsAlreadyMapped={props.supplierProductsAlreadyMapped}
        onDisplayMappingErrorModalChange={props.onDisplayMappingErrorModalChange}
        onSupplierProductsAlreadyMappedChange={props.onSupplierProductsAlreadyMappedChange}
      />
    </>
  );
}

export const IngredientDetails = (props) => {
  const {
    history,
    match: { params },
    openGenericModal,
    showErrorMessage,
    showSuccessMessage,
    client: { clientId, hasLocalCatalogs },
  } = props;

  const ingredientId = params.id;
  const [ingredient, onIngredientChange] = useState(EMPTY_INGREDIENT);

  const [suppliersOfClient, onSuppliersChange] = useState([]);

  const [entitiesMapped, onEntitiesMappedChange] = useState(false);

  const [displayMappingErrorModal, onDisplayMappingErrorModalChange] = useState(false);
  const [supplierProductsAlreadyMapped, onSupplierProductsAlreadyMappedChange] = useState([]);

  const [activeSubPages, onActiveSubPageChange] = useState([SUPPLIER_PRODUCTS_SUB_PAGE]);
  const [selectedIngredientSubPage, onSelectedIngredientSubPage] = useState(
    SUPPLIER_PRODUCTS_SUB_PAGE,
  );
  const [isLoading, setLoading] = useState(true);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(true);

  /**
   * Allows to retrieve the client's supplier ids associated to user in order to retrieve further the list of SP to display in dropdown
   */
  const loadSuppliersByClientId = async (id) => {
    try {
      const suppliers = await fetchSuppliersOfClient(id);

      onSuppliersChange(suppliers);

      setIsLoadingSuppliers(false);
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.INGREDIENT_DETAIL_FETCH_ERROR'));
    }
  };

  const loadIngredientData = async (id) => {
    props.pageLoading();

    try {
      const ingredient = await fetchIngredient(id);

      onIngredientChange(ingredient);

      if (!ingredient.active) {
        onActiveSubPageChange([PARENTS_SUB_PAGE]);
        onSelectedIngredientSubPage(PARENTS_SUB_PAGE);

        return;
      }

      onActiveSubPageChange([SUPPLIER_PRODUCTS_SUB_PAGE, PARENTS_SUB_PAGE]);
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.INGREDIENT_DETAIL_FETCH_ERROR'));

      if (isForbiddenAPIError(error)) {
        history.goBack();
      }
    } finally {
      props.pageLoaded();

      setLoading(false);
    }
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }

    loadSuppliersByClientId(clientId);
  }, [clientId]);

  useEffect(() => {
    if (!ingredientId || ingredientId === INGREDIENT_ID_CREATE_MODE) {
      setLoading(false);

      return;
    }

    loadIngredientData(ingredientId);
  }, [ingredientId]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    props.pageLoading();
    (async function getIngredientCost() {
      try {
        const spsUsedForCost = ingredient.supplierProducts.filter(
          ({ isUsedForCost }) => isUsedForCost,
        );

        const updatedCost = await fetchIngredientCostFromSupplierList(
          spsUsedForCost.map((supplierProduct) => supplierProduct.id),
          ingredient.unit,
        );

        if (updatedCost !== null) {
          ingredient.cost = updatedCost;
          onIngredientChange({ ...ingredient });
        }
      } catch (err) {
        showErrorMessage(i18next.t('ADMIN.INGREDIENTS.INGREDIENT_PRICE_FETCH_ERROR'));
      } finally {
        props.pageLoaded();
      }
    })();
  }, [ingredient.unit, ingredient.supplierProducts]);

  const handleIngredientActivation = (ingredientToActivate) => {
    const params = getActivationWarningModal({ ingredientToActivate, activateIngredients });

    openGenericModal(params);
  };

  const isReadOnly = !ingredient.active && !!ingredient.id;

  const activateIngredients = async (ingredient) => {
    props.pageLoading();

    try {
      await ingredientService.enableIngredientsByIds([ingredient.id]);

      const activeIngredient = await fetchIngredient(ingredientId);
      onIngredientChange(activeIngredient);
      onActiveSubPageChange([SUPPLIER_PRODUCTS_SUB_PAGE, PARENTS_SUB_PAGE]);
      onSelectedIngredientSubPage(SUPPLIER_PRODUCTS_SUB_PAGE);

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION_ERROR'));
    }

    props.pageLoaded();
  };

  return (
    <Container>
      {renderContent({
        ...props,
        activeSubPages,
        selectedIngredientSubPage,
        ingredient,
        ingredientId,
        onIngredientChange,
        onSelectedIngredientSubPage,
        entitiesMapped,
        onEntitiesMappedChange,
        onDisplayMappingErrorModalChange,
        supplierProductsAlreadyMapped,
        onSupplierProductsAlreadyMappedChange,
        displayMappingErrorModal,
        isReadOnly,
        handleIngredientActivation,
        isLoadingSuppliers,
        supplierIds: suppliersOfClient.map(({ id }) => id),
        suppliers: suppliersOfClient,
        clientId,
        hasLocalCatalogs,
      })}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userRights: state.baseReducer.userRights,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  pageDidNotLoad: () => {
    dispatch(loadingFailure());
  },
  openModal: (params) => {
    dispatch(openSupplierIngredientAlreadyAssociatedWarningModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientDetails);
