import {
  cloneDeep,
  first,
  flatten,
  forEach,
  get,
  groupBy,
  head,
  isEmpty,
  isEqual,
  pick,
  sortBy,
  uniqBy,
} from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { computeTypesOfPackaging, convertPriceToPackagingById } from '@commons/utils/packagings';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { escapeSpecialCharacters } from '@commons/utils/regex';
import { flattenArray } from '@commons/utils/format';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { getTheme } from '@commons/utils/theme';
import { getUserTimezone, ORDER_IS_EDITING_DATE_MAX_DATE_IN_PAST } from '@commons/utils/date';
import { handleAppRefresh } from '@commons/utils/refreshApp';
import { isUserAdmin, isUserDeepsight } from '@commons/utils/roles';
import EmptyState from '@commons/EmptyState';
import mixpanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { canCreateDraftOrder, canEditDraftOrder } from '@selectors/actions/orderActions';
import {
  getAuthorizedActions,
  getGenerateInvoice,
  getResetStockEveryDayForCentralKitchen,
} from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import orderService from '@services/order';

import { computePackagingToSupplierProduct } from '@orders/utils/computePackagingToSupplierProduct';
import {
  PRODUCT_ORDER_TYPE,
  MAX_CREDIT_ORDER_PICTURE_PER_ORDER,
} from '@orders/OrderList/constants';
import modalConfiguration from '@orders/utils/modalConfiguration';

import { Container, Content, DisplayEmptyState } from './styledComponents';
import { encodeFileNameInUrl } from '@commons/utils/fileUtils';
import { getFormattedProductOrders } from './utils/sendValidatedDelivery';
import { getFormattedProductOrders as getProductOrderForSendOrder } from './utils/sendOrder';
import {
  RenderSendingErrorModal,
  RenderSendToPartnerConfirmationModal,
  RenderValidateModal,
} from './OrderFormModals';
import DeliveryNotePreview from './components/DeliveryNotePreview';
import getConvertedPackagingValue from './components/ProductOrdersContainer/utils/getConvertedPackagingValue';
import Header from './components/Header';
import LoadingState from './components/LoadingState/LoadingState';
import ModalManager from './components/ModalManager';
import ORDER_MODAL_TYPE from './constants/modalType';
import ORDER_STATUS from './constants/status';
import OrderCart from './components/OrderCart';
import OrderCreditForm from './components/OrderCreditForm';
import OrderFormCategory from './components/OrderFormCategory';
import OrderFormInfoPanel from './components/OrderFormInfoPanel';
import services from './services';
import utilsLib from './utils';

const AMOUNT_DAY_TO_ADD_TO_END_DATE = 16;
const PRODUCT_ORDER_MAX_HANDLER = 100;

const SEND_ORDER_ERRORS = {
  DUPLICATED_REFERENCE: 'duplicated_reference',
  SEND_EMAIL_FAILED: 'send_email_failed',
  SEND_EDI_GENERIC_FAILED_MESSAGE: 'send_edi_',
};

function OrderForm(props) {
  const {
    user,
    defaultCurrency,
    client: { storeName, clientName },
    params,
    closeModal,
    shouldReloadApp,
    authorizedActions,
    resetStockEveryDayForCentralKitchen,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    hasGenerateInvoiceProps,
  } = props;

  const {
    stores,
    setIsRetrievingData,
    setShouldRetrieveData,
    order,
    canOrderWithMin,
    constraints,
    isDuplicatingOrder = false,
  } = params;

  const theme = getTheme();

  const userTimezone = getUserTimezone();

  const [isLoadingHeader, setIsLoadingHeader] = useState(true);
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const [modalType, setModalType] = useState(null);
  const [isConfirmable, setIsConfirmable] = useState(false);
  const [prefillInpulse, setPrefillInpulse] = useState(false);

  // Dates configuration
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [receptionDate, setReceptionDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [orderOpeningTime] = useState(moment.tz(userTimezone).format('HHmmss'));
  const [selectedStartDate, setSelectedStartDate] = useState(null);

  // Email sending params configuration
  const [sending, setSending] = useState(null);
  const [sendingParams, setSendingParams] = useState(null);

  // Store configuration
  const [selectedStore, setSelectedStore] = useState({});
  const [storeId, setStoreId] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [storeInfos, setStoreInfos] = useState({});
  const [confirmedStoreId, setConfirmedStoreId] = useState(null);
  const [currentStoreName, setCurrentStoreName] = useState(
    getClientStoreNameTranslation(storeName),
  );

  // Supplier configuration
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [deliveryDays, setDeliveryDays] = useState([]);
  const [supplierInfos, setSupplierInfos] = useState({});
  const [deliveryLeadTimes, setDeliveryLeadTimes] = useState('');
  const [supplierName, setSupplierName] = useState('Fournisseur');
  const [confirmedSupplierId, setConfirmedSupplierId] = useState(null);
  const [firstPossibleDeliveryDatesByDeliveryDay, setFirstPossibleDeliveryDatesByDeliveryDay] =
    useState({});
  const [supplierHasEDI, setSupplierHasEDI] = useState(false);

  // Order configuration
  const [orderId, setOrderId] = useState('');
  const [orderTotal, setOrderTotal] = useState(0);
  const [pastOrders, setPastOrders] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [maxOrderDate, setMaxOrderDate] = useState(null);
  const [canStillOrder, setCanStillOrder] = useState(false);
  const [orderTimeLimit, setOrderTimeLimit] = useState(null);
  const [receptionReference, setReceptionReference] = useState('');
  const [orderStatus, setOrderStatus] = useState(ORDER_STATUS.CREATION);
  const [preparationStatus, setPreparationStatus] = useState(null);
  const [newOrderDatesRecorded, setNewOrderDatesRecorded] = useState(false);
  const [receivedDiffOfProductOrder, setReceivedDiffOfProductOrder] = useState({});
  const [invoicedDiffOfProductOrder, setInvoicedDiffOfProductOrder] = useState({});
  const [categorizedProducts, setCategorizedProducts] = useState({});
  const [productsRecommendations, setProductsRecommendations] = useState({});
  const [hasChangedHeaderParameter, setHasChangedHeaderParameter] = useState(true);
  const [savedOpenedCategory, setSavedOpenedCategory] = useState({});

  const storeTimezone = get(storeInfos, 'timezone', '');

  const isCentralMode = getIsCentralMode() || selectedStore.isKitchen;

  // Picture configuration
  const [picturesUrls, setPicturesUrls] = useState([]);

  // Reception configuration
  const [receptionComment, setReceptionComment] = useState('');

  // Data
  const [categories, setCategories] = useState([]);
  const [totalBDL, setTotalBDL] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [orderedUnit, setOrderedUnit] = useState(0);
  const [nbReferencesOrdered, setNbReferencesOrdered] = useState(0);
  const [productOrders, setProductOrders] = useState([]);
  const [initialCategorizedProducts, setInitialCategorizedProducts] = useState(null);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [productsInventory, setProductsInventory] = useState([]);
  const [totalByCategories, setTotalByCategories] = useState({});
  const [supplierProductsCustom, setSupplierProductsCustom] = useState([]);
  const [availableReferencesToAdd, setAvailableReferencesToAdd] = useState([]);
  const [openStockInfo, setOpenStockInfo] = useState({ category: null, entity: null });
  const [countProductOrder, setCountProductOrder] = useState(0);

  const [isGeneratingData, setIsGeneratingData] = useState(false);
  const [diffBetweenReceivedAndOrdered, setDiffBetweenReceivedAndOrdered] = useState(0);
  const [diffBetweenOrderedAndInvoiced, setDiffBetweenOrderedAndInvoiced] = useState(0);
  const [nbAnomalies, setNbAnomalies] = useState(0);

  const [disableSendButton, setDisableSendButton] = useState(false);

  //SearchBar
  const [searchInput, setSearchInput] = useState('');
  const [hasNoResult, setHasNoResult] = useState(false);
  const [productOrderToDisplay, setProductOrderToDisplay] = useState({});

  const [displaySendToPartnerConfirmationModal, setDisplaySendToPartnerConfirmationModal] =
    useState(false);
  const [displaySendingError, setDisplaySendingError] = useState(false);
  const [displayOrderWithMinConfirmationModal, setDisplayOrderWithMinConfirmationModal] =
    useState(false);
  const [
    shouldOrderWithMinConfirmationModalBeDisplayed,
    setShouldOrderWithMinConfirmationModalBeDisplayed,
  ] = useState(true);

  const [displayConfirmSupplierProductToDelete, setDisplayConfirmSupplierProductToDelete] =
    useState(false);
  const [hasRoleAdminOrInpulse] = useState(isUserAdmin(user) || isUserDeepsight(user));

  const [addingNewReference, setAddingNewReference] = useState(false);

  const [isEditingDeliveryDate, setIsEditingDeliveryDate] = useState(false);
  const [isDeliveryDateUpdated, setIsDeliveryDateUpdated] = useState(false);

  const ediPartnerWithRequiredCustomerCode = ['TGT', 'PMN'];

  // EmptyState
  const [emptyStateProps, setEmptyStateProps] = useState(null);

  // Cart
  const [displayCart, setDisplayCart] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailRecipientsCopy, setEmailRecipientsCopy] = useState([]);
  const [purchaseOrderComment, setPurchaseOrderComment] = useState('');
  const [htmlPreview, setHtmlPreview] = useState(null);

  // Credit Form
  const [displayCreditForm, setDisplayCreditForm] = useState(false);
  const [creditRequestData, setCreditRequestData] = useState({
    recipientEmails: [],
    copyEmails: [],
    comment: null,
    creditOrderPictureUrls: [], // associated to Order
  });
  const [disableAddCreditPicture, setDisableAddCreditPicture] = useState(false);
  const [allCreditPicturesCount, setAllCreditPicturesCount] = useState(0); // Sum of PO related credit pictures count and order related credit pictures count

  // Delivery note
  const [displayDeliveryNotePreview, setDisplayDeliveryNotePreview] = useState(false);
  const isEditingPreparationOrder = preparationStatus != null && isCentralMode;

  // Preparation

  useEffect(() => {
    if (!isEmpty(categorizedProducts) && orderStatus >= ORDER_STATUS.SENT) {
      setProductOrderToDisplay(categorizedProducts);
      setCountProductOrder(sumUpProductsOrder(categorizedProducts));
      setIsGeneratingData(false);
    }
    if (!isEmpty(productsRecommendations) && orderStatus < ORDER_STATUS.SENT) {
      setProductOrderToDisplay(productsRecommendations);
      setCountProductOrder(sumUpProductsOrder(productsRecommendations));
      setIsGeneratingData(false);
    }
  }, [categorizedProducts, productsRecommendations]);

  useEffect(() => {
    setOpenStockInfo({ open: false, category: null, entity: null });
  }, [startDate, endDate]);

  const sumUpProductsOrder = (productsOrder) =>
    Object.values(productsOrder).reduce((categoryAccumulator, category) => {
      categoryAccumulator += category.products.reduce((productsAccumulator, product) => {
        productsAccumulator += product.length;

        return productsAccumulator;
      }, 0);

      return categoryAccumulator;
    }, 0);

  const getEmailsToUse = () => {
    const purchaseEmailRecipients = get(order, 'purchaseOrderRecipients', '');
    const purchaseEmailRecipientsCopy = get(order, 'purchaseOrderCopy', '');
    const sendingDefaultCC = get(sendingParams, 'email.order.default_cc', '');

    let emailRecipientsToUse = !!purchaseEmailRecipients
      ? purchaseEmailRecipients
      : supplierInfos.email;
    let emailRecipientsCopyToUse = !!purchaseEmailRecipientsCopy
      ? purchaseEmailRecipientsCopy
      : sendingDefaultCC;

    return { emailRecipientsToUse, emailRecipientsCopyToUse };
  };

  const initializeSendindParamsConfiguration = () => {
    if (!params.send) {
      return;
    }

    const email = get(user, 'email', '');

    let updatedSending = '';
    let updatedSendingParams = Object.assign({}, params.send);

    if (updatedSendingParams.hasOwnProperty('email')) {
      updatedSending = 'email';

      if (!updatedSendingParams.email.order.default_cc.includes(email)) {
        updatedSendingParams.email.order.default_cc.push(email);
      }

      if (!updatedSendingParams.email.credit.default_cc.includes(email)) {
        updatedSendingParams.email.credit.default_cc.push(email);
      }
    }

    if (updatedSendingParams.hasOwnProperty('edi')) {
      updatedSending = 'edi';
    }

    setSending(updatedSending);
    setSendingParams(updatedSendingParams);
  };

  const initializeOrderForm = () => {
    if (params.orderId.toString() !== '' || params.storeId) {
      setStoreId(params.storeId);
      setCurrentStoreName(params.storeName);
      setConfirmedStoreId(params.storeId);

      const matchingStore = stores.find((store) => store.id === params.storeId);

      setSelectedStore(matchingStore);

      if (matchingStore) {
        setPartnerId(matchingStore.partnerId);
      }
    }

    if (params.isDuplicatingOrder) {
      // Set orderId to get product orders from duplicated orders
      setOrderId(params.orderId);

      setSupplierId(order.supplierId);
      setConfirmedSupplierId(order.supplierId);
      setSupplierName(order.lnkSupplierOrderrel.name);

      setPurchaseOrderComment(order.purchaseOrderComment);

      const { emailRecipientsToUse, emailRecipientsCopyToUse } = getEmailsToUse();

      setEmailRecipients(emailRecipientsToUse);
      setEmailRecipientsCopy(emailRecipientsCopyToUse);

      // Leave statuses to default, dates will be set by the HeaderSelectors using the next available delivery date
      return;
    }

    if (params.orderId.toString() !== '') {
      setOrderId(params.orderId);
      setOrderStatus(parseInt(order.status, 10));
      setPreparationStatus(
        order.preparationStatus != null ? parseInt(order.preparationStatus) : null,
      );

      setIsEditable(order.isEditable);

      const matchingStore = stores.find((store) => store.id === params.storeId);

      setReceptionDate(moment.tz(order.receptionDate, userTimezone));

      setEndDate(moment.tz(order.endOrderDate, matchingStore.timezone).endOf('day'));
      setStartDate(moment.tz(order.startOrderDate, matchingStore.timezone).startOf('day'));

      if (order.pictures) {
        setPicturesUrls(order.pictures);
      } else {
        setPicturesUrls([]);
      }
      setReceptionComment(order.receptionComment);
      setReceptionReference(order.receptionReference);

      setSupplierId(order.supplierId);
      setConfirmedSupplierId(order.supplierId);
      setSupplierName(order.lnkSupplierOrderrel.name);

      setPurchaseOrderComment(order.purchaseOrderComment);

      const { emailRecipientsToUse, emailRecipientsCopyToUse } = getEmailsToUse();

      setEmailRecipients(emailRecipientsToUse);
      setEmailRecipientsCopy(emailRecipientsCopyToUse);

      setAllCreditPicturesCount(get(order, 'creditOrderPictureUrls', []).length); // Initialize the count of order releated credit pictures

      return;
    }
  };

  const setLimitOrderDayBySupplierIdAndDate = (supplierId, date) => {
    const selectedSupplier = suppliers.find(({ id }) => id === supplierId);

    const limitOrderDay = utilsLib.getMaxOrderDateOfSupplierFromDate(
      selectedSupplier,
      date,
      storeTimezone,
    );

    if (!limitOrderDay.canStillOrder && !orderId) {
      setModalType(ORDER_MODAL_TYPE.DATE_ORDER_LIMIT);
    }

    setMaxOrderDate(limitOrderDay.maxOrderDate);
    setCanStillOrder(limitOrderDay.canStillOrder);
  };

  const receiveSupplierProducts = () => {
    if (orderStatus === ORDER_STATUS.CREATION) {
      setSupplierProducts(supplierProductsCustom);

      return;
    }

    if (orderStatus > ORDER_STATUS.SENT) {
      const formattedProductOrders = productOrders.filter(
        ({ type }) => type === PRODUCT_ORDER_TYPE.RECEIVED,
      );

      let formattedSupplierProducts = formattedProductOrders.map(
        ({ lnkSupplierproductProductorderrel }) => lnkSupplierproductProductorderrel,
      );

      formattedSupplierProducts.map((supplierProduct) => {
        if (!supplierProduct.subCategory || supplierProduct.subCategory === '') {
          supplierProduct.subCategory = 'Autres';
        }

        return supplierProduct;
      });

      setSupplierProducts(formattedSupplierProducts);

      return;
    }

    const supplierProductIds = supplierProductsCustom.map(({ id }) => id);

    const formattedSupplierProducts = [...supplierProductsCustom];

    productOrders.forEach((productOrder) => {
      if (!supplierProductIds.includes(productOrder.lnkSupplierproductProductorderrel.id)) {
        formattedSupplierProducts.push({
          ...productOrder.lnkSupplierproductProductorderrel,
          available: false,
        });
      }
    });

    setSupplierProducts(uniqBy(formattedSupplierProducts, 'id'));
  };

  const closeOrderModalAfterSaving = (message) => {
    closeModal();

    params.reloadOrders();

    props.showMessage(message);
  };

  /**************/
  /** HANDLERS **/
  /**************/

  const handleCloseErrorModal = () => {
    if (!isEditingDeliveryDate) {
      setCategories([]);
    }

    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStartDate(null);
    setEndDate(null);
    setModalType(null);
    setIsConfirmable(false);
    setMaxOrderDate(null);
    setOrderTimeLimit(null);
  };

  const handleReceivedDiff = (receivedDiff, productId) => {
    const newReceivedDiffOfProductOrder = receivedDiffOfProductOrder;

    newReceivedDiffOfProductOrder[productId] = receivedDiff;

    setReceivedDiffOfProductOrder(newReceivedDiffOfProductOrder);
  };

  const handleInvoicedDiff = (invoicedDiff, productId) => {
    const newInvoicedDiffOfProductOrder = invoicedDiffOfProductOrder;

    newInvoicedDiffOfProductOrder[productId] = invoicedDiff;

    setInvoicedDiffOfProductOrder(newInvoicedDiffOfProductOrder);
  };

  const handleUpdateOrderTotal = () => {
    let total = 0;

    const newTotalByCategories = { ...totalByCategories };

    categories.forEach((category) => {
      let totalCategory = 0;

      category.products.forEach((product) => {
        totalCategory += product.price * product.ordered;
      });

      total += totalCategory;

      newTotalByCategories[category.name] = totalCategory;
    });

    setOrderTotal(total);
    setTotalByCategories(newTotalByCategories);
  };

  const handleCloseOrderForm = () => {
    setModalType(null);

    params.reloadOrders();

    closeModal();
  };

  const handleSelectSupplier = (name, id) => {
    setSupplierId(id);
    setSupplierName(name);
    setHasChangedHeaderParameter(true);
  };

  const handleSelectStore = (name, id) => {
    setStoreId(id);
    setCurrentStoreName(name);
    setHasChangedHeaderParameter(true);

    const matchingStore = stores.find(({ id: storeId }) => storeId === params.storeId);

    setSelectedStore(matchingStore);
  };

  const handleSelectDates = ({ startDate, endDate }) => {
    setSelectedEndDate(endDate);
    setSelectedStartDate(startDate);
    if (isEditingDeliveryDate) {
      return;
    }

    setHasChangedHeaderParameter(true);
  };

  const handleReceptionComment = (comment) => setReceptionComment(comment);

  const handleReceptionReference = (reference) => setReceptionReference(reference);

  const isFirstOrderOfThisMonth = () => {
    const orderDatesFromThisMonth = getSentOrderDatesFromLastMonth();
    return !orderDatesFromThisMonth.includes(startDate.startOf('day').format());
  };

  // Remove all CSS of the purchase order comment so when users copy paste text from other sites we don't end up with unreadable text in the purchase order
  const formatPurchaseOrderCommentForPDF = (purchaseOrderComment) => {
    const formattedPurchaseOrderComment = purchaseOrderComment || '';
    const htmlPurchaseOrderComment = document.createElement('div');
    htmlPurchaseOrderComment.innerHTML = formattedPurchaseOrderComment.trim();

    const elements = htmlPurchaseOrderComment.getElementsByTagName('*');

    // We have to use a for loop instead of a forEach or map because the object is a HTML collection and we can only iterate over it with a for
    for (const elem of elements) {
      elem.removeAttribute('style');
      elem.style.font = theme.fonts.textMicroWidth8Height10;
      elem.style.color = theme.colors.greys.darkest;
    }

    return htmlPurchaseOrderComment.innerHTML;
  };

  const handleSave = async (source) => {
    const isSaving = source === 'save';

    if (
      source === 'send' &&
      isFirstOrderOfThisMonth() &&
      orderTotal < supplierInfos.orderMinAmount &&
      !canOrderWithMin
    ) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.FORM_SUM_IS_LOWER_THAN_THE_ORDER_MINIMUM', {
          orderMinAmount: supplierInfos.orderMinAmount,
          currency: (supplierInfos.currency || defaultCurrency).alphabeticCode,
        }),
        'error',
      );

      return;
    }

    const totalItems = utilsLib.getTotalAmount(productsInventory);

    if (
      source === 'send' &&
      isFirstOrderOfThisMonth() &&
      totalItems < supplierInfos.orderMinUnit &&
      !canOrderWithMin
    ) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.FORM_SUM_IS_LOWER_THAN_THE_ORDER_MINIMUM_UNIT', {
          orderMinUnits: supplierInfos.orderMinUnit,
        }),
        'error',
      );

      return;
    }

    if (
      !(maxOrderDate !== null
        ? moment.tz(storeTimezone).isBefore(moment.tz(maxOrderDate, storeTimezone))
        : true)
    ) {
      setModalType(ORDER_MODAL_TYPE.DATE_ORDER_LIMIT_AFTER_EDIT);

      return;
    }

    const isOrderable = productsInventory.some((category) =>
      category.products.some((product) => product.ordered && product.ordered > 0),
    );

    if (orderId === '' && (!isOrderable || !startDate || !endDate)) {
      return;
    }

    setIsSendingRequest(true);

    try {
      pageLoading();

      // For Sellsy EDI partner, we don't need to format the purchase order comment in html
      const formattedPurchaseOrderComment =
        supplierInfos.ediPartner === 'SELLSY'
          ? purchaseOrderComment
          : formatPurchaseOrderCommentForPDF(purchaseOrderComment);

      const message = await utilsLib.sendOrder({
        params,
        source,
        orderId,
        storeId,
        sending,
        endDate,
        startDate,
        partnerId,
        storeInfos,
        supplierId,
        setOrderId,
        productOrders,
        sendingParams,
        supplierInfos,
        receptionComment,
        orderOpeningTime,
        productsInventory,
        receptionReference,
        clientName,
        purchaseOrderComment: formattedPurchaseOrderComment,
        emailRecipients,
        emailRecipientsCopy,
        isDuplicatingOrder,
      });

      setIsRetrievingData(true);
      setShouldRetrieveData(true);

      const orderReference = get(order, 'reference', reference);
      const orderTimestamp = get(
        order,
        'timestamp',
        moment.tz(userTimezone).format('DD/MM/YYYY HH:mm:ss'),
      ); // equivalent to order.orderDate

      mixpanelUtils.sendMetric(ENUM_EVENTS.SEND_ORDER, {
        orderReference,
        timestamp: orderTimestamp,
      });

      handleAppRefresh(shouldReloadApp);
      return closeOrderModalAfterSaving(message);
    } catch (error) {
      setDisableSendButton(true);
      const customError = get(error, 'response.data.message.custom_error', null);

      let errorDetail = get(error, 'response.data.message.error_detail', null);
      let errorMessage = i18next.t(
        isSaving
          ? 'ORDERS.ORDERS.SAVING_ERROR_SLOW_INTERNET'
          : 'ORDERS.ORDERS.SENDING_ERROR_SLOW_INTERNET',
      );

      if (!!errorDetail) {
        if (errorDetail === SEND_ORDER_ERRORS.DUPLICATED_REFERENCE) {
          errorMessage = i18next.t('ORDERS.ORDERS.SENDING_ERROR_DUPLICATE_REFERENCE');
        }
        if (errorDetail === SEND_ORDER_ERRORS.SEND_EMAIL_FAILED) {
          errorMessage = i18next.t('ORDERS.ORDERS.SENDING_ERROR_MAILJET_ERROR');
        }
        if (errorDetail === SEND_ORDER_ERRORS.SEND_EDI_FAILED) {
          errorMessage = i18next.t('ORDERS.ORDERS.SENDING_ERROR_EDI_ERROR');
        }
      }

      closeModal();
      params.reloadOrders();

      if (
        errorDetail &&
        errorDetail.startsWith(SEND_ORDER_ERRORS.SEND_EDI_GENERIC_FAILED_MESSAGE) &&
        // make sure that specific error is handled by modal, otherwise display generic toaster with error message
        modalConfiguration.getText({ errorDetail, customError }, {})
      ) {
        params.setEdiSettingsErrorModalParams(
          modalConfiguration.getEdiSupplierProductSettingsErrorModal(
            { errorDetail, customError },
            {
              reference,
              storeName: storeInfos.name,
              supplierName: supplierInfos.name,
            },
          ),
        );
        return;
      }

      props.showMessage(errorMessage, 'error', 5000);
    } finally {
      setIsSendingRequest(false);

      pageLoaded();
    }
  };

  const handleUploadCreditPicture = async (file, uploadType = null) => {
    const creditReference = order.reference.replace(/CMD/g, 'AV');

    const params = {
      partnerId,
      clientId: user.clientId,
      reference: creditReference,
      keepOriginalFileName: true,
    };

    const result = await services.uploadFile(
      file,
      !uploadType ? params : { ...params, type: uploadType },
      props.showMessage,
    );

    return encodeFileNameInUrl(result.data.fileUri);
  };

  /**
   * Handles the deletion of the product order anomaly picture
   * Actually is only used by "OrderCreditForm" component
   *
   * @param {string} productId - The ID of the product whose credit order picture is to be deleted.
   * @param {string} categoryName - The name of the category to which the product belongs.
   */
  const handleDeletePOCreditPicture = (productId, categoryName) => {
    const productOrders = productOrderToDisplay[categoryName].products;

    const mappedProductsInventory = productOrders.map((productGrouped) =>
      productGrouped.map((product) => {
        if (product.id === productId) {
          product.creditOrderPictureUrl = null;
        }
        return product;
      }),
    );

    // Need to call 2 functions to update order's products
    handleSetProductsOrder(categoryName, mappedProductsInventory);
    handleUpdateCategory(categoryName, mappedProductsInventory);
  };

  /**
   * Keep category related data and upload credit picture for each PO if needed and returns the formatted order products.
   *
   * Shape of orderProducts: [{name: 'the category name', products: [productOrder data], total: total of the category}]
   *
   * @param {Array} orderProducts - The list of order products to process.
   * @param {Object} orderProducts[].products - The products within each order category.
   * @returns {Promise<Array>} - A promise that resolves to the formatted order products with uploaded credit pictures.
   */
  const updloadProductOrdersCreditPicture = async (orderProducts) => {
    const formattedOrderProducts = [];

    for (const categoryRelatedData of orderProducts) {
      const formattedOrderSPs = [];

      for (const product of categoryRelatedData.products) {
        if (get(product.creditOrderPictureUrl, 'file', null)) {
          const creditPictureUri = await handleUploadCreditPicture(
            product.creditOrderPictureUrl.file,
            'product-order-credit-picture',
          );

          formattedOrderSPs.push({
            ...product,
            creditOrderPictureUrl: creditPictureUri,
          });
        } else {
          formattedOrderSPs.push({
            ...product,
            creditOrderPictureUrl: product.creditOrderPictureUrl,
          });
        }
      }

      formattedOrderProducts.push({
        ...categoryRelatedData,
        products: formattedOrderSPs,
      });
    }

    return formattedOrderProducts;
  };

  /**
   * Uploads order credit pictures and returns their URLs.
   *
   * This function takes an array of credit order picture URLs, checks if each URL
   * contains a file, and if so, uploads the file and replaces the URL with the
   * uploaded file's URL. If the URL does not contain a file, it is added to the
   * result as is.
   *
   * @param {Array<Object>} creditOrderPictureUrls - An array of objects representing credit order picture URLs.
   * @returns {Promise<Array<string>>} - A promise that resolves to an array of credit picture URLs.
   */
  const uploadOrderCreditPictures = async (creditOrderPictureUrls) => {
    const creditPictureUrls = [];

    for (const creditOrderPictureUrl of creditOrderPictureUrls) {
      if (get(creditOrderPictureUrl, 'file', null)) {
        const creditPictureUri = await handleUploadCreditPicture(
          creditOrderPictureUrl.file,
          'order-credit-picture',
        );
        creditPictureUrls.push(creditPictureUri);

        continue;
      }

      creditPictureUrls.push(creditOrderPictureUrl);
    }

    return creditPictureUrls;
  };

  const handlePreparationSave = async () => {
    pageLoading();

    try {
      const formattedProductOrders = getProductOrderForSendOrder(
        productsInventory,
        productOrders,
        null,
        orderId,
        isEditingPreparationOrder,
      );

      await orderService.setOrderToInPreparation(
        orderId,
        !!formattedProductOrders.length ? formattedProductOrders : null,
      );

      showSuccessMessage(i18next.t('ORDERS.PREPARATIONS.SAVE_SUCCESS'));

      closeModal();

      params.reloadOrders();
    } catch {
      showErrorMessage(i18next.t('ORDERS.PREPARATIONS.SAVE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handlePreparationValidation = async (shouldGenerateInvoice) => {
    pageLoading();

    try {
      const formattedProductOrders = getProductOrderForSendOrder(
        productsInventory,
        productOrders,
        null,
        orderId,
        isEditingPreparationOrder,
      );

      await orderService.setOrderToPrepared(
        orderId,
        !!formattedProductOrders.length ? formattedProductOrders : null,
        receptionComment && receptionComment.length ? receptionComment : null,
        shouldGenerateInvoice,
      );

      showSuccessMessage(i18next.t('ORDERS.PREPARATIONS.VALIDATION_SUCCESS'));

      closeModal();
      params.reloadOrders();
    } catch {
      showErrorMessage(i18next.t('ORDERS.PREPARATIONS.VALIDATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleSaveDelivery = async (isDraftReception = false, withMail = true) => {
    if (constraints && constraints.includes('receptionPicture') && !picturesUrls.length) {
      props.showMessage(i18next.t('ORDERS.ORDERS.FORM_DELIVERY_FORM_PICTURE_MISSING'), 'error');

      return;
    }

    setIsSendingRequest(true);

    const { emailRecipientsToUse, emailRecipientsCopyToUse } = getEmailsToUse();

    pageLoading();

    let creditPictureUrls = [];

    if (!isEmpty(creditRequestData.creditOrderPictureUrls)) {
      creditPictureUrls = await uploadOrderCreditPictures(creditRequestData.creditOrderPictureUrls);
    }

    const formattedProductsInventory = await updloadProductOrdersCreditPicture(productsInventory);

    try {
      const message = await utilsLib.sendValidatedDelivery(
        {
          params,
          orderId,
          storeId,
          sending: sending,
          endDate,
          startDate,
          partnerId,
          storeInfos,
          supplierId,
          sendingParams,
          supplierInfos,
          receptionComment,
          orderOpeningTime,
          productsInventory: formattedProductsInventory,
          user: params.user,
          picturesUris: picturesUrls,
          receptionReference,
          showMessage: props.showMessage,
          isDeliveryDateUpdated,
          clientName,
          emailRecipients: emailRecipientsToUse,
          emailRecipientsCopy: emailRecipientsCopyToUse,
          creditRequestData,
          creditPictureUrls,
        },
        isDraftReception,
        withMail,
      );

      setIsRetrievingData(true);
      setShouldRetrieveData(true);
      handleAppRefresh(shouldReloadApp);
      closeOrderModalAfterSaving(message);
    } catch (err) {
      const products = [];

      productsInventory.forEach((category) => {
        Array.prototype.push.apply(products, category.products);
      });

      const hasAnomalies = nbAnomalies > 0;

      setDisableSendButton(true);
      props.showMessage(
        i18next.t(
          isDraftReception || !hasAnomalies
            ? 'ORDERS.ORDERS.SAVING_ERROR_SLOW_INTERNET'
            : 'ORDERS.ORDERS.SENDING_CREDIT_NOTE_ERROR_MAILJET_ERROR',
        ),
        'error',
        5000,
      );

      setTimeout(() => {
        setDisableSendButton(false);
      }, 5000);
    } finally {
      setIsSendingRequest(false);

      pageLoaded();
    }
  };

  const handleSaveDeliveryConfirmationModal = (isDraftReception) => {
    if (constraints && constraints.includes('receptionPicture') && !picturesUrls.length) {
      props.showMessage(i18next.t('ORDERS.ORDERS.FORM_DELIVERY_FORM_PICTURE_MISSING'), 'error');

      return;
    }

    const products = [];

    productsInventory.forEach((category) => {
      Array.prototype.push.apply(products, category.products);
    });

    handleSaveDelivery(isDraftReception);
  };

  const handleSendConfirmationModal = () => {
    if (
      isFirstOrderOfThisMonth() &&
      orderTotal < supplierInfos.orderMinAmount &&
      !canOrderWithMin
    ) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.FORM_SUM_IS_LOWER_THAN_THE_ORDER_MINIMUM', {
          orderMinAmount: supplierInfos.orderMinAmount,
          currency: (supplierInfos.currency || defaultCurrency).alphabeticCode,
        }),
        'error',
      );

      return;
    }

    const totalItems = utilsLib.getTotalAmount(productsInventory);

    if (isFirstOrderOfThisMonth() && totalItems < supplierInfos.orderMinUnit && !canOrderWithMin) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.FORM_SUM_IS_LOWER_THAN_THE_ORDER_MINIMUM_UNIT', {
          orderMinUnits: supplierInfos.orderMinUnit,
        }),
        'error',
      );

      return;
    }

    if (
      maxOrderDate &&
      moment.tz(storeTimezone).isSameOrAfter(moment.tz(maxOrderDate, storeTimezone))
    ) {
      setModalType(ORDER_MODAL_TYPE.DATE_ORDER_LIMIT_AFTER_EDIT);

      return;
    }

    if (sending === 'email' && !!get(sendingParams, 'email.order.default_cc')) {
      if (
        shouldOrderWithMinConfirmationModalBeDisplayed &&
        canOrderWithMin &&
        (orderTotal < supplierInfos.orderMinAmount || totalItems < supplierInfos.orderMinUnit)
      ) {
        setDisplayOrderWithMinConfirmationModal(true);
        setShouldOrderWithMinConfirmationModalBeDisplayed(false);

        return;
      }

      if (supplierInfos && supplierInfos.ediPartner && supplierInfos.isEdi) {
        if (ediPartnerWithRequiredCustomerCode.includes(supplierInfos.ediPartner)) {
          if (!!supplierInfos.customerCode && supplierInfos.customerCode !== '') {
            return setDisplaySendToPartnerConfirmationModal(true);
          }
          return setDisplaySendingError('customerCode');
        }
        return setDisplaySendToPartnerConfirmationModal(true);
      }
      handleSave('send');
      return;
    }

    handleSave('send');
  };

  const handleUpdateCategory = (categoryName, arrayOfSupplierProductsGroupByEntityId) => {
    let total = 0;

    arrayOfSupplierProductsGroupByEntityId.forEach((productsOfEntity) => {
      productsOfEntity.forEach((product) => {
        total += product.price * product.ordered;
      });
    });

    let updatedOrderTotal = 0;

    const updatedProductInventory = productsInventory.map((category) => {
      if (category.name === categoryName) {
        category.products = flattenArray(arrayOfSupplierProductsGroupByEntityId);
        category.total = total;
      }

      updatedOrderTotal += category.total;

      return category;
    });

    const getProductsOrderedCount = (products) =>
      products.reduce((productAcc, { ordered }) => {
        if (ordered > 0) {
          ++productAcc;
        }
        return productAcc;
      }, 0);

    const nbReferencesOrdered = productsInventory.reduce((categoryAcc, category) => {
      categoryAcc += getProductsOrderedCount(category.products);

      return categoryAcc;
    }, 0);

    setNbReferencesOrdered(nbReferencesOrdered);
    setOrderedUnit(utilsLib.getTotalAmount(categories));
    setOrderTotal(updatedOrderTotal);
    setProductsInventory(updatedProductInventory);
  };

  const isReceptionFormDirty = () => {
    const FIELDS_TO_CHECK = ['id', 'invoiced', 'received', 'priceBDL', 'price'];

    if (![ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus) || isSendingRequest) {
      return false;
    }

    if (!initialCategorizedProducts) {
      return false;
    }

    const totalInitialProducts = Object.keys(initialCategorizedProducts).reduce((total, key) => {
      initialCategorizedProducts[key].products.forEach((products) => (total += products.length));

      return total;
    }, 0);

    const totalProducts = Object.keys(categorizedProducts).reduce((total, key) => {
      categorizedProducts[key].products.forEach((products) => (total += products.length));

      return total;
    }, 0);

    if (totalProducts !== totalInitialProducts) {
      return true;
    }

    return Object.keys(categorizedProducts).some((key) =>
      categorizedProducts[key].products.some((products, indexEntity) =>
        products.some((product, indexProduct) => {
          const initialProduct = get(
            initialCategorizedProducts,
            `[${key}][${indexEntity}][${indexProduct}]`,
          );

          if (!initialProduct) {
            return true;
          }

          const initialFieldsToCheck = pick(initialProduct, FIELDS_TO_CHECK);

          if (initialFieldsToCheck.priceBDL == null) {
            initialFieldsToCheck.priceBDL = Math.round(initialFieldsToCheck.price * 100) / 100;
          }

          const fieldsToCheck = pick(product, FIELDS_TO_CHECK);

          return !isEqual(initialFieldsToCheck, fieldsToCheck);
        }),
      ),
    );
  };

  const handleOrderWithConfirmationCloseModal = () => {
    setDisplayOrderWithMinConfirmationModal(false);
    if (canOrderWithMin) {
      setShouldOrderWithMinConfirmationModalBeDisplayed(true);
    }
  };

  const handleCloseSendToPartnerConfirmationModal = () => {
    setDisplaySendToPartnerConfirmationModal(false);
    if (canOrderWithMin) {
      setShouldOrderWithMinConfirmationModalBeDisplayed(true);
    }
  };

  const handleClickClose = () => {
    if ((isConfirmable || isReceptionFormDirty()) && orderStatus < ORDER_STATUS.COMPLIANT) {
      setModalType(ORDER_MODAL_TYPE.CLOSE);

      return;
    }

    handleCloseOrderForm();
  };

  /**
   * Avoids multiple conditional renders for EmptyState
   * @param {boolean} noSuppliers Does the selected store have any mapped supplier
   * @param {boolean} datesNotSet Are both the startDate AND endDate defined
   * @param {boolean} isGeneratingData Are we loading something
   * @param {boolean} hasNoResult Was the search unsuccessful
   * @returns An object containing the necessary props for a label
   */
  const getEmptyStateProps = (noSuppliers, datesNotSet, isGeneratingData, hasNoResult) => {
    if (noSuppliers) {
      return {
        icon: '/images/inpulse/empty-state-supplier-profile.svg',
        label: i18next.t('ORDERS.ORDERS.FORM_EMPTY_STATE_NO_SSPM', {
          storeName: currentStoreName,
        }),
      };
    }

    if (datesNotSet && !isGeneratingData) {
      return {
        icon: null,
        label: i18next.t('ORDERS.ORDERS.FORM_EMPTY_STATE_PERIOD_NOT_SET'),
      };
    }

    if (hasNoResult) {
      return {
        icon: '/images/inpulse/no-results-search.svg',
        label: i18next.t('ORDERS.ORDERS.FORM_EMPTY_STATE_SUPPLIER_PRODUCT_NOT_FOUND'),
      };
    }

    return null;
  };

  /********************************/
  /** SIDE EFFECTS STATE UPDATED **/
  /********************************/

  useLayoutEffect(() => {
    initializeSendindParamsConfiguration();

    initializeOrderForm();
  }, []);

  const _isFirstElementAvailable = (products, currentProduct, index) =>
    products.slice(0, index).every((product) => !product.available) && currentProduct.available;

  useEffect(() => {
    if (isEmpty(productOrderToDisplay)) {
      return;
    }

    const productOrderCloned = cloneDeep(productOrderToDisplay);

    Object.keys(productOrderCloned).forEach((category) => {
      productOrderCloned[category].products.forEach((productArray) => {
        productArray.forEach((product, index) => {
          if (product.entityId && product.entityStockEvent.recommandation > 0) {
            if (
              !!prefillInpulse &&
              !!product.available &&
              _isFirstElementAvailable(productArray, product, index)
            ) {
              product.ordered = sumUpRecommendations(product);
            } else {
              product.ordered = product.saveOrdered;
            }
          }
        });
      });
      handleUpdateCategory(category, productOrderCloned[category].products);
    });

    setProductOrderToDisplay(productOrderCloned);
  }, [prefillInpulse]);

  // Side effect when default store id is set or when user changes the selected store
  useEffect(() => {
    if (!storeId) {
      return;
    }

    setConfirmedStoreId(storeId);

    const store = stores.find((store) => store.id === storeId);

    if (store) {
      setStoreInfos({
        id: store.id,
        name: store.name,
        address: store.adress,
        country: store.country,
        timezone: store.timezone,
        phone: store.telephone,
        brand: get(store, 'lnkBrandStorerel.name', ''),
        ZIPCity: `${store.postCode || ''} ${store.city || ''}`,
        stockConvention: get(store, 'stockConvention', 'end'),
      });

      setPartnerId(store.partnerId);
    }

    (async () => {
      const [orders, suppliersOfStore] = await Promise.all([
        services.getOrdersByStoreId(storeId, props.showMessage),
        services.getSuppliersByStoreId(storeId, props.showMessage),
      ]);

      setPastOrders(orders);
      setSuppliers(suppliersOfStore);

      if (isDuplicatingOrder) {
        setSupplierId(order.supplierId);
        setSupplierName(order.lnkSupplierOrderrel.name);
        setConfirmedSupplierId(order.supplierId);

        setIsLoadingHeader(false);

        return;
      }

      if (!suppliersOfStore.length) {
        setSupplierId('');
        setSupplierName('');
        setConfirmedSupplierId('');

        setCategories([]);
        setMaxOrderDate(null);
        setCanStillOrder(false);
        setPrefillInpulse(false);

        setIsLoadingHeader(false);

        return;
      }

      if (orderStatus === ORDER_STATUS.CREATION) {
        const defaultSupplier = first(suppliersOfStore);

        setSupplierId(defaultSupplier.id);
        setSupplierName(defaultSupplier.name);
        setConfirmedSupplierId(defaultSupplier.id);
      }

      setIsLoadingHeader(false);
    })();
  }, [storeId]);

  // Side effect when default supplier id is set or when user changes the selected store
  useEffect(() => {
    if (!supplierId) {
      return;
    }

    if (supplierId !== '' && orderStatus !== ORDER_STATUS.CREATION) {
      setLimitOrderDayBySupplierIdAndDate(supplierId, startDate);
    }

    const matchingSupplier = suppliers.find((supplier) => supplier.id === supplierId);

    if (!matchingSupplier) {
      setSupplierInfos({});

      setDeliveryDays([]);
      setDeliveryLeadTimes([]);
      setFirstPossibleDeliveryDatesByDeliveryDay([]);

      setOrderTimeLimit('');

      return;
    }

    const type = orderStatus >= ORDER_STATUS.SENT ? 'credit' : 'order';

    const { exceptionalDatesWithDelivery, exceptionalDatesWithoutDelivery } =
      utilsLib.getExceptionalDeliveryDates(
        matchingSupplier.exceptionalDeliveryDays,
        matchingSupplier.orderTimeLimit,
        storeTimezone,
      );

    setSupplierInfos({
      id: matchingSupplier.id,
      name: matchingSupplier.name,
      address: matchingSupplier.adress,
      currency: matchingSupplier.currency,
      ZIPCity: (matchingSupplier.postCode || '') + ' ' + (matchingSupplier.city || ''),
      country: matchingSupplier.country,
      phone: matchingSupplier.telephone,
      email: utilsLib.getSupplierProfileEmails(matchingSupplier, type),
      deliveryDays: matchingSupplier.deliveryDays,
      exceptionalDeliveryDates: exceptionalDatesWithDelivery,
      exceptionalNoDeliveryDates: exceptionalDatesWithoutDelivery,
      ediPartner: matchingSupplier.ediPartner,
      orderMinAmount: matchingSupplier.orderMinAmount || 0,
      orderMinUnit: matchingSupplier.orderMinUnit || 0,
      customerCode: matchingSupplier.customerCode || '',
      isEdi: matchingSupplier.isEdi || false,
      enableMultipleOrderOnSameDate: !!matchingSupplier.enableMultipleOrderOnSameDate,
      allowDecimalQuantitiesOrder: matchingSupplier.allowDecimalQuantitiesOrder,
      isKitchen: matchingSupplier.isKitchen,
    });

    setSupplierHasEDI(matchingSupplier.ediPartner && matchingSupplier.isEdi);

    if (!(matchingSupplier.deliveryLeadTimes && matchingSupplier.deliveryDays)) {
      setDeliveryDays([]);
      setDeliveryLeadTimes([]);
      setFirstPossibleDeliveryDatesByDeliveryDay([]);

      setOrderTimeLimit('');

      return;
    }

    const supplierDeliveryDays = matchingSupplier.deliveryDays
      .split(';')
      .map((deliveryDay) => parseInt(deliveryDay));

    const supplierDeliveryLeadTimes = matchingSupplier.deliveryLeadTimes
      .split(';')
      .map((deliveryLeadTime) => parseInt(deliveryLeadTime));

    const supplierOrderTimeLimit = matchingSupplier.orderTimeLimit;

    const supplierFirstPossibleDeliveryDatesByDeliveryDay =
      utilsLib.getFirstDeliveryWeekDaysOfSupplierProfile(
        supplierDeliveryDays,
        supplierDeliveryLeadTimes,
        supplierOrderTimeLimit,
        storeTimezone,
      );

    setDeliveryDays(supplierDeliveryDays);
    setDeliveryLeadTimes(supplierDeliveryLeadTimes);
    setFirstPossibleDeliveryDatesByDeliveryDay(supplierFirstPossibleDeliveryDatesByDeliveryDay);

    setOrderTimeLimit(supplierOrderTimeLimit);
  }, [supplierId, suppliers]);

  // Validate selected dates from date picker
  useEffect(() => {
    if (!selectedStartDate || !supplierInfos || !supplierInfos.id) {
      return;
    }

    const existingOrdersDates = pastOrders
      .filter(
        ({ storeId: pastOrderStoreId, supplierId: pastOrderSupplierId }) =>
          pastOrderStoreId === storeId && pastOrderSupplierId === supplierId,
      )
      .map(({ startOrderDate }) =>
        moment.tz(startOrderDate, storeTimezone).startOf('day').format('L'),
      )
      .filter((orderDate) =>
        orderStatus === ORDER_STATUS.DRAFT && (isUserAdmin(user) || isUserDeepsight(user))
          ? !(
              orderDate ===
              moment.tz(order.startOrderDate, storeTimezone).startOf('day').format('DD/MM/YYYY')
            )
          : true,
      );

    if (
      existingOrdersDates.includes(selectedStartDate.format('L')) &&
      !supplierInfos.enableMultipleOrderOnSameDate
    ) {
      setNewOrderDatesRecorded(false);

      setModalType(ORDER_MODAL_TYPE.DATE_ORDER_EXISTS);

      return;
    }

    const { exceptionalDeliveryDates, exceptionalNoDeliveryDates } = supplierInfos;

    if (exceptionalNoDeliveryDates.includes(selectedStartDate.format())) {
      setNewOrderDatesRecorded(false);
      setModalType(ORDER_MODAL_TYPE.DATE_SUPPLIER_PROFILE);

      return;
    }

    if (!isEditingDeliveryDate) {
      let startDateIndex;
      let supplierDeliveryDays;

      if (supplierInfos && supplierInfos.deliveryDays) {
        startDateIndex = moment(selectedStartDate).day() - 1;
        startDateIndex = startDateIndex !== -1 ? startDateIndex : 6;
        supplierDeliveryDays = supplierInfos.deliveryDays
          .split(';')
          .map((string) => Number(string));
      }

      if (
        supplierDeliveryDays &&
        (startDateIndex || startDateIndex === 0) &&
        !supplierDeliveryDays.includes(startDateIndex) &&
        !exceptionalDeliveryDates.includes(selectedStartDate.format())
      ) {
        setNewOrderDatesRecorded(false);

        setModalType(ORDER_MODAL_TYPE.DATE_SUPPLIER_PROFILE);

        return;
      }
    }

    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    if (isEditingDeliveryDate) {
      return;
    }

    setConfirmedStoreId(storeId);
    setConfirmedSupplierId(supplierId);

    setCategories([]);
    setNewOrderDatesRecorded(true);
    setPrefillInpulse(false);

    setLimitOrderDayBySupplierIdAndDate(supplierId, selectedStartDate);
  }, [selectedStartDate, selectedEndDate, supplierInfos.id]);

  useEffect(() => {
    if (
      [
        ORDER_STATUS.COMPLIANT,
        ORDER_STATUS.INCOMPLETE,
        ORDER_STATUS.NON_COMPLIANT,
        ORDER_STATUS.CREDIT_REQUEST_PROCESSED,
      ].includes(orderStatus)
    ) {
      setDisableSendButton(true);
    }
  }, [orderStatus]);

  // Side effect when dates are valid to make an order
  useEffect(() => {
    if (!startDate || !endDate || !confirmedSupplierId || !confirmedStoreId) {
      return;
    }

    if (isEditingDeliveryDate) {
      setIsEditingDeliveryDate(false);
      setDisableSendButton(false);
      setIsDeliveryDateUpdated(true);
      return;
    }

    setIsGeneratingData(true);

    (async () => {
      let receivedProductOrders = [];

      if (orderStatus !== ORDER_STATUS.CREATION || isDuplicatingOrder) {
        receivedProductOrders = await services.getProductOrdersByOrderId(
          orderId,
          props.showMessage,
        );

        let poCreditPictureCount = 0;

        const formattedProductOrders = receivedProductOrders.map((productOrder) => {
          let formattedProductOrder = { ...productOrder };

          delete formattedProductOrder.lnkSupplierproductProductorderrel;

          formattedProductOrder.lnkSupplierproductProductorderrel =
            computePackagingToSupplierProduct(
              productOrder.lnkSupplierproductProductorderrel,
              productOrder.supplierProductPackagingId,
            );

          if (get(productOrder, 'creditOrderPictureUrl', null)) {
            poCreditPictureCount++;
          }

          return formattedProductOrder;
        });

        setProductOrders(formattedProductOrders);
        setAllCreditPicturesCount(poCreditPictureCount + allCreditPicturesCount); // Initialize the count of PO related credit pictures
      }

      if (
        orderStatus === ORDER_STATUS.CREATION &&
        constraints.includes('stockReassemblyMandatory')
      ) {
        const isStockValidForStoreByDate = await services.isStockValidForStoreByDate(
          storeId,
          moment.tz(storeTimezone).format(),
          props.showMessage,
        ); // TODO - [TIMEZONES] Apply timezone for this route in IPP-5227 Stock Module

        if (!isStockValidForStoreByDate) {
          setIsGeneratingData(false);

          setModalType(ORDER_MODAL_TYPE.STOCK_REASSEMBLY_NOT_DONE);

          props.showMessage(i18next.t('ORDERS.ORDERS.FORM_STOCK_INVENTORY_MISSING'), 'error');

          return;
        }
      }

      if (
        constraints.includes('forecastsValidated') &&
        startDate &&
        endDate &&
        orderStatus === ORDER_STATUS.CREATION
      ) {
        const isForecastValidForStoreByDate = await services.isForecastValidForStoreByDate(
          storeId,
          startDate.startOf('day').format(),
          endDate.endOf('day').format(),
          props.showMessage,
        );

        if (!isForecastValidForStoreByDate) {
          setIsGeneratingData(false);

          setModalType(ORDER_MODAL_TYPE.FORECAST_NOT_CONFIRMED);

          props.showMessage(i18next.t('ORDERS.ORDERS.FORM_FORECAST_VALIDATION_MISSING'), 'error');

          return;
        }
      }

      try {
        const receivedSupplierProductsCustom =
          await services.getSupplierProductsCustomByStoreIdAndSupplierId(
            supplierId,
            storeId,
            props.showMessage,
            startDate,
          );

        const formatSupplierProductsCustom = receivedSupplierProductsCustom.map(
          (supplierProduct) => {
            const matchingProductOrder = receivedProductOrders.find(
              ({ supplierProductId }) => supplierProductId === supplierProduct.id,
            );

            return computePackagingToSupplierProduct(
              {
                ...supplierProduct,
                subCategory: supplierProduct.subCategory || i18next.t('GENERAL.OTHER'),
                packagings: get(
                  matchingProductOrder,
                  'lnkSupplierproductProductorderrel.packagings',
                  supplierProduct.packagings,
                ),
              },
              matchingProductOrder && matchingProductOrder.supplierProductPackagingId,
            );
          },
        );

        setCountProductOrder(formatSupplierProductsCustom.length);
        setSupplierProductsCustom(formatSupplierProductsCustom);
      } catch {
        showErrorMessage(i18next.t('ORDERS.ORDERS.SUPPLIER_PRODUCTS_FETCH_ERROR'));

        setCountProductOrder(0);
        setSupplierProductsCustom([]);
      }
    })();
  }, [startDate, endDate, confirmedSupplierId, confirmedStoreId]);

  useEffect(() => {
    receiveSupplierProducts();
  }, [supplierProductsCustom]);

  useEffect(() => {
    if (!startDate || !endDate || !supplierProductsCustom || !supplierProductsCustom.length) {
      return;
    }

    receiveSupplierProducts();
  }, [productOrders]);

  useEffect(() => {
    if (!storeId) {
      return;
    }

    if (
      (!supplierProducts.length || !productOrders.length) &&
      orderStatus !== ORDER_STATUS.CREATION
    ) {
      return;
    }

    if (!startDate || !endDate) {
      setCategories([]);
      return;
    }

    if (addingNewReference) {
      return;
    }

    (async () => {
      let formattedProductOrders = cloneDeep(productOrders);

      const dataContainer = utilsLib.generateDataContainer(
        params,
        supplierProducts,
        formattedProductOrders,
        orderStatus,
        isEditable,
        preparationStatus,
        isDuplicatingOrder,
      );

      let categories = dataContainer.formattedCategories;
      if (!isEmpty(productOrderToDisplay)) {
        const productOrderOfCategoriesKeyById = Object.values(productOrderToDisplay).reduce(
          (result, productsGroupedByEntityId) => {
            productsGroupedByEntityId.products.forEach((productsOfEntity) => {
              productsOfEntity.forEach((product) => {
                result[product.id] = product;
              });
            });

            return result;
          },
          {},
        );

        formattedProductOrders = productOrders.map((productOrder) => ({
          ...productOrder,
          checked: get(
            productOrderOfCategoriesKeyById,
            `[${productOrder.supplierProductId}].checked`,
            true, // We set true by default because it means that's a new reference and specs say that new refs should be automatically equal to true
          ),
        }));

        /**
         *  When adding a new reference, the call to utilsLib.generateDataContainer above 'resets' the order's content to how it was right before
         *  productOrderToDisplay (productOrderOfCategoriesKeyById) is used since it is the temporary state that keeps up-to-date data.
         */
        categories = dataContainer.formattedCategories.map((category) => {
          /**
           * category is an object of the form
           * {
           *   name: string
           *   products: []
           *   total: number
           * }
           * */

          if (!!category.products.length) {
            const updatedProducts = category.products.map((product) => {
              if (productOrderOfCategoriesKeyById[product.id]) {
                // this means the product was in the state before adding the reference
                return productOrderOfCategoriesKeyById[product.id];
              }
              return product;
            });
            category.products = updatedProducts;
          }
          return category;
        });
      }

      setCategories(categories);
      setProductsInventory(categories);

      if (!newOrderDatesRecorded) {
        setIsConfirmable(dataContainer.isOrderConfirmable);
      }

      const categorized = {};

      categories.map((category) => {
        // DO NOT GROUP BY ENTITY ID IF FORM IS IN RECEPTION MODE
        const isReception = orderStatus >= ORDER_STATUS.SENT;
        category.products.forEach((product) => {
          product.isHidden = false;
        });

        const productGroupByEntity = groupBy(category.products, isReception ? 'id' : 'entityId');

        const orderedProduct = [];

        forEach(productGroupByEntity, (item, key) => {
          if (key !== 'null') {
            orderedProduct.push(sortBy(item, 'name'));
          }
        });

        if (productGroupByEntity['null']) {
          const productOrderEntityIdNull = sortBy(productGroupByEntity['null']);

          productOrderEntityIdNull.forEach((product) => {
            orderedProduct.push([product]);
          });
        }

        categorized[category.name] = { products: orderedProduct, isOpen: false };
      });

      setCategorizedProducts(categorized);

      if (isEmpty(categorized)) {
        setIsGeneratingData(false);
      }

      if (!initialCategorizedProducts) {
        setInitialCategorizedProducts(cloneDeep(categorized));
      }
    })();
  }, [addingNewReference, supplierProducts, productOrders]);

  useEffect(() => {
    setOrderedUnit(utilsLib.getTotalAmount(categories));
  }, [categories]);

  useEffect(() => {
    if (isConfirmable) {
      return;
    }

    setIsConfirmable(true);
  }, [orderedUnit, newOrderDatesRecorded]);

  useEffect(() => {
    handleUpdateOrderTotal();
  }, [categories]);

  useEffect(() => {
    const products = [];

    productsInventory.forEach((category) => {
      Array.prototype.push.apply(products, category.products);
    });

    const numberOfAnomalies = products.reduce((acc, product) => {
      const orderPackaging = product.packagings.find(({ isUsedInOrder }) => isUsedInOrder);

      const convertedPriceBDC = convertPriceToPackagingById(
        product.price,
        orderPackaging.id,
        product.receptionPackagingId || orderPackaging.id,
        product.packagings,
      );

      const formattedPriceBDC = Math.round(convertedPriceBDC * 100) / 100;
      const formattedPriceBDL = Math.round(product.priceBDL * 100) / 100;

      if (product.received !== product.invoiced || formattedPriceBDC !== formattedPriceBDL) {
        ++acc;
      }

      return acc;
    }, 0);

    setNbAnomalies(numberOfAnomalies);
  }, [productsInventory, orderTotal, totalBDL, totalReceived, productOrderToDisplay]);

  useEffect(() => {
    if (
      !storeId ||
      isEmpty(categorizedProducts) ||
      orderStatus > ORDER_STATUS.SENT_EDITABLE ||
      !hasChangedHeaderParameter
    ) {
      return;
    }

    const formattedEndDate = moment
      .tz(startDate, storeTimezone)
      .add(AMOUNT_DAY_TO_ADD_TO_END_DATE, 'day')
      .format('YYYY-MM-DD');
    const endIsBeforeFormattedEnd = moment
      .tz(formattedEndDate, storeTimezone)
      .isBefore(props.endDate, 'day');

    (async () => {
      await utilsLib.getEntitiesRecommandations({
        categorizedProducts,
        storeId,
        endDate: !endIsBeforeFormattedEnd
          ? formattedEndDate
          : moment.tz(endDate, storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        startOrderDate: moment
          .tz(startDate, storeTimezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        endOrderDate: moment
          .tz(endDate, storeTimezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        setProductsRecommendations,
        showMessage: props.showMessage,
        resetStockEveryDayForCentralKitchen,
        supplierInfos,
      });

      Object.keys(categorizedProducts).forEach((category) => {
        handleUpdateCategory(category, categorizedProducts[category].products);
      });

      setHasChangedHeaderParameter(false);
    })();
  }, [categorizedProducts]);

  useEffect(() => {
    if (orderStatus < ORDER_STATUS.SENT) {
      return;
    }

    const totals = Object.keys(productOrderToDisplay).reduce(
      (acc, key) => {
        for (const groupedProducts of productOrderToDisplay[key].products) {
          for (const product of groupedProducts) {
            const { packagingUsedInOrder, packagingUsedInReception } = computeTypesOfPackaging(
              product.receptionPackagingId,
              product.packagings,
            );

            const isOrderPackagingSameAsReceptionPackaging =
              packagingUsedInOrder.id === packagingUsedInReception.id;

            let convertedPriceBDC = product.price;
            if (!isOrderPackagingSameAsReceptionPackaging && !isEditingPreparationOrder) {
              convertedPriceBDC = convertPriceToPackagingById(
                product.price,
                packagingUsedInOrder.id,
                packagingUsedInReception.id,
                product.packagings,
              );
            }

            acc.sumBDL += product.priceBDL * product.invoiced;
            acc.sumReceived += convertedPriceBDC * product.received;
            acc.diffBDCReceived +=
              product.received * convertedPriceBDC - product.invoiced * product.priceBDL;
            acc.diffInvoicedBDC +=
              product.invoiced * product.priceBDL - product.ordered * convertedPriceBDC;
          }
        }

        return acc;
      },
      { sumBDL: 0, sumReceived: 0, diffBDCReceived: 0, diffInvoicedBDC: 0 },
    );

    setDiffBetweenReceivedAndOrdered(Math.round(totals.diffBDCReceived * 100) / 100);
    setDiffBetweenOrderedAndInvoiced(Math.round(totals.diffInvoicedBDC * 100) / 100);
    setTotalBDL(Math.round(totals.sumBDL * 100) / 100);
    setTotalReceived(Math.round(totals.sumReceived * 100) / 100);

    if ([ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus)) {
      setAvailableReferencesToAdd(
        supplierProductsCustom.filter(
          (supplierProduct) =>
            !Object.values(productOrderToDisplay).some((category) =>
              flatten(category.products).some((product) => product.id === supplierProduct.id),
            ),
        ),
      );
    }
  }, [productOrderToDisplay]);

  useEffect(() => {
    setEmptyStateProps(
      getEmptyStateProps(
        suppliers.length === 0,
        !startDate || !endDate,
        isGeneratingData,
        hasNoResult,
      ),
    );
  }, [suppliers, startDate, endDate, isGeneratingData, hasNoResult]);

  useEffect(() => {
    if (!displayCart) {
      return;
    }
    pageLoading();

    let { emailRecipientsToUse, emailRecipientsCopyToUse } = getEmailsToUse();

    setEmailRecipients(emailRecipientsToUse);
    setEmailRecipientsCopy(emailRecipientsCopyToUse);

    (async () => {
      const formattedPurchaseOrderComment = formatPurchaseOrderCommentForPDF(purchaseOrderComment);

      const { formattedOrder, formattedProductOrders } = await utilsLib.sendOrder(
        {
          params,
          source: 'send',
          orderId: isDuplicatingOrder ? null : orderId,
          storeId,
          sending,
          endDate,
          startDate,
          partnerId,
          storeInfos,
          supplierId,
          setOrderId,
          productOrders,
          sendingParams,
          supplierInfos,
          receptionComment,
          orderOpeningTime,
          productsInventory,
          receptionReference,
          clientName,
          purchaseOrderComment: formattedPurchaseOrderComment,
          emailRecipients: emailRecipientsToUse,
          emailRecipientsCopy: emailRecipientsCopyToUse,
        },
        true,
      );

      const html = await services.getPreviewPurchaseOrder(
        formattedOrder,
        formattedProductOrders,
        showErrorMessage,
        userTimezone,
      );

      setHtmlPreview(html);
      pageLoaded();
    })();
  }, [displayCart]);

  useEffect(() => {
    if (!displayDeliveryNotePreview) {
      return;
    }
    pageLoading();

    (async () => {
      const formattedPurchaseOrderComment = formatPurchaseOrderCommentForPDF(purchaseOrderComment);

      const { formattedOrder, formattedProductOrders } = await utilsLib.sendOrder(
        {
          params,
          source: 'send',
          orderId,
          storeId,
          sending,
          endDate,
          startDate,
          partnerId,
          storeInfos,
          supplierId,
          setOrderId,
          productOrders,
          sendingParams,
          supplierInfos,
          receptionComment,
          orderOpeningTime,
          productsInventory,
          receptionReference,
          clientName,
          purchaseOrderComment: formattedPurchaseOrderComment,
          isEditingPreparationOrder,
        },
        true,
      );

      const html = await services.generateDeliveryNotePreview(
        formattedOrder,
        formattedProductOrders,
        showErrorMessage,
        userTimezone,
      );

      setHtmlPreview(html);
      pageLoaded();
    })();
  }, [displayDeliveryNotePreview]);

  // Credit Request
  useEffect(() => {
    const existingCreditRecipients = get(order, 'creditOrderRecipients', null);
    const existingCreditCopy = get(order, 'creditOrderCopy', null);
    const existingComment = get(order, 'creditOrderComment', '');
    const creditOrderPictureUrls = get(order, 'creditOrderPictureUrls', []);

    const defaultRecipients = supplierInfos.email;
    const defaultCopy = get(sendingParams, 'email.credit.default_cc', '');

    const emailRecipientsToUse = existingCreditRecipients || defaultRecipients;
    const emailsCopyToUse = existingCreditCopy || defaultCopy;

    setCreditRequestData({
      ...creditRequestData,
      recipientEmails: emailRecipientsToUse,
      copyEmails: emailsCopyToUse,
      comment: existingComment,
      creditOrderPictureUrls,
    });
  }, [params, sendingParams, supplierInfos]);

  useEffect(() => {
    if (!displayCreditForm) {
      return;
    }
    pageLoading();

    // Load html preview
    (async () => {
      const flattenProductOrders = flattenArray(productsInventory.map(({ products }) => products));

      const formattedProductOrders = await getFormattedProductOrders(flattenProductOrders, orderId);

      const html = await services.getPreviewCreditOrder(
        {
          id: orderId,
          storeId,
          supplierId,
        },
        formattedProductOrders,
        showErrorMessage,
      );

      setHtmlPreview(html);
    })();

    pageLoaded();
  }, [displayCreditForm]);

  useEffect(() => {
    setDisableAddCreditPicture(allCreditPicturesCount >= MAX_CREDIT_ORDER_PICTURE_PER_ORDER);
  }, [allCreditPicturesCount]);

  if (isLoadingHeader) {
    return (
      <Container>
        <DeepsightComponentLoader />
      </Container>
    );
  }

  const getOrderDatesInThePast = () =>
    pastOrders
      .filter(
        ({ storeId: pastOrderStoreId, supplierId: pastOrderSupplierId, startOrderDate }) =>
          pastOrderStoreId === storeId &&
          pastOrderSupplierId === supplierId &&
          moment
            .tz(startOrderDate, storeTimezone)
            .isAfter(
              moment
                .tz(storeTimezone)
                .subtract(ORDER_IS_EDITING_DATE_MAX_DATE_IN_PAST, 'months')
                .startOf('D'),
            ),
      )
      .map(({ startOrderDate }) =>
        moment.tz(startOrderDate, storeTimezone).startOf('day').format(),
      );

  const getSentOrderDatesFromLastMonth = () =>
    pastOrders
      .filter(
        ({ storeId: pastOrderStoreId, supplierId: pastOrderSupplierId, status, startOrderDate }) =>
          pastOrderStoreId === storeId &&
          pastOrderSupplierId === supplierId &&
          parseInt(status, 10) >= ORDER_STATUS.SENT_EDITABLE &&
          moment
            .tz(startOrderDate, storeTimezone)
            .isAfter(moment.tz(storeTimezone).subtract(1, 'months').startOf('D')),
      )
      .map(({ startOrderDate }) =>
        moment.tz(startOrderDate, storeTimezone).startOf('day').format(),
      );

  const reference = utilsLib.getOrderReference(
    get(order, 'reference'),
    partnerId,
    orderOpeningTime,
  );

  const pictureConstraint = constraints ? constraints.includes('receptionPicture') : false;

  const handleSetProductsOrder = (categoryName, values) => {
    setProductOrderToDisplay({
      ...productOrderToDisplay,
      [categoryName]: { products: values, isOpen: productOrderToDisplay[categoryName].isOpen },
    });
  };

  const addNewProductOrder = (newReference) => {
    const {
      supplierProduct,
      receptionAnomaly,
      priceData: { price, hasOverwrittenOrderedProductPrice },
      packagingData: { received, invoiced, packagingUsedInInvoice, packagingUsedInReception },
    } = newReference;

    // priceData.price is expressed in invoiced packaging, need to convert to reception packaging
    const convertedPrice = convertPriceToPackagingById(
      price,
      packagingUsedInInvoice.id,
      packagingUsedInReception.id,
      supplierProduct.packagings,
    );

    const commonData = {
      orderId,
      price: convertedPrice,
      supplierProductId: supplierProduct.id,
      receptionAnomaly: receptionAnomaly && receptionAnomaly.id,
      supplierProductPackagingId: packagingUsedInReception.id,
      lnkSupplierproductProductorderrel: {
        ...supplierProduct,
        hasOverwrittenOrderedProductPrice: hasOverwrittenOrderedProductPrice,
      },
      checked: true,
    };

    const receivedNewSupplierProduct = {
      ...commonData,
      type: PRODUCT_ORDER_TYPE.RECEIVED,
      quantity: received,
    };

    const invoicedNewSupplierProduct = {
      ...commonData,
      type: PRODUCT_ORDER_TYPE.INVOICED,
      quantity: invoiced,
    };

    const getReceivedDiff = isNaN(invoicedNewSupplierProduct.quantity)
      ? 0
      : receivedNewSupplierProduct.quantity * Number.parseFloat(commonData.price) -
        invoicedNewSupplierProduct.quantity * Number.parseFloat(commonData.price);

    handleReceivedDiff(getReceivedDiff, supplierProduct.id);

    const productOrderOfCategoriesKeyById = Object.values(categorizedProducts).reduce(
      (result, productsGroupedByEntityId) => {
        productsGroupedByEntityId.products.forEach((productsOfEntity) => {
          productsOfEntity.forEach((product) => {
            result[product.id] = product;
          });
        });

        return result;
      },
      {},
    );

    setIsGeneratingData(true);

    setAddingNewReference(true);

    const updatedProductOrders = productOrders.map((productOrder) => {
      const matchingProductOrder = productOrderOfCategoriesKeyById[productOrder.supplierProductId];

      if (productOrder.supplierProductId && matchingProductOrder) {
        return {
          ...productOrder,
          priceBDL: matchingProductOrder.priceBDL,
          anomalie: matchingProductOrder.anomalie,
          receptionAnomaly: matchingProductOrder.receptionAnomaly,
          quantity: matchingProductOrder[productOrder.type],
          checked: matchingProductOrder.checked,
        };
      }

      return productOrder;
    });

    setProductOrders([
      ...updatedProductOrders,
      receivedNewSupplierProduct,
      invoicedNewSupplierProduct,
    ]);

    if (orderStatus === ORDER_STATUS.SENT) {
      const updatedSupplierProductsCustom = supplierProductsCustom.map((supplierProduct) => {
        const matchingProductOrder = productOrderOfCategoriesKeyById[supplierProduct.id];

        if (supplierProduct.id && matchingProductOrder) {
          return {
            ...supplierProduct,
            invoiced: matchingProductOrder.invoiced,
            received: matchingProductOrder.received,
            priceBDL: matchingProductOrder.priceBDL,
            anomalie: matchingProductOrder.anomalie,
            receptionAnomaly: matchingProductOrder.receptionAnomaly,
          };
        }

        return supplierProduct;
      });

      setSupplierProductsCustom(updatedSupplierProductsCustom);
    }

    setTimeout(() => {
      setAddingNewReference(false);
    }, 500);
  };

  const deleteProductOrder = (supplierProductId) => {
    const updatedSupplierProducts = supplierProducts.filter(
      (supplierProduct) => supplierProduct.id !== supplierProductId,
    );

    const productOrderOfCategoriesKeyById = Object.values(categorizedProducts).reduce(
      (result, productsGroupedByEntityId) => {
        productsGroupedByEntityId.products.forEach((productsOfEntity) => {
          productsOfEntity.forEach((product) => {
            result[product.id] = product;
          });
        });

        return result;
      },
      {},
    );

    const updatedProductOrders = productOrders.map((productOrder) => {
      const matchingProductOrder = productOrderOfCategoriesKeyById[productOrder.supplierProductId];

      if (productOrder.supplierProductId && matchingProductOrder) {
        return {
          ...productOrder,
          anomalie: matchingProductOrder.anomalie,
          receptionAnomaly: matchingProductOrder.receptionAnomaly,
          quantity: matchingProductOrder[productOrder.type],
        };
      }

      return productOrder;
    });

    const filteredProductOrders = updatedProductOrders.filter(
      (productOrder) => productOrder.supplierProductId !== supplierProductId,
    );

    setIsGeneratingData(true);

    setAddingNewReference(true);

    setSupplierProducts(updatedSupplierProducts);
    setProductOrders(filteredProductOrders);

    setAddingNewReference(false);
    handleReceivedDiff(0, supplierProductId);
  };

  const handleHasNoResultFound = () => {
    const allProductHidden = Object.keys(productOrderToDisplay).reduce((acc, categoryName) => {
      const hasHiddenProducts = productOrderToDisplay[categoryName].products.reduce(
        (productAcc, products) => {
          productAcc.push(products.every((product) => product.isHidden));

          return productAcc;
        },
        [],
      );

      acc.push(hasHiddenProducts.every((hidden) => !!hidden));
      return acc;
    }, []);

    return setHasNoResult(allProductHidden.every((hidden) => !!hidden));
  };

  const handleSearchInput = (searchValue) => {
    if (searchInput === searchValue) {
      return;
    }

    let filterRegex = '';
    if (searchValue.length) {
      filterRegex = new RegExp(escapeSpecialCharacters(normalizeStringValue(searchValue)), 'ig');

      const saveOpenCategory = Object.keys(productOrderToDisplay).reduce((acc, category) => {
        acc[category] = productOrderToDisplay[category].isOpen;

        return acc;
      }, {});
      setSavedOpenedCategory(saveOpenCategory);
    }

    let productOrderToDisplayCount = 0;

    Object.keys(productOrderToDisplay).forEach((category) => {
      productOrderToDisplay[category].products.forEach((productArray) => {
        productArray.forEach((product) => {
          const skuMatch = product.sku && product.sku.match(filterRegex);
          product.isHidden = !normalizeStringValue(product.name).match(filterRegex) && !skuMatch;

          productOrderToDisplayCount += !product.isHidden;
        });
      });
    });

    setSearchInput(searchValue);
    handleHasNoResultFound();
    openCloseAllCategory(searchValue, productOrderToDisplayCount);
  };

  const isCategoryHidden = (productCategory) => {
    const globalHidden = productCategory.reduce((acc, products) => {
      acc.push(products.every((product) => product.isHidden));

      return acc;
    }, []);

    return globalHidden.every((hidden) => !!hidden);
  };

  const getFirstDisplayedCategory = () => {
    for (const category of Object.keys(productOrderToDisplay)) {
      if (!isCategoryHidden(productOrderToDisplay[category].products)) {
        return category;
      }
    }
  };

  const sumUpRecommendations = (product) => {
    const convertedPackagingValue = getConvertedPackagingValue(product);

    return Math.ceil(
      product.entityStockEvent.recommandation / (1 - product.loss / 100) / convertedPackagingValue,
    );
  };

  const openCloseAllCategory = (searchValue, productOrderToDisplayCount) => {
    const duplicatedProductOrders = cloneDeep(productOrderToDisplay);

    const firstDisplayedCategory = getFirstDisplayedCategory();

    for (const category of Object.keys(duplicatedProductOrders)) {
      if (searchValue.length) {
        if (productOrderToDisplayCount < PRODUCT_ORDER_MAX_HANDLER) {
          duplicatedProductOrders[category].isOpen = true;
          continue;
        }

        duplicatedProductOrders[category].isOpen =
          category === firstDisplayedCategory ? true : false;
        continue;
      }

      if (productOrderToDisplayCount < PRODUCT_ORDER_MAX_HANDLER) {
        duplicatedProductOrders[category].isOpen = savedOpenedCategory[category];
        continue;
      }

      duplicatedProductOrders[category].isOpen = false;
    }

    setProductOrderToDisplay(duplicatedProductOrders);
  };

  const handleOpenCategory = (categoryName) => {
    if (countProductOrder < PRODUCT_ORDER_MAX_HANDLER) {
      setProductOrderToDisplay({
        ...productOrderToDisplay,
        [categoryName]: {
          products: productOrderToDisplay[categoryName].products,
          isOpen: !productOrderToDisplay[categoryName].isOpen,
        },
      });

      return;
    }

    const isOpen = productOrderToDisplay[categoryName].isOpen;
    const cloneProductOrder = cloneDeep(productOrderToDisplay);

    Object.keys(cloneProductOrder).forEach((category) => {
      if (!isOpen) {
        cloneProductOrder[category].isOpen = false;
      }

      if (category === categoryName) {
        if (!isOpen) {
          cloneProductOrder[categoryName].isOpen = true;
        }

        if (!!isOpen) {
          cloneProductOrder[categoryName].isOpen = false;
        }
      }
    });

    setProductOrderToDisplay(cloneProductOrder);
  };

  const userHasRightToUpdate = () => {
    if (orderStatus === ORDER_STATUS.CREATION) {
      return !canCreateDraftOrder(authorizedActions);
    }

    if (orderStatus === ORDER_STATUS.DRAFT) {
      return !canEditDraftOrder(authorizedActions);
    }
  };

  const handleEmails = (emails, setEmails) => {
    const split = emails.split(',');

    if (head(split) === '') {
      // split returns an array with an empty string on an empty string
      setEmails([]);
      return;
    }

    setEmails(split);
  };

  const shouldDisplayOrderFormHeader = () =>
    !displayCart &&
    !displayDeliveryNotePreview &&
    !displayCreditForm &&
    startDate &&
    endDate &&
    confirmedSupplierId &&
    confirmedStoreId;

  const shouldDisplayProductList = () =>
    !displayCart &&
    !displayDeliveryNotePreview &&
    !displayCreditForm &&
    !isGeneratingData &&
    !!categories.length &&
    !isEmpty(productOrderToDisplay);

  const matchingSupplier = suppliers.find((supplier) => supplier.id === supplierId);

  return (
    <Container>
      {modalType && (
        <ModalManager
          closeErrorModal={handleCloseErrorModal}
          endDate={endDate}
          maxOrderDate={maxOrderDate}
          modalType={modalType}
          orderTimeLimit={orderTimeLimit}
          setModalType={setModalType}
          timezone={storeTimezone}
          verifiedClose={handleCloseOrderForm}
        />
      )}
      {displayOrderWithMinConfirmationModal && (
        <RenderValidateModal
          closeModal={() => handleOrderWithConfirmationCloseModal()}
          handleConfirm={() => {
            setDisplayOrderWithMinConfirmationModal(false);
            handleSendConfirmationModal();
          }}
          text={i18next.t('ORDERS.ORDERS.MODAL_CAN_ORDER_WITH_MIN_TEXT')}
          title={i18next.t('ORDERS.ORDERS.MODAL_CAN_ORDER_WITH_MIN_TITLE')}
        />
      )}
      {displaySendToPartnerConfirmationModal && (
        <RenderSendToPartnerConfirmationModal
          closeSendToPartnerConfirmationModal={() => handleCloseSendToPartnerConfirmationModal()}
          sendOrderToPartner={() => {
            setDisplaySendToPartnerConfirmationModal(false);
            handleSave('send');
          }}
        />
      )}
      {displaySendingError && (
        <RenderSendingErrorModal
          closeSendingErrorModal={() => setDisplaySendingError(false)}
          displaySendingError={displaySendingError}
        />
      )}
      {displayConfirmSupplierProductToDelete && (
        <RenderValidateModal
          closeModal={() => setDisplayConfirmSupplierProductToDelete(false)}
          handleConfirm={() => {
            const { supplierProductIdToDelete, callback } = displayConfirmSupplierProductToDelete;

            deleteProductOrder(supplierProductIdToDelete);

            if (callback) {
              callback();
            }

            setDisplayConfirmSupplierProductToDelete(false);
          }}
          text={i18next.t('ORDERS.ORDERS.FORM_DELETE_REFERENCE_CONTENT')}
          title={`${i18next.t('ORDERS.ORDERS.FORM_DELETE_REFERENCE_TITLE')} ?`}
        />
      )}
      <Header
        creditRequestData={creditRequestData}
        deliveryDays={deliveryDays}
        deliveryLeadTimes={deliveryLeadTimes}
        disableSendButton={disableSendButton || isEditingDeliveryDate}
        displayCart={displayCart}
        displayCreditForm={displayCreditForm}
        displayDeliveryNotePreview={displayDeliveryNotePreview}
        endDate={orderStatus === ORDER_STATUS.CREATION ? selectedEndDate : endDate}
        firstPossibleDeliveryDatesByDeliveryDay={firstPossibleDeliveryDatesByDeliveryDay}
        handleClickClose={handleClickClose}
        handlePreparationSave={handlePreparationSave}
        handleReceptionComment={handleReceptionComment}
        handleReceptionReference={handleReceptionReference}
        handleSave={handleSave}
        handleSaveDelivery={handleSaveDelivery}
        handleSaveDeliveryConfirmationModal={handleSaveDeliveryConfirmationModal}
        handleSelectDates={handleSelectDates}
        handleSelectStore={handleSelectStore}
        handleSelectSupplier={handleSelectSupplier}
        hasRoleAdminOrInpulse={hasRoleAdminOrInpulse}
        infos={{
          storeInfos,
          orderStatus,
          supplierInfos,
          receptionComment,
          receptionReference,
          orderId: reference,
          deliveryDate: startDate,
          user: params.user,
          orderDate:
            orderStatus !== ORDER_STATUS.CREATION
              ? moment.tz(order.orderDate, userTimezone)
              : moment.tz(userTimezone),
        }}
        isConfirmable={isConfirmable}
        isDuplicatingOrder={isDuplicatingOrder}
        isEditingDeliveryDate={isEditingDeliveryDate}
        isEditingPreparationOrder={isEditingPreparationOrder}
        isGeneratingData={isGeneratingData}
        labelTopButton={params.labelTopButton}
        limitOrderTime={maxOrderDate}
        nbAnomalies={nbAnomalies}
        nbReferencesOrdered={nbReferencesOrdered}
        order={order}
        orderDatesInPast={getOrderDatesInThePast()}
        orderedUnit={orderedUnit}
        orderOpeningTime={orderOpeningTime}
        orderStatus={orderStatus}
        orderTimeLimit={orderTimeLimit}
        params={params}
        partnerId={partnerId}
        pictureConstraint={pictureConstraint}
        preparationStatus={preparationStatus}
        receptionDate={receptionDate}
        sending={sending}
        sendingParams={sendingParams}
        setDisplayCart={setDisplayCart}
        setDisplayCreditForm={setDisplayCreditForm}
        setDisplayDeliveryNotePreview={setDisplayDeliveryNotePreview}
        startDate={orderStatus === ORDER_STATUS.CREATION ? selectedStartDate : startDate}
        storeName={currentStoreName}
        stores={stores}
        supplierName={supplierName}
        suppliers={suppliers}
        supplierSelected={suppliers.find((mapping) => mapping.id === supplierId)}
        timezone={storeTimezone}
      />
      <Content>
        {shouldDisplayOrderFormHeader() && (
          <OrderFormInfoPanel
            addNewReference={addNewProductOrder}
            associatedInvoiceControlListId={get(order, 'invoiceControlListId')}
            availableReferencesToAdd={availableReferencesToAdd}
            categorizedProducts={categorizedProducts}
            credit={get(order, 'credit')}
            currency={supplierInfos.currency || defaultCurrency}
            customerCode={supplierInfos.customerCode}
            deliveryNote={get(order, 'deliveryNote')}
            diffBetweenOrderedAndInvoiced={diffBetweenOrderedAndInvoiced}
            diffBetweenReceivedAndOrdered={diffBetweenReceivedAndOrdered}
            handleReceptionComment={handleReceptionComment}
            handleReceptionReference={handleReceptionReference}
            handleSearchInput={handleSearchInput}
            invoicedDiffOfProductOrder={invoicedDiffOfProductOrder}
            isEditable={isEditable}
            isEditingPreparationOrder={preparationStatus != null && isCentralMode}
            isGeneratingData={isGeneratingData}
            orderConfirmationMessageReducer={props.orderConfirmationMessageReducer}
            orderDate={
              orderStatus !== ORDER_STATUS.CREATION
                ? moment.tz(order.orderDate, userTimezone)
                : moment.tz(userTimezone)
            }
            orderedUnit={orderedUnit}
            orderId={reference}
            orderStatus={orderStatus}
            orderTimeLimit={maxOrderDate}
            orderTimestamp={get(
              order,
              'timestamp',
              moment.tz(userTimezone).format('DD/MM/YYYY HH:mm:ss'),
            )}
            orderTotal={orderTotal}
            params={params}
            partnerId={partnerId}
            picturesUrls={picturesUrls}
            prefillInpulse={prefillInpulse}
            preparationInvoice={get(
              order,
              'lnkOrderInvoiceInformationOrderrel.preparationInvoice',
              null,
            )}
            preparationStatus={preparationStatus}
            productsInventory={productsInventory}
            purchaseOrder={get(order, 'purchaseOrder')}
            receivedDiffOfProductOrder={receivedDiffOfProductOrder}
            receptionComment={receptionComment}
            receptionReference={receptionReference}
            searchInput={searchInput}
            selectedStore={selectedStore}
            sentOrderDatesFromThisMonth={getSentOrderDatesFromLastMonth()}
            setIsEditingDeliveryDate={setIsEditingDeliveryDate}
            setOpenStockInfo={setOpenStockInfo}
            setPicturesUrls={setPicturesUrls}
            setPrefillInpulse={setPrefillInpulse}
            startDate={orderStatus === ORDER_STATUS.CREATION ? selectedStartDate : startDate}
            storeId={storeId}
            storeName={currentStoreName}
            supplierId={supplierId}
            supplierInfos={supplierInfos}
            supplierName={supplierName}
            suppliers={suppliers}
            timezone={storeTimezone}
            totalBDL={totalBDL}
            totalReceived={totalReceived}
          />
        )}
        {emptyStateProps && (
          <DisplayEmptyState>
            <EmptyState icon={emptyStateProps.icon} label={emptyStateProps.label} />
          </DisplayEmptyState>
        )}
        {isGeneratingData && <LoadingState orderStatus={orderStatus} />}
        {displayDeliveryNotePreview && (
          <DeliveryNotePreview
            handlePreparationValidation={handlePreparationValidation}
            hasGenerateInvoiceProps={hasGenerateInvoiceProps}
            htmlPreview={htmlPreview}
            receptionComment={receptionComment}
            setReceptionComment={setReceptionComment}
            storeId={storeId}
            supplierId={supplierId}
          />
        )}
        {displayCart && (
          <OrderCart
            authorizedActions={authorizedActions}
            emailRecipients={emailRecipients}
            emailRecipientsCopy={emailRecipientsCopy}
            handleEmails={handleEmails}
            handleSendConfirmationModal={handleSendConfirmationModal}
            htmlPreview={htmlPreview}
            matchingSupplier={matchingSupplier}
            purchaseOrderComment={purchaseOrderComment}
            setEmailRecipients={setEmailRecipients}
            setEmailRecipientsCopy={setEmailRecipientsCopy}
            setPurchaseOrderComment={setPurchaseOrderComment}
            supplierHasEDI={supplierHasEDI}
            userHasRightToUpdate={userHasRightToUpdate}
          />
        )}
        {displayCreditForm && (
          <OrderCreditForm
            creditRequestData={creditRequestData}
            disableAddCreditPicture={disableAddCreditPicture}
            handleDeletePOCreditPicture={handleDeletePOCreditPicture}
            handleSaveDelivery={handleSaveDelivery}
            htmlPreview={htmlPreview}
            params={params}
            productsInventory={productsInventory}
            sendingParams={sendingParams}
            setAllCreditPicturesCount={setAllCreditPicturesCount}
            setCreditRequestData={setCreditRequestData}
            supplierInfos={supplierInfos}
          />
        )}
        {shouldDisplayProductList() &&
          Object.keys(productOrderToDisplay).map((category, index) => {
            const foundCategory = categories.find(
              (cat) => cat.name.toLowerCase() === category.toLowerCase(),
            );

            if (isCategoryHidden(productOrderToDisplay[category].products)) {
              return;
            }

            if (!foundCategory) {
              return;
            }

            return (
              <OrderFormCategory
                allCreditPicturesHandlers={{
                  allCreditPicturesCount,
                  setAllCreditPicturesCount,
                  disableAddCreditPicture,
                }}
                canStillOrder={canStillOrder}
                category={foundCategory}
                creditRequestData={creditRequestData}
                currency={supplierInfos.currency || defaultCurrency}
                deleteProductOrder={deleteProductOrder}
                endDate={endDate}
                handleInvoicedDiff={handleInvoicedDiff}
                handleOpenCategory={handleOpenCategory}
                handleReceivedDiff={handleReceivedDiff}
                handleSetProductsOrder={handleSetProductsOrder}
                handleUpdateCategory={handleUpdateCategory}
                indexCategory={index}
                invoicedDiffOfProductOrder={invoicedDiffOfProductOrder}
                isCategoryOpen={productOrderToDisplay[category].isOpen}
                isEditable={isEditable}
                isEditingPreparationOrder={preparationStatus != null && isCentralMode}
                key={`order-form-category-${category.name}-${index}`}
                openStockInfo={openStockInfo}
                orderStatus={orderStatus}
                prefillInpulse={prefillInpulse}
                preparationStatus={preparationStatus}
                productOrders={productOrderToDisplay[category].products}
                receivedDiffOfProductOrder={receivedDiffOfProductOrder}
                selectedStore={selectedStore}
                setOpenStockInfo={setOpenStockInfo}
                startDate={startDate}
                storeId={storeId}
                storeInfos={storeInfos}
                storeTimezone={storeTimezone}
                sumUpRecommendations={sumUpRecommendations}
                supplierInfos={supplierInfos}
                user={user}
              />
            );
          })}
      </Content>
    </Container>
  );
}

OrderForm.propTypes = {};

OrderForm.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  defaultCurrency: state.baseReducer.currency,
  orderConfirmationMessageReducer: state.orderConfirmationMessageReducer,
  client: getClientInfo(state.baseReducer.user),
  shouldReloadApp: state.baseReducer.shouldReloadApp,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
  resetStockEveryDayForCentralKitchen: getResetStockEveryDayForCentralKitchen(
    state.baseReducer.userRights,
  ),
  hasGenerateInvoiceProps: getGenerateInvoice(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, dismissTime) => {
    dispatch(showConfirmationMessage(message, type, null, dismissTime));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
