import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { METRIC_KEY } from '@commons/constants/metricKey';
import DisplayNumber, { formatNumber, formatNumberWithCurrency } from '@commons/DisplayNumber';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getEntityUnit } from '@stocks/utils';
import { TextWithTooltipButtonContainer } from './styledComponents';
import AnalyticsList from '@commons/AnalyticsList';
import TooltipButton from '@commons/TooltipButton';

const formatValueString = (value, metricKey, currency = {}, unit = '') => {
  if (value == null) {
    return '--';
  }

  if (metricKey === METRIC_KEY.TURNOVER) {
    return `${formatNumberWithCurrency({
      displayCurrencyCode: true,
      withoutDecimals: true,
      shortenDigits: true,
      number: value,
      currency,
    })}`;
  }

  return `${formatNumber(value, 2)} ${getEntityUnit(unit)}`;
};

const getFormattedCurrency = (metricKey, unit) =>
  metricKey === METRIC_KEY.TURNOVER ? null : { alphabeticCode: getEntityUnit(unit) };

export const formatDataForTooltip = (data, metricKey, currency, startDate, endDate) => {
  const unit = data.unit || '';

  return [
    {
      categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_ORDERED'),
      categoryValue: formatValueString(get(data, 'foodCost.order', 0), metricKey, currency, unit),
      children: [
        {
          name: i18next.t('GENERAL.SUPPLIER_PLURAL'),
          value: formatValueString(get(data, 'foodCost.order', 0), metricKey, currency, unit),
        },
      ],
    },
    {
      categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_VARIATION_STOCK'),
      categoryValue: formatValueString(
        get(data, 'foodCost.totalStockVariation', 0),
        metricKey,
        currency,
        unit,
      ),
      children: [
        {
          name: i18next.t('GENERAL.INGREDIENT_PLURAL'),
          value: formatValueString(
            get(data, 'foodCost.ingredientsStockVariation.variation', 0),
            metricKey,
            currency,
            unit,
          ),
          children: [
            {
              name: startDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
              value: formatValueString(
                get(data, 'foodCost.ingredientsStockVariation.initial', 0),
                metricKey,
                currency,
                unit,
              ),
            },
            {
              name: endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
              value: formatValueString(
                get(data, 'foodCost.ingredientsStockVariation.final', 0),
                metricKey,
                currency,
                unit,
              ),
            },
          ],
        },
        {
          name: i18next.t('GENERAL.RECIPE_PLURAL'),
          value: formatValueString(
            get(data, 'foodCost.recipesStockVariation.variation', 0),
            metricKey,
            currency,
            unit,
          ),
          children: [
            {
              name: startDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
              value: formatValueString(
                get(data, 'foodCost.recipesStockVariation.initial', 0),
                metricKey,
                currency,
                unit,
              ),
            },
            {
              name: endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
              value: formatValueString(
                get(data, 'foodCost.recipesStockVariation.final', 0),
                metricKey,
                currency,
                unit,
              ),
            },
          ],
        },
      ],
    },
    {
      categoryName: i18next.t('GENERAL.TOTAL'),
      categoryValue: formatValueString(
        get(data, 'foodCost.realConsumption', 0),
        metricKey,
        currency,
        unit,
      ),
    },
  ];
};

export const getFoodCostColumns = (
  currency = {},
  startDate,
  endDate,
  metricKey,
  isInCentralMode = false,
) => {
  const headers = [
    {
      id: 0,
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      bold: true,
      textDecorationChild: 'underline',
      isSortable: true,
      large: true,
    },
    {
      id: 1,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
      propertyKey: 'realOverTurnover',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_ACTUAL_FC'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      unit: '%',
      render: (item) => {
        if (item == null) {
          return <>-</>;
        }

        return (
          <>
            <DisplayNumber number={item} /> %
          </>
        );
      },
    },
    {
      id: 2,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
      propertyKey: 'foodCost.realConsumption',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_BY_CATEGORY_ACTUAL_FC'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const dataForTooltip = formatDataForTooltip(row, metricKey, currency, startDate, endDate);

        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <TextWithTooltipButtonContainer>
            <DisplayNumber
              currency={formattedCurrency}
              number={item}
              displayCurrencyCode
              withoutDecimals
            />
            <TooltipButton
              tooltipContent={<AnalyticsList data={dataForTooltip} title={row.name} />}
            />
          </TextWithTooltipButtonContainer>
        );
      },
    },
    {
      id: 3,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_THEORETICAL_FC'),
      propertyKey: 'theoreticalConsumption',
      tooltipDisplay: i18next.t(
        isInCentralMode
          ? 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_THEORETICAL_CENTRAL_KITCHEN_FC'
          : 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_THEORETICAL_FC',
      ),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 4,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_FC_DEVIATION'),
      propertyKey: 'foodCost.gapFromTheoretical',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_FC_DEVIATION'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 5,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_FC_DEVIATION'),
      propertyKey: 'relativeRealFromTheorical',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_FC_DEVIATION'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item) => {
        if (item == null) {
          return <>-</>;
        }

        return (
          <>
            <DisplayNumber number={item} /> %
          </>
        );
      },
    },
    {
      id: 6,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_WASTES'),
      propertyKey: 'loss',
      tooltipDisplay: i18next.t(
        isInCentralMode
          ? 'ANALYSIS.FOOD_COST.CENTRAL_KITCHEN_COLUMN_TOOLTIP_WASTES'
          : 'ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_WASTES',
      ),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },
    {
      id: 7,
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_INVENTORY_SHRINKAGE'),
      propertyKey: 'inventoryShrinkage',
      tooltipDisplay: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_INVENTORY_SHRINKAGE'),
      isSortable: true,
      isNumber: true,
      hideRow: metricKey !== METRIC_KEY.TURNOVER,
      filterType: 'numeric',
      render: (item, row) => {
        const formattedCurrency = getFormattedCurrency(metricKey, row.unit);

        return (
          <DisplayNumber
            currency={formattedCurrency}
            number={item}
            displayCurrencyCode
            withoutDecimals
          />
        );
      },
    },

    {
      name: '',
      propertyKey: 'action',
      isClickable: true,
      isSortable: false,
    },
  ];

  return headers;
};
