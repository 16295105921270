import { orderBy } from 'lodash';
import i18next from 'i18next';

import { user as userService } from '../../../services/user';

/**
 * Handle the fetch of the stores associated to the current user
 *
 * @param {Function} showMessage      - The method to display message relating to the request status
 * @param {Boolean} activeStoresOnly  - (optional) Whether we only want to retrieve active store
 *
 * @returns {Promise<Store[]>} The list of stores on which the current user belongs
 */
export async function fetchStoresUser(showMessage, activeStoresOnly) {
  try {
    const accountId = localStorage.getItem('userId');

    const stores = await userService.getStoresOfAccount(accountId);

    const sortedStores = orderBy(stores, 'name', 'asc');

    if (activeStoresOnly) {
      return sortedStores.filter(({ active }) => active);
    }

    return sortedStores;
  } catch (err) {
    if (showMessage) {
      showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_STORES'), 'error');
    }

    return [];
  }
}

export default {
  fetchStoresUser,
};
