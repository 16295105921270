import i18next from 'i18next';

export const INVOICE_CONTROL_STATUS_TRANSLATED = {
  // Supplier Product
  unknown_reference: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_UNKNOWN_REFERENCE'),
  no_corresponding_order: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_CORRESPONDING_ORDER'),
  additional_reference: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_ADDITIONAL_REFERENCE'),
  no_corresponding_invoice_control: i18next.t(
    'INVOICE.INVOICE_CONTROLS.STATUS_NO_CORRESPONDING_INVOICE_CONTROL',
  ),

  // Price and Quantity
  no_sp_price: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_SP_PRICE'),
  no_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_GAP'),
  price_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_PRICE_GAP'),
  price_and_quantity_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_PRICE_AND_QTY_GAP'),
  quantity_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_QTY_GAP'),

  // Packaging
  wrong_packaging: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_WRONG_PACKAGING'),
  missing_packaging: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_MISSING_PACKAGING'),
};

export const INVOICE_STATUS = {
  DETECTING_STORE: 'detecting_store',
  FAILURE: 'failure',
  PENDING: 'pending',
  SUCCESS: 'success',
  UNCONTROLLED: 'uncontrolled',
};

export const INVOICE_STATUS_LABEL_CONFIG = {
  [INVOICE_STATUS.UNCONTROLLED]: {
    color: 'grey',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_UNCONTROLLED'),
  },
  [INVOICE_STATUS.PENDING]: {
    color: 'orange',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_PENDING'),
  },
  [INVOICE_STATUS.SUCCESS]: {
    color: 'green',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_SUCCESS'),
  },
  [INVOICE_STATUS.FAILURE]: {
    color: 'red',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_FAILURE'),
  },
};
