import { chain, get, isEmpty, omit, sortBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  DEFAULT_LANGUAGE_CODE,
  LISTVIEW_FULL_SCREEN_MODAL_NO_TOP_PADDING,
} from '@commons/constants/listViewProps';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import utilsXLS from '@commons/utils/makeXLS';

// SELECTORS
import { canEditSupplierProductFromDetailView } from '@selectors/actions/supplierProductActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

// SERVICES
import { storeSupplierProductMapping } from '@services/storeSupplierProductMapping';
import storeService from '@services/store';

/// UTILS
import {
  CHOICES_LOSS_AVAILABILITY,
  CHOICES_ORDER_AVAILABILITY,
  CHOICES_STOCK_AVAILABILITY,
} from '@admin/utils/DropdownItems';
import { getNextAvailabilitiesModalWithoutWarningStep } from '@admin/suppliers/supplierProducts/utils/getNextAvailabilitiesModalConfig';
import { getSspmAvailabilitiesModalConfig } from './utils/availabilitiesModal';
import { getStoreMappingModalConfig } from './utils/getStoreMappingModalConfig';
import actionsUtils from './utils/actions';
import columnsUtils from './utils/colums/index';
import formatUtils from './utils/format';

// COMPONENTS
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/supplierProducts/components/EmptyState';

import { Container } from './styledComponents';

const SupplierProductMappings = ({
  mappingsToCreate = [],
  setMappingsToCreate,
  supplierProduct: {
    id: supplierProductId,
    supplierId,
    price: supplierProductPrice,
    active: supplierProductActive,
  },
  isCreation,
  // Redux state dispatched to props
  user,
  client: { clientId, storeName },
  authorizedActions,
  // Methods dispatched to props
  showSuccessMessage,
  showErrorMessage,
  openGenericModal,
  refreshGenericModal,
  closeGenericModal,
  pageLoaded,
  pageLoading,
}) => {
  // States ListView
  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userLanguageCode, setUserLanguageCode] = useState(DEFAULT_LANGUAGE_CODE);

  // States filters
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [availableStores, setAvailableStores] = useState([]);
  const [selectedAvailableStores, setSelectedAvailableStores] = useState([]);
  const [supplierProfiles, setSupplierProfiles] = useState([]);
  const [selectedSupplierProfiles, setSelectedSupplierProfiles] = useState([]);
  const [selectedOrderAvailability, setSelectedOrderAvailability] = useState(
    CHOICES_ORDER_AVAILABILITY(),
  );
  const [selectedStockAvailability, setSelectedStockAvailability] = useState(
    CHOICES_STOCK_AVAILABILITY(),
  );
  const [selectedLossAvailability, setSelectedLossAvailability] = useState(
    CHOICES_LOSS_AVAILABILITY(),
  );

  const [modalAvailabilities, setModalAvailabilities] = useState({
    available: true,
    hasStock: true,
    hasDlc: true,
  });

  const [mappings, setMappings] = useState([]);
  const [filteredMappings, setFilteredMappings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [shouldDisableNewAssociation, setShouldDisableNewAssociation] = useState(false);

  const [nextAvailabilitiesData, setNextAvailabilitiesData] = useState({
    available: true,
    startDate: '',
  });

  const formattedPluralStoreName = getClientStoreNameTranslation(storeName, true).toLowerCase();

  useEffect(() => {
    if (!isCreation) {
      return;
    }

    (async () => {
      pageLoading();

      const stores = await storeService.getStoresByAccountIdAndSupplierId(supplierId, {
        withGroups: true,
      });

      if (!supplierProductId) {
        setAvailableStores([]);

        return;
      }

      const result =
        await storeSupplierProductMapping.getStoreSupplierProductMappingsOfAccountStores(
          user.id,
          supplierProductId,
        );

      const formattedMappings = mappingsToCreate
        ? formatMappingsToCreate(mappingsToCreate, stores)
        : formatUtils.computeSupplierProductMappings(result, stores);

      const storeIdsOfMappings = formattedMappings.map(({ storeId }) => storeId);

      const activeStoresWithoutMappings = stores.filter(
        ({ id, active, supplierProfile }) =>
          active && !isEmpty(supplierProfile) && !storeIdsOfMappings.includes(id),
      );
      setAvailableStores(activeStoresWithoutMappings);
      setShouldDisableNewAssociation(!activeStoresWithoutMappings.length && supplierProductActive);

      setFilteredMappings(formattedMappings);

      if (setMappingsToCreate && mappingsToCreate === null) {
        setMappingsToCreate(formattedMappings);
      }

      pageLoaded();
    })();
  }, [isCreation]);

  useEffect(() => {
    if (!isCreation) {
      return;
    }

    setColumns(
      columnsUtils.getColumns({
        onChange: handleSSPMChange,
        isCreation,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
      }),
    );

    setRowActions(
      actionsUtils.getRowActions({
        handleRemoveAssociation,
        openNextAvailabilitiesSchedulingModal,
        handleDeleteScheduledAvailabilitiesModal,
        isCreation,
      }),
    );

    if (mappingsToCreate === null) {
      return;
    }

    (async () => {
      await reloadSupplierProductMappings();
    })();
  }, [mappingsToCreate]);

  // First render (not exactly) to get user language code in state once and for all
  useEffect(() => {
    if (!clientId) {
      return;
    }

    setUserLanguageCode(get(user, 'lnkLanguageAccountrel.code'));
  }, [clientId]);

  // First render (not exactly) to load data and setup ListView
  useEffect(() => {
    setColumns(
      columnsUtils.getColumns({
        onChange: handleSSPMChange,
        isCreation,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
      }),
    );

    setRowActions(
      actionsUtils.getRowActions({
        handleRemoveAssociation,
        openNextAvailabilitiesSchedulingModal,
        handleDeleteScheduledAvailabilitiesModal,
        isCreation,
        authorizedActions,
      }),
    );

    if (isCreation && mappingsToCreate === null) {
      return;
    }

    if (!supplierProductId) {
      return;
    }

    (async () => {
      await reloadSupplierProductMappings();
    })();
  }, [supplierProductId]);

  // Trigger refresh of actions when user has change list selection
  useEffect(() => {
    setActions(
      actionsUtils.getGlobalActions({
        selectedItems,
        availableStores,
        isLoading,
        shouldDisableNewAssociation,
        handleExport,
        handleRemoveAssociation,
        handleEditionAssociation,
        handleCreateNewAssociation,
        openNextAvailabilitiesSchedulingModal,
        handleDeleteScheduledAvailabilitiesModal,
        isCreation,
        authorizedActions,
      }),
    );
  }, [shouldDisableNewAssociation, mappings, selectedItems, availableStores, isLoading]);

  useEffect(() => {
    const params = getSspmAvailabilitiesModalConfig({
      selectedAvailabilities: modalAvailabilities,
      sspmIds: selectedItems.map(({ id }) => id),
      setSelectedAvailabilities: setModalAvailabilities,
      handleSspmUpdate: handleSSPMChange,
      handleSspmUpdateModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [modalAvailabilities]);

  // Check if creation action should be disabled when no other store could be associated by current user
  useEffect(() => {
    const formattedStores = mappings.map(({ storeId, storeName }) => ({
      id: storeId,
      name: storeName,
    }));

    setStores(formattedStores);
    setSelectedStores(formattedStores);

    if (get(filters, 'stores', null)) {
      setFilters({
        ...filters,
        stores: {
          activeList: formattedStores,
          list: formattedStores,
          selectedStores: formattedStores,
        },
      });
    }
  }, [mappings]);

  // Handle filters change
  useEffect(() => {
    // User has not applied the filters
    if (!applyFilters) {
      return;
    }

    const filteredData = mappings.filter(
      ({ storeId, supplierProfileId, available, hasStock, hasDlc }) =>
        selectedStores.some(({ id }) => id === storeId) && // store selector
        selectedSupplierProfiles.some(({ id }) => id === supplierProfileId) && // supplier profile selector
        selectedOrderAvailability.some(({ filterValue }) => available === filterValue) && // order availability selector
        selectedStockAvailability.some(({ filterValue }) => hasStock === filterValue) && // stock availability selector
        selectedLossAvailability.some(({ filterValue }) => hasDlc === filterValue), // loss availability selector
    );

    setFilteredMappings(filteredData);
  }, [applyFilters]);

  useEffect(() => {
    if (!applyFilters || !advancedFilters) {
      return;
    }

    const filteredSspmWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        // Tricks to allow filter on groups since groups is an array of object and groupNames an array of string
        // And advanced filter only allows filter on array of strings
        const formattedPropertyKey = propertyKey === 'groups' ? 'groupNames' : propertyKey;

        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, formattedPropertyKey, list, true);
        }

        return doFilter(result, formattedPropertyKey, value);
      },
      mappings,
    );

    setFilteredMappings(filteredSspmWithAdvancedFilters);
    setIsLoading(false);
  }, [mappings, advancedFilters, applyFilters]);

  // Handle creation modal refresh
  useEffect(() => {
    const params = getStoreMappingModalConfig({
      availableStores,
      setSelectedAvailableStores,
      selectedAvailableStores,
      handleCallToCreateAssociation,
      price: supplierProductPrice,
    });

    refreshGenericModal(params);
  }, [selectedAvailableStores]);

  const formatMappingsToCreate = (items = [], allStores) =>
    items.map((item) => {
      const updatedValue = {
        ...item,
        groups: [],
        supplierProfile: {},
        lnkStoreStoresupplierproductmappingrel: {},
      };

      const matchingStore = allStores.find(({ id }) => item.storeId === id);

      if (matchingStore) {
        updatedValue.supplierProfile = matchingStore.supplierProfile;
        updatedValue.supplierProfileName = matchingStore.supplierProfile.name;

        updatedValue.storeName = matchingStore.name;
        updatedValue.lnkStoreStoresupplierproductmappingrel = {
          name: matchingStore.name,
          active: matchingStore.active,
        };
      }

      return updatedValue;
    });

  // Handle SSPM modal flow
  useEffect(() => {
    const params = getNextAvailabilitiesModalWithoutWarningStep({
      nextAvailabilitiesData,
      setNextAvailabilitiesData,
      handleNextAvailabilitiesScheduling,
      handleCloseCleanUp: handleNextAvailabilitiesSchedulingModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [nextAvailabilitiesData]);

  useEffect(() => {
    if (!columns.length) {
      return;
    }

    // Initialize advanced filters
    updateColumnsFilterList(mappings);
  }, [columns, mappings]);

  /**
   * Handle the fetch of supplier product mappings
   */
  const reloadSupplierProductMappings = async () => {
    setIsLoading(true);

    try {
      if (!supplierId) {
        return;
      }

      const stores = await storeService.getStoresByAccountIdAndSupplierId(supplierId, {
        withGroups: true,
      });

      let result = [];
      if (!isCreation) {
        result = await storeSupplierProductMapping.getStoreSupplierProductMappingsOfAccountStores(
          user.id,
          supplierProductId,
        );
      } else {
        result = formatMappingsToCreate(mappingsToCreate, stores);
      }

      const formattedMappings = formatUtils.computeSupplierProductMappings(result, stores);

      const storeIdsOfMappings = formattedMappings.map(({ storeId }) => storeId);

      const activeStoresWithoutMappings = stores.filter(
        ({ id, active, supplierProfile }) =>
          active && !isEmpty(supplierProfile) && !storeIdsOfMappings.includes(id),
      );

      setAvailableStores(activeStoresWithoutMappings);
      setShouldDisableNewAssociation(!activeStoresWithoutMappings.length && supplierProductActive);

      setMappings(formattedMappings);
      setFilteredMappings(formattedMappings);

      const formattedSupplierProfiles = Object.values(
        stores.reduce((result, { supplierProfile }) => {
          if (!isEmpty(supplierProfile) && !result[supplierProfile.id]) {
            result[supplierProfile.id] = {
              id: supplierProfile.id,
              name: supplierProfile.name,
            };
          }

          return result;
        }, {}),
      );

      setSupplierProfiles(formattedSupplierProfiles);
      setSelectedSupplierProfiles(formattedSupplierProfiles);
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_FETCH_ERROR', {
          storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
        }),
      );

      setMappings([]);
      setFilteredMappings([]);

      setSupplierProfiles([]);
      setSelectedSupplierProfiles([]);
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const updateColumnsFilterList = (storeMappings) => {
    if (!storeMappings.length) {
      return;
    }

    const groupsColumn = columns.find(({ propertyKey }) => propertyKey === 'groups');

    if (!groupsColumn) {
      return;
    }

    const groupFilterList = chain(storeMappings)
      .flatMap('groups')
      .map(({ name, id }) => ({ name, id, groupNames: name }))
      .sortBy('name')
      .sortedUniqBy('name')
      .value();

    groupFilterList.unshift({
      id: -1,
      name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      groupNames: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
    });

    groupsColumn.list = groupFilterList;

    setColumnsFilterList([groupsColumn]);
  };

  const handleSspmUpdateModalCloseCleanUp = () => {
    setModalAvailabilities({
      available: true,
      hasStock: true,
      hasDlc: true,
    });

    closeGenericModal();
  };

  /**
   * Handle the update of a supplier product mapping
   *
   * @param {[uuid]} supplierProductMappingIds - SSPM ids on which apply change
   * @param {string} propertyKey            - The property key that has been changed
   * @param {boolean} value                 - The value to apply
   *
   * @returns {Promise<void>}
   */
  const handleSSPMChange = async (supplierProductMappingIds, fields) => {
    if (isCreation) {
      const updatedMappingsToCreate = mappingsToCreate.map((item) => {
        if (!supplierProductMappingIds.includes(item.id)) {
          return item;
        }

        return { ...item, ...fields };
      });

      setMappingsToCreate(updatedMappingsToCreate);

      return;
    }

    try {
      await storeSupplierProductMapping.patchStoreSupplierProductMappingByIds(
        supplierProductMappingIds,
        fields,
      );

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPDATE_AVAILABILITIES_SUCCESS', {
          count: supplierProductMappingIds.length,
        }),
      );

      await reloadSupplierProductMappings();
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPDATE_AVAILABILITIES_ERROR', {
          count: supplierProductMappingIds.length,
        }),
      );
    }
  };

  /**
   * Handle the new association flow
   */
  const handleCreateNewAssociation = (availableStores) => {
    const params = getStoreMappingModalConfig({
      availableStores,
      setSelectedAvailableStores,
      selectedAvailableStores,
      handleCallToCreateAssociation,
      price: supplierProductPrice,
    });

    openGenericModal(params);
  };

  const handleCallToCreateAssociation = async () => {
    const storeIds = selectedAvailableStores.map(({ id }) => id);

    const createdSSPMs = storeIds.map((id) => ({
      id,
      supplierProductId,
      storeId: id,
      price: supplierProductPrice,
      hasStock: true,
      available: true,
      hasDlc: true,
    }));

    if (isCreation && setMappingsToCreate) {
      setMappingsToCreate((mappingsToCreate || []).concat(createdSSPMs));

      return;
    }

    setIsLoading(true);

    try {
      await storeSupplierProductMapping.createMultipleStoreSupplierProductMapping(createdSSPMs);

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_STORE_MAPPING_SUCCESS', {
          count: createdSSPMs.length,
        }),
      );

      await reloadSupplierProductMappings();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_STORE_MAPPING_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle the remove association flow
   */
  const handleRemoveAssociation = (mappings) => {
    const mappingsIds = mappings.map(({ id }) => id);

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_STORE_MAPPING_WARNING', {
        storeCount: mappings.length,
        count: mappings.length,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => handleCallToRemoveAssociation(mappingsIds),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const handleCallToRemoveAssociation = async (mappingIds) => {
    if (isCreation) {
      const updatedMappingsToCreate = mappingsToCreate.filter(
        ({ id }) => !mappingIds.some((mappingId) => mappingId === id),
      );

      setMappingsToCreate(updatedMappingsToCreate);

      return;
    }

    setIsLoading(true);

    try {
      await storeSupplierProductMapping.deleteMultipleStoreSupplierProductMapping(mappingIds);

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_STORE_MAPPING_SUCCESS', {
          count: mappings.length,
        }),
      );

      await reloadSupplierProductMappings();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_STORE_MAPPING_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle the export of data
   *
   * @param {boolean} isSelection - Whether the export should be of all the list or the selectedItems
   */
  const handleExport = (isSelection = false) => {
    const titleSheetStores = getClientStoreNameTranslation(storeName, true);

    const mappingsToExport = sortBy(isSelection ? selectedItems : mappings, ({ storeName }) =>
      storeName.toLowerCase(),
    );

    const sheetStores = utilsXLS.generateDefaultSheet(titleSheetStores, columns, mappingsToExport);

    const fileName = i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EXPORT_FILENAME', {
      storeName: titleSheetStores,
      date: moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    });

    utilsXLS.makeXLS(fileName, [sheetStores]);
  };

  const openNextAvailabilitiesSchedulingModal = (selectedMappings) => {
    setSelectedItems(selectedMappings);

    const params = getNextAvailabilitiesModalWithoutWarningStep({
      nextAvailabilitiesData,
      setNextAvailabilitiesData,
      handleNextAvailabilitiesScheduling,
      handleCloseCleanUp: handleNextAvailabilitiesSchedulingModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const handleNextAvailabilitiesSchedulingModalCloseCleanUp = () => {
    setNextAvailabilitiesData({
      available: true,
      startDate: '',
    });
  };

  const handleNextAvailabilitiesScheduling = async () => {
    pageLoading();
    try {
      const sspmToCreate = selectedItems.map((mapping) => ({
        ...omit(mapping, ['id', 'storeActive']),
        ...nextAvailabilitiesData,
        supplierProductId,
        price: supplierProductPrice,
      }));

      await storeSupplierProductMapping.createMultipleStoreSupplierProductMapping(sspmToCreate);

      handleNextAvailabilitiesSchedulingModalCloseCleanUp();
      closeGenericModal();
      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_AVAILABILITIES_BATCH_CREATION_SUCCESS'),
      );

      await reloadSupplierProductMappings();
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_AVAILABILITIES_BATCH_CREATION_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  /**
   * Handle the edition association flow
   */
  const handleEditionAssociation = () => {
    const params = getSspmAvailabilitiesModalConfig({
      selectedAvailabilities: modalAvailabilities,
      sspmIds: selectedItems.map(({ id }) => id),
      setSelectedAvailabilities: setModalAvailabilities,
      handleSspmUpdate: handleSSPMChange,
      handleSspmUpdateModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const handleDeleteScheduledAvailabilitiesModal = (sspms) => {
    const sspmsIds = sspms.map(({ id }) => id);

    const isSingleSelection = sspmsIds.length === 1;

    const warningModalConfig = getConfirmationModal({
      title: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_SINGLE')
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_MULTIPLE'),
      content: isSingleSelection
        ? i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.DETAILS_STORES_DELETE_SCHEDULED_AVAILABILITY_MODAL_CONTENT_SINGLE',
            {
              storeName: formattedPluralStoreName,
            },
          )
        : i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.DETAILS_STORES_DELETE_SCHEDULED_AVAILABILITY_MODAL_CONTENT_MULTIPLE',
            {
              storeName: formattedPluralStoreName,
            },
          ),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            handleCallToDeleteScheduledAvailabilities(sspmsIds);
          },
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const handleCallToDeleteScheduledAvailabilities = async (sspmsIds) => {
    pageLoading();

    const isSingleSelection = sspmsIds.length === 1;

    try {
      await storeSupplierProductMapping.deleteMultipleStoreSupplierProductMapping(sspmsIds, true);

      showSuccessMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_SUCCESS_SINGLE')
          : i18next.t(
              'ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_SUCCESS_MULTIPLE',
            ),
      );

      await reloadSupplierProductMappings();
    } catch (error) {
      showErrorMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_ERROR_SINGLE')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_ERROR_MULTIPLE'),
      );
    } finally {
      pageLoaded();
    }
  };

  if (!isLoading && !mappings.length) {
    const storeNameToLowerCase = getClientStoreNameTranslation(storeName).toLowerCase();

    return (
      <GeneralEmptyStateListView
        icon={'/images/inpulse-icon-no-data.svg'}
        renderAction={() =>
          !isCreation && canEditSupplierProductFromDetailView(authorizedActions) ? (
            <Button
              color="inpulse-default"
              handleClick={() => handleCreateNewAssociation(availableStores)}
              icon={'/images/inpulse/add-white-small.svg'}
              isDisabled={shouldDisableNewAssociation}
              label={i18next.t('GENERAL.ADD')}
            />
          ) : null
        }
        subtitle={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EMPTY_STATE_SUBTITLE', {
          storeName: storeNameToLowerCase,
        })}
        title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EMPTY_STATE_TITLE', {
          storeName: storeNameToLowerCase,
        })}
      />
    );
  }

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={filteredMappings}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={LISTVIEW_FULL_SCREEN_MODAL_NO_TOP_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={columnsFilterList}
            customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
              supplierProfiles,
              selectedSupplierProfiles,
              setSelectedSupplierProfiles,
              selectedOrderAvailability,
              setSelectedOrderAvailability,
              selectedStockAvailability,
              setSelectedStockAvailability,
              selectedLossAvailability,
              setSelectedLossAvailability,
            })}
            filters={filters}
            readOnly={isLoading}
            selectedStores={selectedStores}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            setSelectedStores={setSelectedStores}
            stores={stores}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        rowActions={rowActions}
        setSelectedItems={setSelectedItems}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  activeStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/supplier-products/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductMappings);
