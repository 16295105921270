import { capitalize, cloneDeep, get, isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, NestedList, ToggleButton, Tooltip } from '@commons/utils/styledLibraryComponents';
import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { getCustomFilterMultipleCategory } from '@commons/utils/filtersFetches';
import { getFormattedCurrencyName } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { METRIC_KEY } from '@commons/constants/metricKey';
import { multipleOrderByWithNullsLast } from '@commons/utils/sorting';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import DeepsightFiltersButton from '@commons/DeepsightAnalyticsHeaders/components/DeepsightFiltersButton';
import DisplayNumber, { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePaginatorState } from '@hooks/usePaginatorState';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { lossService } from '@services/loss';
import clientService from '@services/client';
import orderService from '@services/order';

import { LOSS_TYPE } from '@losses/LossesLosses/components/LossListView/constants';

import {
  Container,
  ContentContainer,
  Filter,
  FilterContainer,
  HeaderContainer,
  InfoBoxLabel,
  LittleBoxContainer,
  NestedListContainer,
} from './styledComponents';

import columnsUtils from './utils/columns';
import ExportLossAnalyticsByStoreModal from './exports/ExportLossAnalyticsByStoreModal';
import ExportLossAnalyticsModal from './exports/ExportLossAnalyticsModal';
import formatUtils from './utils/format';

export const LossesAnalyticsByIngredient = (props) => {
  const {
    match: { path },
    client: { clientId },
    currency,
    showErrorMessage,
    stores,
    user,
    openSmallModal,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const userTimezone = getUserTimezone();
  const yesterday = moment.tz(userTimezone).subtract(1, 'days').endOf('day');

  const METRICS = [
    {
      key: 'currency',
      name: capitalize(getFormattedCurrencyName(currency.shortenedName, true)),
    },
    { key: METRIC_KEY.UNIT, name: i18next.t('GENERAL.UNIT') },
  ];

  // Filters states
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [selectedLossCategories, setSelectedLossCategories] = useState([]);
  const [lossCategories, setLossCategories] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [selectedIngredientCategories, setSelectedIngredientCategories] = useState([]);
  const [selectedStores, setSelectedStores] = useState(stores);
  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);

  const [lossRate, setLossRate] = useState(0);
  const [turnover, setTurnover] = useState(0);
  const [totalRow, setTotalRow] = useState({});
  const [formattedIngredientData, setFormattedIngredientData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [filteredAnalytics, setFilteredAnalytics] = useState([]);
  const [turnoverByStoreId, setTurnoverByStoreId] = useState({});
  const [lossCategoryById, setLossCategoryById] = useState({});

  const [columns, setColumns] = useState(columnsUtils.getListColumns(METRICS[0]));
  const [isLoading, setIsLoading] = useState(true);

  const DEFAULT_NONE = i18next.t('GENERAL.NONE_VALUE');

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(userTimezone).subtract(1, 'month').startOf('day'),
    yesterday,
    userTimezone,
  );

  const paginatorState = usePaginatorState();

  useEffect(() => {
    (async () => {
      try {
        const { categories } = await clientService.getCategoriesAndSubCategories(
          clientId,
          CATEGORY_TYPES_OBJECT.INGREDIENT,
        );

        const formattedIngredientCategories = categories.map(({ name, id }) => ({
          id,
          value: name || getPropertyNoneValue(),
        }));

        const fetchedLossCategories = await lossService.getLossCategoriesByClientId();

        const filteredCategories = fetchedLossCategories.reduce((acc, lossCategory) => {
          if (lossCategory.lossType != LOSS_TYPE.RECIPE) {
            acc.push({ ...lossCategory, value: i18next.t(lossCategory.translationKey) });
          }

          return acc;
        }, []);

        filteredCategories.push({
          id: null,
          value: DEFAULT_NONE,
        });

        setIngredientCategories(formattedIngredientCategories);
        setSelectedIngredientCategories(formattedIngredientCategories);
        setLossCategories(filteredCategories);
        setLossCategoryById(keyBy(filteredCategories, 'id'));
        setSelectedLossCategories(filteredCategories);
      } catch {
        showErrorMessage(i18next.t('LOSSES.ANALYTICS.FILTERS_FETCH_ERROR'));
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // Avoid trying to fetch with only one date
    if (!periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    (async () => {
      const storeIds = stores.map(({ id }) => id);

      const fetchedTurnoverByStoreId = await orderService.getTurnoverByStoreIdsAndDates(
        storeIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      setTurnoverByStoreId(fetchedTurnoverByStoreId);
    })();
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  useEffect(() => {
    const selectedStoresTurnover = sumTurnoverOfSelectedStores();

    setTurnover(selectedStoresTurnover);
  }, [turnoverByStoreId, selectedStores]);

  useEffect(() => {
    if (
      !periodPickerState.startDate ||
      !periodPickerState.endDate ||
      !selectedStores.length ||
      !selectedIngredientCategories.length ||
      !selectedLossCategories.length
    ) {
      return;
    }

    getAnalyticsData();
  }, [
    periodPickerState.startDate,
    periodPickerState.endDate,
    selectedStores,
    selectedIngredientCategories,
    selectedLossCategories,
  ]);

  useEffect(() => {
    // Temporary loading state while data is refreshed
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [selectedMetric]);

  useEffect(() => {
    const formattedAnalyticsData = formatUtils.addNestedContentToFormattedIngredientData(
      formattedIngredientData,
      selectedMetric.key,
      lossCategoryById,
    );

    setAnalyticsData(formattedAnalyticsData);

    setColumns(columnsUtils.getListColumns(selectedMetric.key));
  }, [selectedMetric, lossCategoryById, formattedIngredientData]);

  useEffect(() => {
    if (isEmpty(totalRow)) {
      return;
    }

    const formattedLossRate = turnover
      ? getNumberFormattedWithDecimals((totalRow.total.currency * 100) / turnover, 2)
      : 0;

    setLossRate(formattedLossRate);
  }, [totalRow, turnover]);

  // Handle filters
  useEffect(() => {
    if (!analyticsData) {
      setFilteredAnalytics([]);
      return;
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredAnalytics(analyticsData);

      return;
    }

    if (!applyFilters) {
      return;
    }

    let filteredData = [];

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(analyticsData, propertyKey, value);
    });

    if (totalRow && filteredData.length) {
      const updatedTotalLine = formatUtils.aggregateStatsOnTotalLevel(
        cloneDeep(totalRow),
        filteredData,
      );
      setTotalRow(updatedTotalLine);
    }

    setFilteredAnalytics(filteredData);
  }, [analyticsData, advancedFilters, applyFilters, selectedMetric]);

  const triggerExportAnalytics = () => {
    openSmallModal({
      component: ExportLossAnalyticsModal,
      data: filteredAnalytics,
      currency,
      lossCategoryById,
      context: {
        stores: selectedStores,
        startDate: periodPickerState.startDate,
        endDate: periodPickerState.endDate,
        ingredientCategories: selectedIngredientCategories,
        lossCategories: selectedLossCategories,
        turnover,
        lossRate,
      },
    });
  };

  const triggerExportAnalyticsByStore = () => {
    openSmallModal({
      component: ExportLossAnalyticsByStoreModal,
      currency,
      lossCategoryById,
      context: {
        stores: selectedStores,
        startDate: periodPickerState.startDate,
        endDate: periodPickerState.endDate,
        ingredientCategories: selectedIngredientCategories,
        lossCategories: selectedLossCategories,
        turnover,
        lossRate,
      },
    });
  };

  const actions = [
    {
      label: i18next.t('LOSSES.ANALYTICS.EXPORT_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: triggerExportAnalytics,
    },
    {
      label: i18next.t('LOSSES.ANALYTICS.EXPORT_BY_STORE_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: triggerExportAnalyticsByStore,
    },
  ];

  const sumTurnoverOfSelectedStores = () => {
    const turnover = selectedStores.reduce((acc, { id }) => {
      const storeTurnover = turnoverByStoreId[id];

      acc += storeTurnover;

      return acc;
    }, 0);

    return turnover;
  };

  const getAnalyticsData = async () => {
    setIsLoading(true);

    const selectedStoreIds = selectedStores.map(({ id }) => id);

    const ingredientCategoryIds = selectedIngredientCategories.map(({ id }) => id);

    try {
      const fetchedAnalytics = await lossService.getLossesAnalytics(
        selectedStoreIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        selectedLossCategories.map(({ id }) => id),
        ingredientCategoryIds,
      );

      const { totalRow, formattedDataByIngredient } =
        formatUtils.formatAnalyticsForNestedList(fetchedAnalytics);

      setTotalRow(totalRow);

      setFormattedIngredientData(formattedDataByIngredient);
    } catch (err) {
      showErrorMessage(i18next.t('LOSSES.ANALYTICS.FETCH_ANALYTICS_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <HeaderContainer>
          <LeftRightSplitter
            left={
              <FilterContainer>
                <Filter>
                  <DeepsightFiltersButton
                    advancedFilters={advancedFilters}
                    columnsFilterList={columns.filter((column) => column.filterType === 'numeric')}
                    customMultipleDropDowns={[
                      getCustomFilterMultipleCategory(
                        ingredientCategories,
                        selectedIngredientCategories,
                        setSelectedIngredientCategories,
                        false,
                      ),
                      {
                        id: 'lossCategories',
                        icon: '/images/icon-loss-black.svg',
                        list: lossCategories,
                        defaultSelectedItems: lossCategories,
                        selectedItems: lossCategories,
                        setSelectedItems: setSelectedLossCategories,
                      },
                    ]}
                    filters={filters}
                    readOnly={isLoading}
                    selectedStores={selectedStores}
                    setAdvancedFilters={setAdvancedFilters}
                    setApplyFilters={setApplyFilters}
                    setFilters={setFilters}
                    setSelectedStores={setSelectedStores}
                    stores={stores}
                    textFilterButton={i18next.t('GENERAL.ANALYTICS_BUTTON')}
                  />
                </Filter>
                <PeriodDatePicker
                  disabled={isLoading}
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  maxFutureDate={yesterday}
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  startDate={periodPickerState.startDate}
                  onDatesChange={periodPickerState.handleSelectedDates}
                />
                <ToggleButton
                  choices={METRICS}
                  isDisabled={false}
                  selectedChoice={selectedMetric}
                  setSelectedChoice={setSelectedMetric}
                />
                <LittleBoxContainer>
                  <InfoBoxLabel>
                    {i18next.t('GENERAL.TURNOVER_EX_TAX')}
                    <Tooltip
                      text={i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_TOOLTIP', {
                        currencyName: getFormattedCurrencyName(currency.shortenedName, true),
                      })}
                    />
                  </InfoBoxLabel>

                  <div className="kpi">
                    {isLoading ? (
                      <DeepsightComponentLoader height={16} width={16} />
                    ) : (
                      <DisplayNumber
                        currency={currency}
                        number={turnover}
                        displayCurrencyCode
                        withoutDecimals
                      />
                    )}
                  </div>
                </LittleBoxContainer>

                <LittleBoxContainer>
                  <InfoBoxLabel>{i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE')}</InfoBoxLabel>

                  <div className="kpi">
                    {isLoading ? (
                      <DeepsightComponentLoader height={16} width={16} />
                    ) : (
                      `${lossRate}%`
                    )}
                  </div>
                </LittleBoxContainer>
              </FilterContainer>
            }
            right={
              <Button
                actions={actions}
                color={'inpulse-outline'}
                iconDropdown={'/images/inpulse/carret-black.svg'}
                iconOnLeft={false}
                label={i18next.t('GENERAL.ACTIONS')}
              />
            }
          />
        </HeaderContainer>

        {isEmpty(analyticsData) && !isLoading ? (
          <EmptyState
            iconHeight={'160px'}
            iconWidth={'160px'}
            label={i18next.t('LOSSES.ANALYTICS.INGREDIENT_EMPTY_STATE_LABEL')}
            labelColor={ENUM_COLORS.IP_BLACK_1}
            labelFont={ENUM_FONTS.H2_INTER}
            subtitle={i18next.t('LOSSES.ANALYTICS.INGREDIENT_EMPTY_STATE_SUBTITLE')}
          />
        ) : (
          <NestedListContainer>
            <NestedList
              currentPage={paginatorState.currentPage}
              customMultipleOrder={{
                orderBy: [`total.${selectedMetric.key}`, 'name'],
                orderType: [`desc`, 'asc'],
                customOrderByFunc: multipleOrderByWithNullsLast,
              }}
              customNestedContentPadding={'8px'}
              data={filteredAnalytics}
              fixedRowsData={selectedMetric.key === 'currency' ? [totalRow] : []}
              headers={columns}
              isLoading={isLoading}
              itemsCount={analyticsData.length}
              languageCode={userLanguageCode}
              maxPerPage={paginatorState.maxPerPage}
              maxPerPageOptions={paginatorState.maxPerPageOptions}
              setCurrentPage={paginatorState.setCurrentPage}
              setMaxPerPage={paginatorState.setMaxPerPage}
              hasPagination
            />
          </NestedListContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  stores: getSalesPointStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LossesAnalyticsByIngredient);
