import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, Franco, SearchBar, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { getIsCentralMode } from '@commons/utils/localStorage';
import DisplayNumber, { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import Drawer from '@commons/Drawer';
import mixpanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canChangeOrderDeliveryDate,
  canEditDraftOrder,
  canEditSentOrReceivedOrder,
  canUpdateAssociatedInvoices,
} from '@selectors/actions/orderActions';
import {
  getAuthorizedActions,
  getDisplaySupplierProductPackagingWeight,
  getResetStockEveryDayForCentralKitchen,
} from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import theme from '@theme';

import DeliveryInvoiceModal from '@orders/components/DeliveryInvoiceModal';
import ModalDeliveryReceipt from '@orders/components/DeliveryReceiptModal';

import { getTranslatedOrderAnomalies } from '../../constants/anomalies';
import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from '../../constants/status';
import ProgressBarModal from '../../components/ProgressBarModal';
import utilLibs from '../../utils';

import OrderAdditionReferenceDrawerContent from '../ProductOrdersContainer/components/ProductOrder/components/OrderSidePanel/OrderAddingReferenceDrawerContent';

import {
  ActionDot,
  ActionsContainer,
  ConfirmationMessage,
  FillRecommandationSwitch,
  FillRecommandationText,
  InfoPanelContainer,
  InpulseFillRecommandation,
  LabelContent,
  LoadingContainer,
  MainTitleContainer,
  SearchContainer,
} from './styledComponents';

import LoadingState from './components/LoadingState';
import OrderRestrictionTooltip from './components/OrderRestrictionTooltip';
import TotalBDLFranco from './components/TotalBDLFranco';
import utilsInfoPanel from './utils';

export const OrderFormInfoPanel = (props) => {
  const {
    user,
    client,
    currency,
    defaultCurrency,
    orderTimeLimit,
    isEditable,
    isEditingPreparationOrder,
    revenueDisp,
    isInitialized,
    orderConfirmationMessageReducer,
    orderTotal,
    diffBetweenOrderedAndInvoiced,
    diffBetweenReceivedAndOrdered,
    supplierId,
    suppliers,
    orderedUnit,
    prefillInpulse,
    setPrefillInpulse,
    storeId,
    productsInventory,
    purchaseOrder,
    receptionReference,
    handleReceptionReference,
    receptionComment,
    handleReceptionComment,
    picturesUrls,
    setPicturesUrls,
    orderStatus,
    preparationStatus,
    preparationInvoice,
    totalBDL,
    totalReceived,
    credit,
    deliveryNote,
    orderId,
    addNewReference,
    availableReferencesToAdd,
    partnerId,
    params,
    displaySupplierProductPackagingWeight,
    handleSearchInput,
    isGeneratingData,
    searchInput,
    setIsEditingDeliveryDate,
    sentOrderDatesFromThisMonth,
    orderTimestamp,
    authorizedActions,
    timezone,
    setOpenStockInfo,
    selectedStore,
    storeName,
    supplierName,
    supplierInfos,
    customerCode,
    orderDate,
    startDate,
    receivedDiffOfProductOrder,
    invoicedDiffOfProductOrder,
    resetStockEveryDayForCentralKitchen,
    // Props
    showMessage,
  } = props;

  const orderAnomalies = getTranslatedOrderAnomalies();
  const userHasRightToEditOrder = !(
    orderStatus === ORDER_STATUS.DRAFT && !canEditDraftOrder(authorizedActions)
  );

  // For some customers (Krispy Kreme), when ordering from a Central, they don't want to use the order recos but the theoretical consumption
  // (as the DLC is 1 day, they never have any stock).
  const shouldUseConsumption = resetStockEveryDayForCentralKitchen && supplierInfos.isKitchen;

  const isCentralMode = getIsCentralMode() || selectedStore.isKitchen;

  const [hasDeliveryReceiptInfo, setHasDeliveryReceiptInfo] = useState(false);

  const [orderMinAmount, setOrderMinAmount] = useState(null);
  const [orderMinCount, setOrderMinCount] = useState(null);
  const [availableActions, setAvailableActions] = useState([]);
  const [displayBdlModal, setDisplayBdlModal] = useState(false);
  const [displayInvoiceModal, setDisplayInvoiceModal] = useState(false);
  const [addingReferenceSidePanelOpened, setAddingReferenceSidePanelOpened] = useState(false);
  const [progressBarStatus, setProgressBarStatus] = useState({ index: 0, totalPicturesCount: 0 });
  const [openProgressBarModal, setOpenProgressBarModal] = useState(false);
  const [totalOrderWeight, setTotalOrderWeight] = useState(0);

  const downloadFile = (fileUrl) => {
    if (fileUrl) {
      let link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';
      link.click();
    } else {
      showMessage(i18next.t('ORDERS.ORDERS.FORM_LINK_DOES_NOT_EXIST'), 'error');
    }
  };

  const handleXLS = () => {
    utilsInfoPanel.makeOrderXls({
      products: productsInventory,
      user,
      client,
      orderStatus,
      orderId,
      storeName,
      supplierName,
      customerCode,
      orderDate,
      startDate,
      receivedDiffOfProductOrder,
      receptionAnomalies: orderAnomalies,
      showMessage,
      displaySupplierProductPackagingWeight,
      currency: currency || defaultCurrency,
      storeTimezone: timezone,
      partnerId,
      isEditingPreparationOrder,
      invoicedDiffOfProductOrder,
      supplierInfos,
      resetStockEveryDayForCentralKitchen,
    });
  };

  const formattedOrderTotal = getNumberFormattedWithDecimals(orderTotal, currency.numberDecimals);

  useEffect(() => {
    setHasDeliveryReceiptInfo(
      (picturesUrls && picturesUrls.length) || !!receptionComment || !!receptionReference,
    );
  }, [picturesUrls, receptionComment, receptionReference]);

  useEffect(() => {
    if (!supplierId || !storeId) {
      return;
    }

    (async function loadData() {
      const supplierProfile = await utilLibs.getSupplierProfileBySupplierIdAndStoreId(
        supplierId,
        storeId,
      );
      const isFirstOrder = !sentOrderDatesFromThisMonth.includes(startDate.startOf('day').format());

      if (supplierProfile) {
        if (isFirstOrder) {
          setOrderMinAmount(supplierProfile.orderMinAmount);
          setOrderMinCount(supplierProfile.orderMinUnit);
        } else {
          setOrderMinAmount(null);
          setOrderMinCount(null);
        }
      }
    })();
  }, [supplierId, storeId, startDate]);

  useEffect(() => {
    if (orderStatus <= ORDER_STATUS.DRAFT) {
      return;
    }

    const allowUpdateOrderDate = suppliers.some(({ id }) => id === supplierId);

    const allowDeliveryReceiptAction =
      [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus) || !!hasDeliveryReceiptInfo;

    const allowInvoiceAction = ![ORDER_STATUS.DRAFT, ORDER_STATUS.CREATION].includes(orderStatus);

    const isCKEditingNonPreparedOrder =
      isCentralMode && preparationStatus <= ORDER_PREPARATION_STATUS.PREPARING;

    const isPOSReception =
      !isCentralMode && [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus);

    const isPOSEditingPreparationOrder = !isCentralMode && preparationStatus !== null;

    const allowAddNewReference =
      (isCKEditingNonPreparedOrder && orderStatus === ORDER_STATUS.SENT) || isPOSReception;

    const updatedActions = [
      {
        id: 'action-display-delivery-receipt-infos',
        isDisabled: !allowDeliveryReceiptAction,
        isHidden:
          isEditingPreparationOrder ||
          isPOSEditingPreparationOrder ||
          !canEditSentOrReceivedOrder(authorizedActions),
        label: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{i18next.t('ORDERS.ORDERS.FORM_DELIVERY_RECEIPT_INFOS')}</div>
            {hasDeliveryReceiptInfo && <ActionDot inline />}
          </div>
        ),
        icon: `/images/inpulse/pen-${!allowDeliveryReceiptAction ? 'lmgrey' : 'black'}-small.svg`,
        handleClick: (e) => {
          if (!allowDeliveryReceiptAction) {
            return e.stopPropagation();
          }

          setDisplayBdlModal(true);
        },
      },
      {
        id: 'action-display-invoice-infos',
        isHidden:
          isPOSEditingPreparationOrder ||
          !canEditSentOrReceivedOrder(authorizedActions) || // same right than action-display-delivery-receipt-infos
          !allowInvoiceAction,
        label: <div>{i18next.t('ORDERS.ORDERS.FORM_INVOICE_INFOS')}</div>,
        icon: `/images/inpulse/payments-black.svg`,
        handleClick: () => {
          setDisplayInvoiceModal(true);
        },
      },
      {
        id: 'action-edit-date',
        label: i18next.t('ORDERS.ORDERS.ACTION_EDIT_DELIVERY_DATE'),
        handleClick: () => setIsEditingDeliveryDate(true),
        isDisabled: !allowUpdateOrderDate,
        icon: `/images/inpulse/pen-${!allowUpdateOrderDate ? 'lmgrey' : 'black'}-small.svg`,
        isHidden: isEditingPreparationOrder || !canChangeOrderDeliveryDate(authorizedActions),
      },
      {
        id: 'action-add-reference',
        isDisabled: !allowAddNewReference,
        isHidden:
          (!isCentralMode && preparationStatus !== null) ||
          !canEditSentOrReceivedOrder(authorizedActions),
        label: i18next.t('ORDERS.ORDERS.FORM_ADD_NEW_REFERENCE'),
        icon: `/images/inpulse/add-${!allowAddNewReference ? 'lmgrey' : 'black'}-small.svg`,
        handleClick: (e) => {
          if (!allowAddNewReference) {
            return e.stopPropagation();
          }

          setAddingReferenceSidePanelOpened(true);
        },
      },
      {
        id: 'export',
        label: i18next.t('GENERAL.EXPORT_XLS'),
        icon: '/images/inpulse/file-download-black-thick.svg',
        handleClick: () => handleXLS(),
      },
    ];

    if (purchaseOrder) {
      const purchaseOrderExcel = get(params, 'order.purchaseOrderExcel', null);

      updatedActions.push({
        id: 'action-get-order-form',
        label: i18next.t('ORDERS.ORDERS.FORM_ORDER_LABEL'),
        icon: '/images/inpulse/file-download-black-thick.svg',
        handleClick: () => {
          downloadFile(purchaseOrder);
          if (!!purchaseOrderExcel) {
            downloadFile(purchaseOrderExcel);
          }
        },
      });
    }

    if (deliveryNote && preparationStatus >= ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED) {
      updatedActions.push({
        id: 'action-delivery-note-request',
        label: i18next.t('ORDERS.ORDERS.FORM_DELIVERY_NOTE_REQUEST'),
        icon: '/images/inpulse/file-download-black-thick.svg',
        handleClick: () => downloadFile(deliveryNote),
      });
    }

    if (preparationInvoice && preparationStatus >= ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED) {
      updatedActions.push({
        id: 'action-preparation-invoice-request',
        label: i18next.t('ORDERS.ORDERS.FORM_PREPARATION_INVOICE_REQUEST'),
        icon: '/images/inpulse/file-download-black-thick.svg',
        handleClick: () => downloadFile(preparationInvoice),
      });
    }

    if (
      credit &&
      (orderStatus > ORDER_STATUS.COMPLIANT ||
        preparationStatus >= ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED)
    ) {
      updatedActions.push({
        id: 'action-credit-request',
        label: i18next.t('ORDERS.ORDERS.FORM_CREDIT_REQUEST'),
        icon: '/images/inpulse/file-download-black-thick.svg',
        handleClick: () => downloadFile(credit),
      });
    }

    setAvailableActions(sortActionsList(updatedActions));
  }, [purchaseOrder, hasDeliveryReceiptInfo, productsInventory]);

  useEffect(() => {
    if (progressBarStatus.index >= progressBarStatus.totalPicturesCount) {
      setOpenProgressBarModal(false);
    }
  }, [progressBarStatus]);

  const handleDeliveryReceiptAndProgressBarModalsOpening = (
    displayProgressBarModal,
    displayDeliveryReceiptModal,
  ) => {
    setOpenProgressBarModal(displayProgressBarModal);
    setDisplayBdlModal(displayDeliveryReceiptModal);
  };

  useEffect(() => {
    if (isEmpty(productsInventory)) {
      return;
    }

    let totalWeight = 0;

    Object.values(productsInventory).map((category) => {
      const filteredProducts = category.products.filter(
        (product) => product.ordered > 0 || product.invoiced > 0,
      );
      filteredProducts.map((product) => {
        if (!!product.packagingWeight) {
          totalWeight += product.packagingWeight * product.ordered;
        }
      });
    });

    setTotalOrderWeight(totalWeight.toFixed(2));
  }, [productsInventory]);

  const handleInpulsePrefill = (prefillInpulse) => {
    setPrefillInpulse(prefillInpulse);

    mixpanelUtils.sendMetric(ENUM_EVENTS.PREFILL_ORDER_FORM, {
      orderReference: orderId, // here the orderId is equal to the order reference
      timestamp: orderTimestamp,
      toggleState: prefillInpulse ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE'),
    });
  };

  const closeDrawer = () => {
    setOpenStockInfo({
      entity: null,
      category: null,
    });
    setAddingReferenceSidePanelOpened(false);
  };

  return (
    <InfoPanelContainer>
      {isGeneratingData && (
        <LoadingContainer>
          <LoadingState />
        </LoadingContainer>
      )}

      {!isGeneratingData && (
        <>
          {openProgressBarModal && (
            <ProgressBarModal
              progressBarStatus={progressBarStatus}
              setOpenProgressBarModal={setOpenProgressBarModal}
            />
          )}
          {displayBdlModal && (
            <ModalDeliveryReceipt
              close={() => setDisplayBdlModal(false)}
              handleDeliveryReceiptAndProgressBarModalsOpening={
                handleDeliveryReceiptAndProgressBarModalsOpening
              }
              handleReceptionComment={handleReceptionComment}
              handleReceptionReference={handleReceptionReference}
              params={params}
              partnerId={partnerId}
              picturesUrls={picturesUrls}
              progressBarStatus={progressBarStatus}
              readOnly={orderStatus > ORDER_STATUS.RECEPTION}
              receptionComment={receptionComment}
              receptionReference={receptionReference}
              setOpenProgressBarModal={setOpenProgressBarModal}
              setPicturesUrls={setPicturesUrls}
              setProgressBarStatus={setProgressBarStatus}
            />
          )}
          {displayInvoiceModal && (
            <DeliveryInvoiceModal
              close={() => setDisplayInvoiceModal(false)}
              isReadOnly={!canUpdateAssociatedInvoices(authorizedActions)}
              orderId={params.orderId}
              storeId={storeId}
              storeTimezone={timezone}
              supplierId={supplierId}
            />
          )}
          <Drawer isOpened={addingReferenceSidePanelOpened} onClick={closeDrawer}>
            <OrderAdditionReferenceDrawerContent
              handleNewReference={addNewReference}
              isEditingPreparationOrder={isEditingPreparationOrder}
              supplierProducts={availableReferencesToAdd}
              onClose={closeDrawer}
            />
          </Drawer>
          {orderConfirmationMessageReducer.orderMessageBool && (
            <ConfirmationMessage>
              {orderConfirmationMessageReducer.orderMessage}
            </ConfirmationMessage>
          )}
          <MainTitleContainer
            marged={isEditable && revenueDisp && isInitialized && userHasRightToEditOrder}
          >
            <SearchContainer>
              <SearchBar
                disabled={false}
                maxWidth={160}
                minWidth={160}
                placeholder={i18next.t('GENERAL.SEARCH')}
                setValue={handleSearchInput}
                value={searchInput}
              />
            </SearchContainer>
            {!isEditable ? (
              <>
                <Franco
                  content={
                    <DisplayNumber
                      color={ENUM_COLORS.DARKEST}
                      currency={currency || defaultCurrency}
                      font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                      number={formattedOrderTotal || 0}
                      displayCurrencyCode
                    />
                  }
                  title={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX_BDC')} :`}
                  width="160px"
                  hasBorder
                />
                <TotalBDLFranco
                  currency={currency || defaultCurrency}
                  diffBetweenOrderedAndInvoiced={diffBetweenOrderedAndInvoiced}
                  isEditingPreparationOrder={isEditingPreparationOrder}
                  totalBDL={totalBDL}
                />
                {!isEditingPreparationOrder && (
                  <Franco
                    background={
                      diffBetweenReceivedAndOrdered === 0
                        ? theme.colors.infoGreen
                        : theme.colors.infoRed
                    }
                    color={theme.franco.colors.white}
                    content={
                      <DisplayNumber
                        color={
                          diffBetweenReceivedAndOrdered === 0
                            ? ENUM_COLORS.INFO_GREEN
                            : ENUM_COLORS.INFO_RED
                        }
                        currency={currency || defaultCurrency}
                        font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                        number={totalReceived || 0}
                        displayCurrencyCode
                      />
                    }
                    contentColor={
                      diffBetweenReceivedAndOrdered === 0
                        ? theme.colors.infoGreen
                        : theme.colors.infoRed
                    }
                    labelContent={
                      <LabelContent>
                        {diffBetweenReceivedAndOrdered > 0 ? '+ ' : ''}
                        <DisplayNumber
                          color={ENUM_COLORS.LIGHTEST}
                          currency={currency || defaultCurrency}
                          font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
                          number={diffBetweenReceivedAndOrdered || 0}
                          displayCurrencyCode
                        />
                      </LabelContent>
                    }
                    title={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX_RECEIVED')} :`}
                    type="plain"
                    hasBorder
                  />
                )}
              </>
            ) : (
              <>
                {orderTimeLimit && orderTimeLimit !== 'Invalid date' && (
                  <OrderRestrictionTooltip
                    tooltipContent={
                      moment.tz(timezone).isSame(moment.tz(orderTimeLimit, timezone), 'day')
                        ? i18next.t(
                            'ORDERS.ORDERS.FORM_CANNOT_ORDER_AFTER_TODAY_TIME_WARNING_MESSAGE',
                            {
                              time: moment.tz(orderTimeLimit, timezone).format('HH:mm'),
                            },
                          )
                        : i18next.t(
                            'ORDERS.ORDERS.FORM_CANNOT_ORDER_AFTER_DATE_TIME_WARNING_MESSAGE',
                            {
                              datetime: moment.tz(orderTimeLimit, timezone).format('DD/MM'),
                              time: moment.tz(orderTimeLimit, timezone).format('HH:mm'),
                            },
                          )
                    }
                  />
                )}
                {(!!orderTotal || orderTotal === 0) && (
                  <Franco
                    background={
                      orderTotal < orderMinAmount
                        ? theme?.colors?.infoRed
                        : theme?.colors?.infoGreen
                    }
                    color="#FFFFFF"
                    content={
                      <DisplayNumber
                        color={ENUM_COLORS.DARKEST}
                        currency={currency || defaultCurrency}
                        font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                        number={formattedOrderTotal || 0}
                      />
                    }
                    labelContent={
                      orderMinAmount ? (
                        <DisplayNumber
                          color={ENUM_COLORS.LIGHTEST}
                          currency={currency || defaultCurrency}
                          font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
                          number={orderMinAmount}
                        />
                      ) : (
                        ''
                      )
                    }
                    title={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX')} ${
                      (currency || defaultCurrency).alphabeticCode
                    }`}
                    hasBorder
                  />
                )}
                <Franco
                  background={
                    orderedUnit < orderMinCount ? theme?.colors?.infoRed : theme?.colors?.infoGreen
                  }
                  color="#FFFFFF"
                  content={`${Math.round(orderedUnit * 100) / 100} ${i18next.t(
                    'ORDERS.ORDERS.FORM_BOX_PACKAGE_UNIT',
                  )}`}
                  labelContent={
                    !!orderMinCount
                      ? `${i18next.t('ORDERS.ORDERS.FORM_BOX_MIN_AMOUNT')} ${orderMinCount}`
                      : ''
                  }
                  title={i18next.t('ORDERS.ORDERS.FORM_BOX_MIN_ORDER_PACKAGE_UNIT')}
                  hasBorder
                />
              </>
            )}
            {displaySupplierProductPackagingWeight && (
              <Franco
                content={`${totalOrderWeight} kg`}
                title={`${i18next.t('ORDERS.ORDERS.FORM_TOTAL_WEIGHT')} :`}
                width={'160px'}
                hasBorder
              />
            )}

            {user.hasForecasts && isEditable && userHasRightToEditOrder && !isCentralMode && (
              <InpulseFillRecommandation>
                <FillRecommandationSwitch>
                  <ToggleSwitch
                    checked={prefillInpulse}
                    disabled={moment.tz(orderTimeLimit, timezone).isBefore(moment.tz(timezone))}
                    handleClick={() => handleInpulsePrefill(!prefillInpulse)}
                    id="fill-inpulse-reco"
                  />
                </FillRecommandationSwitch>
                <FillRecommandationText>
                  {i18next.t(
                    shouldUseConsumption
                      ? 'ORDERS.ORDERS.FORM_PRE_FILL_WITH_INPULSE_CONSUMPTION'
                      : 'ORDERS.ORDERS.FORM_PRE_FILL_WITH_INPULSE_RECOMMENDATION',
                  )}
                </FillRecommandationText>
              </InpulseFillRecommandation>
            )}
          </MainTitleContainer>

          {availableActions && availableActions.length > 0 && (
            <ActionsContainer>
              <Button
                actions={availableActions}
                color={'inpulse-outline'}
                iconDropdown={'/images/inpulse/ellipsis-black-small.svg'}
              />
              {hasDeliveryReceiptInfo && preparationStatus === null && <ActionDot />}
            </ActionsContainer>
          )}
          {orderStatus <= ORDER_STATUS.DRAFT && (
            <ActionsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={() => handleXLS()}
                icon={'/images/inpulse/file-download-black-small.svg'}
              />
            </ActionsContainer>
          )}
        </>
      )}
    </InfoPanelContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  defaultCurrency: state.baseReducer.currency,
  displaySupplierProductPackagingWeight: getDisplaySupplierProductPackagingWeight(
    state.baseReducer.userRights,
  ),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
  client: getClientInfo(state.baseReducer.user),
  resetStockEveryDayForCentralKitchen: getResetStockEveryDayForCentralKitchen(
    state.baseReducer.userRights,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, icon) => {
    dispatch(showConfirmationMessage(message, type, icon));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormInfoPanel);
