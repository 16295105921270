import { capitalize, cloneDeep, get, isEmpty, omit, sortBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { openSmallModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage, getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import { getFormattedCurrencyName } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { METRIC_KEY } from '@commons/constants/metricKey';
import { NestedList } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { getEntityUnit } from '@stocks/utils';

import {
  Container,
  ContentContainer,
  FilterContainer,
  HeaderContainer,
  ListContainer,
} from './styledComponents';
import {
  fetchAnalytics,
  fetchBrandsByClientId,
  fetchGroupsByStoreIds,
  fetchInventoryDateOfStoreByDates,
  fetchLocationsByAccountId,
  fetchRetailersByClientId,
} from './utils/fetch';
import { getTableViewColumns } from './utils/columns';
import { OrderAnalyticsByCategoryByStoreExportModal } from './components/OrderAnalyticsCategoryStoreExportModal';
import { OrderAnalyticsByCategoryExportModal } from './components/OrderAnalyticsCategoryExportModal';
import DeepsightFiltersButton from './components/Headers';

const ORDER_BY_KEY = 'name';

export const OrdersAnalyticsCategory = (props) => {
  const {
    user,
    currency,
    client: { clientId, hasMultipleBrands, storeName },
    match: { path },
    stores,
    showMessage,
    showErrorMessage,
  } = props;

  const userTimezone = getUserTimezone();

  const METRICS = [
    {
      key: METRIC_KEY.TURNOVER,
      name: capitalize(getFormattedCurrencyName(currency.shortenedName, true)),
    },
    { key: METRIC_KEY.UNIT, name: i18next.t('GENERAL.UNIT') },
  ];

  /**
   * FILTERS STATES
   */
  const [locations, setLocations] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [brands, setBrands] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [selectedStores, setSelectedStores] = useState([]);

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [filtersOrderAnalytics, setFiltersOrderAnalytics] = useState(null);

  const [startDate, setStartDate] = useState(moment.tz(userTimezone).subtract(1, 'month'));
  const [endDate, setEndDate] = useState(moment.tz(userTimezone).subtract(1, 'day'));
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  const [startMonth, setStartMonth] = useState(
    moment
      .tz(userTimezone)
      .subtract(1, 'month')
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );
  const [endMonth, setEndMonth] = useState(
    moment.tz(userTimezone).endOf('month').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );

  /**
   * DATA STATES
   */
  const [activeData, setActiveData] = useState({});
  const [analytics, setAnalyticsData] = useState([]);

  const [turnOver, setTurnover] = useState(0);
  const [turnoverByStoreId, setTurnoverByStoreId] = useState({});
  const [totalLossByStoreId, setTotalLossByStoreId] = useState({});

  const [lossRate, setLossRate] = useState(0);

  const [formattedInventoryStockDay, setFormattedInventoryStockDay] = useState({});
  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);

  /**
   * DISPLAY STATES
   */
  const [headers, setHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [renderEmptyState, setRenderEmptyState] = useState(false);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(false);
  const [totalRow, setTotalRow] = useState({});

  const columnsFilterList = headers.filter((column) => column.filterType === 'numeric');

  // Handle the fetch of brands / retailers / locations / groups
  useEffect(() => {
    if (!stores || !stores.length) {
      return;
    }

    setHeaders(getTableViewColumns(currency, startDate, endDate, selectedMetric.key));

    try {
      (async function loadData() {
        // Handle brands filters
        if (hasMultipleBrands) {
          const fetchedBrands = await fetchBrandsByClientId(clientId, showMessage);
          setBrands(fetchedBrands);
        }

        const storeIds = stores.map((store) => store.id);

        const [fetchedRetailers, fetchedLocations] = await Promise.all([
          fetchRetailersByClientId(clientId, showMessage),
          fetchLocationsByAccountId(user.id, showMessage),
          fetchGroupsByStoreIds(storeIds, showMessage, setGroups),
        ]);

        setRetailers(fetchedRetailers);
        setLocations(fetchedLocations);

        setSelectedStores(stores);

        setIsLoading(false);
      })();
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
    }
  }, []);

  // Handle the metrics toggle switch
  useEffect(() => {
    if (isEmpty(activeData)) {
      return;
    }

    const formattedActiveData = {
      ...activeData,
      [selectedMetric.key]: formatMetricData(analytics, selectedMetric.key),
    };

    setActiveData(formattedActiveData);

    setHeaders(getTableViewColumns(currency, startDate, endDate, selectedMetric.key));
  }, [selectedMetric]);

  // Handle the fetch of the analytics
  useEffect(() => {
    const storeIds = selectedStores.map((store) => store.id);

    if (isRetrievingAnalytics || !storeIds.length || !startDate || !endDate) {
      return;
    }

    setHeaders(getTableViewColumns(currency, startDate, endDate, selectedMetric.key));

    setRenderEmptyState(false);
    setIsRetrievingAnalytics(true);

    (async function loadData() {
      try {
        const result = await fetchAnalytics(storeIds, startDate, endDate, METRICS);

        setTotalRow(result.total);
        setAnalyticsData(result.data);

        setTotalLossByStoreId(result.lossByStoreId);
        setTurnoverByStoreId(result.turnoverByStoreId);

        const totalTurnover = result.turnoverByStoreId.total;

        setTurnover(getNumberFormattedWithDecimals(totalTurnover, 0));

        if (totalTurnover) {
          const computedLossRate = (result.total[METRIC_KEY.TURNOVER].loss * 100) / totalTurnover;

          setLossRate(getNumberFormattedWithDecimals(computedLossRate, 2));
        }
      } catch {
        showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));

        setLossRate(0);
        setTurnover(0);

        setRenderEmptyState(true);
        setIsRetrievingAnalytics(false);
      }
    })();
  }, [selectedStores, startDate, endDate]);

  // Apply filters on retrieved analytics
  useEffect(() => {
    if (isEmpty(analytics)) {
      return;
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      const formattedActiveData = {
        ...analytics,
        [selectedMetric.key]: formatMetricData(analytics, selectedMetric.key),
      };

      setActiveData(formattedActiveData);

      const formattedLossRate = turnoverByStoreId['total']
        ? getNumberFormattedWithDecimals(
            (totalRow[METRIC_KEY.TURNOVER].loss * 100) / turnoverByStoreId['total'],
            2,
          )
        : 0;

      setLossRate(formattedLossRate);
      return;
    }

    if (!applyFilters) {
      return;
    }

    const filteredData = METRICS.reduce((acc, { key }) => {
      acc[key] = formatMetricData(analytics, key);
      return acc;
    }, {});

    setActiveData(filteredData);

    const formattedLossRate = turnoverByStoreId['total']
      ? getNumberFormattedWithDecimals(
          (totalRow[METRIC_KEY.TURNOVER].loss * 100) / turnoverByStoreId['total'],
          2,
        )
      : 0;
    setLossRate(formattedLossRate);
  }, [advancedFilters, analytics, applyFilters, selectedMetric]);

  // Retrieve inventory dates when single store being selected
  useEffect(() => {
    if (selectedStores.length !== 1) {
      return;
    }

    const storeIds = selectedStores.map((stores) => stores.id);

    try {
      (async function loadData() {
        const formattedDate = await fetchInventoryDateOfStoreByDates(
          storeIds,
          startMonth,
          endMonth,
          userTimezone,
          showMessage,
        );

        setFormattedInventoryStockDay(formattedDate);
      })();
    } catch {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'));
    }
  }, [selectedStores, startMonth, endMonth]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setRenderEmptyState(isEmpty(activeData));
    setIsRetrievingAnalytics(false);
  }, [activeData]);

  const handleSelectedDates = (dates) => {
    if (!moment.tz(dates.startDate, userTimezone).isSame(startDate, 'day')) {
      setEndDate(null);
      setStartDate(dates.startDate);
    }

    if (!moment.tz(dates.endDate, userTimezone).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
    }
  };

  const formatMetricData = (data, key) =>
    data[key].reduce((result, categoryData) => {
      const filteredCategory = cloneDeep(categoryData);

      const childItems = (advancedFilters || []).reduce(
        (filteredChildItems, { propertyKey, doFilter, value }) =>
          doFilter(filteredChildItems, `${propertyKey}`, value),
        categoryData.childItems,
      );

      filteredCategory.childItems = childItems;

      filteredCategory.nestedData = childItems.map((ingredient) => ({
        ...ingredient,
        link: `/admin/products/ingredients/${ingredient.id}/details`,
      }));

      if (childItems.length) {
        const aggregatedStatsOnCategoryLevel = filteredCategory;

        const aggregatedCategory = {
          ...omit(filteredCategory, 'nestedContent'),
          ...omit(aggregatedStatsOnCategoryLevel, 'nestedContent'),
        };

        result.push(aggregatedCategory);
      }

      return result;
    }, []);

  const triggerExport = () => {
    const data = METRICS.reduce(
      (acc, metric) => {
        activeData[metric.key].forEach((element) => {
          const childItems = get(element, 'childItems', null);
          const category = get(element, 'name', null);

          if (childItems) {
            childItems.forEach((entity) => {
              const result = {
                ...entity,
                category,
                unit: getEntityUnit(entity.unit, false),
              };

              if (metric.key === METRIC_KEY.TURNOVER) {
                acc.turnoverSheetData.push(result);
              }

              if (metric.key === METRIC_KEY.UNIT) {
                acc.unitSheetData.push(result);
              }
            });
          }
        });

        return acc;
      },
      { turnoverSheetData: [], unitSheetData: [] },
    );

    const contextSheetData = [];
    selectedStores.forEach((store) => {
      const turnover = get(turnoverByStoreId, `[${store.id}].value`, null);

      const lossRate = formatNumberToExcelUsage(
        (get(totalLossByStoreId, `${store.id}.total`, null) / turnover) * 100,
        2,
      );

      contextSheetData.push({
        storeName: store.name,
        startDate,
        endDate,
        turnover: get(turnoverByStoreId, `[${store.id}].value`),
        lossRate: isNaN(lossRate) || !turnover ? '' : `${lossRate} %`,
      });
    });

    props.openModalExportInfo({
      component: OrderAnalyticsByCategoryExportModal,
      title: i18next.t('ORDERS.BY_CATEGORY.EXPORT_IN_PROGRESS'),
      turnoverSheetData: sortBy(data.turnoverSheetData, ['category', 'name']),
      unitSheetData: sortBy(data.unitSheetData, ['category', 'name']),
      contextSheetData: sortBy(contextSheetData, 'storeName'),
      currency,
      showMessage,
    });
  };

  const handleExportByStore = () => {
    props.openModalExportInfo({
      component: OrderAnalyticsByCategoryByStoreExportModal,
      title: i18next.t('ORDERS.BY_CATEGORY.EXPORT_IN_PROGRESS'),
      stores: selectedStores,
      startDate,
      endDate,
      turnoverByStoreId,
      totalLossByStoreId,
      metrics: METRICS,
      currency,
      storeName,
      showMessage,
    });
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <HeaderContainer>
          <FilterContainer>
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              brands={brands}
              columnsFilterList={columnsFilterList}
              disableExport={isEmpty(activeData) || isEmpty(activeData[selectedMetric.key])}
              endDate={endDate}
              filters={filtersOrderAnalytics}
              focusedDateInput={focusedDateInput}
              formattedInventoryStockDay={formattedInventoryStockDay}
              groups={groups}
              handleExport={triggerExport}
              handleExportByStore={handleExportByStore}
              handleSelectedDates={handleSelectedDates}
              isLoading={isLoading || isRetrievingAnalytics}
              locations={locations}
              lossRate={lossRate}
              metrics={METRICS}
              readOnly={isLoading || isRetrievingAnalytics}
              retailers={retailers}
              selectedBrands={selectedBrands}
              selectedGroups={selectedGroups}
              selectedLocations={selectedLocations}
              selectedMetric={selectedMetric}
              selectedRetailers={selectedRetailers}
              selectedStores={selectedStores}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setEndMonth={setEndMonth}
              setFilters={setFiltersOrderAnalytics}
              setFocusedDateInput={setFocusedDateInput}
              setSelectedBrands={setSelectedBrands}
              setSelectedGroups={setSelectedGroups}
              setSelectedLocations={setSelectedLocations}
              setSelectedMetric={setSelectedMetric}
              setSelectedRetailers={setSelectedRetailers}
              setSelectedStores={setSelectedStores}
              setStartMonth={setStartMonth}
              startDate={startDate}
              stores={stores}
              textFilterButton={i18next.t('GENERAL.ANALYTICS_BUTTON')}
              turnOver={turnOver}
            />
          </FilterContainer>
        </HeaderContainer>
        <ListContainer>
          {renderEmptyState && (
            <div style={{ verticalAlign: 'middle', height: 'calc(100vh - 185px)' }}>
              <EmptyState />
            </div>
          )}
          {!renderEmptyState && (
            <NestedList
              data={activeData[selectedMetric.key] || []}
              defaultOrderBy={ORDER_BY_KEY}
              defaultOrderType={'asc'}
              fixedRowsData={
                selectedMetric.key === 'turnover' ? [totalRow[selectedMetric.key]] : []
              }
              headers={headers}
              isLoading={isLoading || isRetrievingAnalytics}
              minWidth={'978px'}
              hasNestedData
              isExpandable
            />
          )}
        </ListContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersAnalyticsCategory);
