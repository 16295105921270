import styled from 'styled-components';

/** NestedList Rows */
export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NestedListContainer = styled.div`
  width: 100%;
  height: calc(100% - 90px);

  position: relative;
`;
