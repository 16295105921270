import { connect } from 'react-redux';
import { difference, get, uniqBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import { NONE_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import EmptyState from '@commons/EmptyState';

import useLocalStorage from '@hooks/useLocalStorage';

import { canEditStorageArea } from '@selectors/actions/storageAreaActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import storageAreaService from '@services/storageArea';

import DeepsightFiltersButton from '@admin/components/FilterButton';

import formatUtils from '@admin/inventories/utils/formatUtils';
import RecipesMappingsModal from '@admin/inventories/utils/recipeMappings.utils';

import actionsUtils from '../utils/actionsUtils';
import columnsUtils from '../utils/columnsUtils';

import { Container } from './styledComponents';

/** MAPPINGS WITH RECIPES */
const StorageAreaRecipeMappings = (props) => {
  const {
    authorizedActions,
    openGenericModal,
    refreshGenericModal,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    client: { clientId, hasMultipleBrands },
    isLoading,
    isCreation,
    existingStorageArea,
    storageAreaForm,
    formFields,
    storeIds,
  } = props;

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  // States ListView
  const [columns] = useState(columnsUtils.getStorageAreaRecipeMappingsColumns(hasMultipleBrands));
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [brandsToChoose, setBrandsToChoose] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [allRecipeMappings, setAllRecipeMappings] = useState({});
  const [recipeMappings, setRecipeMappings] = useState([]);
  const [filteredRecipeMappings, setFilteredRecipeMappings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // Associations states
  const [recipesToBeAdded, setRecipesToBeAdded] = useState([]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (
      isCreation ||
      (existingStorageArea && !get(existingStorageArea, 'recipeMappings', []).length)
    ) {
      setDisplayEmptyState(true);
      return;
    }

    const formattedMappings = existingStorageArea.recipeMappings.reduce(
      (acc, { id, entityId, lnkEntityStorageareamappingrel, brandEntityMappings }) => {
        const { name, category, isKitchen } = lnkEntityStorageareamappingrel;

        const brands = brandEntityMappings.map(({ brandId, brandName }) => ({
          id: brandId,
          name: brandName,
        }));

        const recipeInformation = {
          id,
          name,
          category,
          entityId,
          brands,
        };

        acc[isKitchen ? 'centralKitchenRecipeMappings' : 'salesPointRecipeMappings'].push(
          recipeInformation,
        );

        return acc;
      },
      { salesPointRecipeMappings: [], centralKitchenRecipeMappings: [] },
    );

    setAllRecipeMappings(formattedMappings);

    const concernedMappings = isInCentralMode
      ? formattedMappings.centralKitchenRecipeMappings
      : formattedMappings.salesPointRecipeMappings;

    setRecipeMappings(concernedMappings);
    setFilteredRecipeMappings(concernedMappings);
  }, [isLoading, existingStorageArea]);

  useEffect(() => {
    if (formFields && formFields.recipeMappings) {
      setRecipeMappings(formFields.recipeMappings);
      setFilteredRecipeMappings(formFields.recipeMappings);
    }
  }, [formFields]);

  useEffect(() => {
    if (!isLoading && !recipeMappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    setDisplayEmptyState(false);

    const filtersData = recipeMappings.reduce(
      (acc, { category, brands }) => {
        if (!acc.filterCategories.some(({ name }) => name === category)) {
          acc.filterCategories.push({ id: category, name: category });
        }

        acc.filterBrands = acc.filterBrands.concat(brands);

        return acc;
      },
      { filterCategories: [], filterBrands: [NONE_DROPDOWN_VALUES().FEMININE] },
    );

    const uniqCategories = filtersData.filterCategories;

    setCategoriesToChoose(uniqCategories);
    setSelectedCategories(uniqCategories);

    const uniqBrands = uniqBy(filtersData.filterBrands, 'id');

    setBrandsToChoose(uniqBrands);
    setSelectedBrands(uniqBrands);

    if (get(filters, 'categories', null) || get(filters, 'brands', null)) {
      let filtersToBeSet = {
        ...filters,
        categories: {
          id: 'categories',
          icon: '/images/inpulse/category-ipblack-small.svg',
          list: uniqCategories,
          defaultSelectedItems: uniqCategories,
          selectedItems: uniqCategories,
          setSelectedItems: setSelectedCategories,
        },
      };

      if (hasMultipleBrands) {
        filtersToBeSet = {
          ...filtersToBeSet,
          brands: {
            activeList: uniqBrands,
            list: uniqBrands,
            selectedBrands: uniqBrands,
          },
        };
      }

      setFilters(filtersToBeSet);
    }
  }, [recipeMappings]);

  // Handle filters change
  useEffect(() => {
    // User has not applied the filters
    if (!applyFilters) {
      return;
    }

    const filteredData = recipeMappings.filter(({ category, brands }) => {
      const categoriesFilterCondition = selectedCategories.some(({ name }) => category === name);

      // Apply brand filter only if client has multiple brands
      if (hasMultipleBrands && selectedBrands.length) {
        const brandNames = brands.map(({ name }) => name);

        // need to filter by brands only if at least one brand is selected (or None is selected)
        const includeNone = selectedBrands.find(({ id }) => id === -1);

        const brandsFilterCondition = selectedBrands.some(
          (selectedBrand) =>
            brandNames.includes(selectedBrand.name) || (includeNone && !brands.length), // keep recipes without brands if none is selected
        );

        return categoriesFilterCondition && brandsFilterCondition;
      }

      return categoriesFilterCondition;
    });

    setFilteredRecipeMappings(filteredData);
  }, [applyFilters, selectedCategories]);

  useEffect(() => {
    setActions(
      actionsUtils.getStorageAreaMappingsGlobalActions({
        isLoading,
        selectedItems,
        openAssociationModal: handleAddAssociation,
        handleRemoveAssociation,
        isRecipeAssociation: true,
        authorizedActions,
      }),
    );

    setRowActions(
      actionsUtils.getStorageAreaMappingsRowActions({
        handleRemoveAssociation,
        isRecipeAssociation: true,
        authorizedActions,
      }),
    );
  }, [filteredRecipeMappings, selectedItems, isLoading]);

  useEffect(() => {
    const params = getRecipeMappingsModalConfig();

    refreshGenericModal(params);
  }, [recipesToBeAdded]);

  /** FUNCTIONS */

  const getRecipeMappingsModalConfig = () => ({
    type: 'actionWhite',
    isFullscreen: true,
    title: i18next.t('ADMIN.STORAGE_AREAS.RECIPE_MAPPINGS_ASSOCIATION_MODAL_TITLE'),
    icon: '/images/inpulse/add-black-small.svg',
    customPadding: '24px 0px 0px 0px',
    component: RecipesMappingsModal,
    data: {
      clientId,
      hasMultipleBrands,
      setRecipesToBeAdded,
      alreadyMappedRecipes: recipeMappings,
      storeIds,
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: () => {
          if (!isCreation) {
            addStorageAreaMappings();
            return;
          }

          storageAreaForm.setValue('recipeMappings', [...recipeMappings, ...recipesToBeAdded]);
        },
      },
    ],
  });

  const handleAddAssociation = () => {
    const params = getRecipeMappingsModalConfig();

    openGenericModal(params);
  };

  const handleRemoveAssociation = (selectedItems) => {
    const firstEntityMapping = selectedItems[0];

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t('ADMIN.STORAGE_AREAS.REMOVE_RECIPE_MAPPINGS_WARNING', {
        count: selectedItems.length,
        name: firstEntityMapping.name,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => deleteStorageAreaMappings(selectedItems),
        },
      ],
    });
    openGenericModal(warningModalConfig);
  };

  const handleStorageAreaMappingsUpdate = async (
    updatedMappingsRecipeIds,
    updatedRecipeMappingsList,
  ) => {
    if (!isCreation) {
      const existingMappingsSPIds = existingStorageArea.supplierProductMappings.map(
        ({ supplierProductId }) => supplierProductId,
      );

      pageLoading();

      try {
        // Keep non visible mappings according to user mode (sales point or central kitchen mode)
        const recipeMappingsToKeep = get(
          allRecipeMappings,
          isInCentralMode ? 'salesPointRecipeMappings' : 'centralKitchenRecipeMappings',
          [],
        );

        const recipeMappingsToKeepEntityIds = recipeMappingsToKeep.map(({ entityId }) => entityId);

        const entityIds = [...recipeMappingsToKeepEntityIds, ...updatedMappingsRecipeIds];

        await storageAreaService.updateStorageArea(
          clientId,
          existingStorageArea.id,
          existingStorageArea.name,
          existingMappingsSPIds,
          entityIds,
        );

        showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));

        setRecipeMappings(updatedRecipeMappingsList);
        setFilteredRecipeMappings(updatedRecipeMappingsList);

        // Unselect selected recipes because recipe list changed
        setSelectedItems([]);
      } catch {
        showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
      } finally {
        pageLoaded();

        return;
      }
    }

    storageAreaForm.setValue('recipeMappings', updatedRecipeMappingsList);

    // Unselect all recipes because recipe list changed
    setSelectedItems([]);
  };

  const addStorageAreaMappings = async () => {
    const updatedRecipeMappingsList = [...recipeMappings, ...recipesToBeAdded];

    const updatedMappingsRecipeIds = updatedRecipeMappingsList.map(({ entityId }) => entityId);

    handleStorageAreaMappingsUpdate(updatedMappingsRecipeIds, updatedRecipeMappingsList);
  };

  const deleteStorageAreaMappings = async (selectedItems) => {
    const entityIdsToBeRemoved = selectedItems.map(({ entityId }) => entityId);
    const mappingsEntityIds = recipeMappings.map(({ entityId }) => entityId);

    // Get storage area updated mappings entity ids list
    const updatedMappingsRecipeIds = difference(mappingsEntityIds, entityIdsToBeRemoved);

    const updatedRecipeMappingsList = Object.values(
      recipeMappings.reduce((acc, mapping) => {
        const exist = updatedMappingsRecipeIds.includes(mapping.entityId);
        if (exist) {
          acc[mapping.entityId] = mapping;
          return acc;
        }
        return acc;
      }, {}),
    );

    handleStorageAreaMappingsUpdate(updatedMappingsRecipeIds, updatedRecipeMappingsList);
  };

  const renderNoRecipeMappings = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_RECIPE_MAPPINGS_LABEL')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() =>
        canEditStorageArea(authorizedActions) && (
          <Button
            color={'inpulse-default'}
            handleClick={handleAddAssociation}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )
      }
      subtitle={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_RECIPE_MAPPINGS_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  const getConditionnalFiltersProperties = () =>
    hasMultipleBrands
      ? {
          brands: brandsToChoose,
          selectedBrands: selectedBrands,
          setSelectedBrands: setSelectedBrands,
        }
      : {};

  return (
    <Container>
      {displayEmptyState ? (
        renderNoRecipeMappings()
      ) : (
        <ListView
          actions={actions}
          columns={columns}
          data={filteredRecipeMappings}
          defaultMaxPerPage={500}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
          minActionsInActionsDropdown={1}
          padding={LISTVIEW_NO_TOP_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              applyFilters={applyFilters}
              customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
                categoriesToChoose,
                selectedCategories,
                setSelectedCategories,
              })}
              filters={filters}
              readOnly={isLoading}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              {...getConditionnalFiltersProperties()}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  activeStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/inventories/storage-areas/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreaRecipeMappings);
