import { connect } from 'react-redux';
import { get, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getEntityUnit } from '@stocks/utils';

import { fetchAnalytics } from '../../utils/fetch';
import {
  getContextSheetColumnsSettings,
  getMainSheetColumnsSettings,
} from '../../utils/exportColumns';
import { METRIC_KEY } from '@commons/constants/metricKey';
import generateFile from '../../utils/generateFile';

export const OrderAnalyticsByCategoryByStoreExportModal = (props) => {
  const {
    params: {
      title,
      stores,
      startDate,
      endDate,
      turnoverByStoreId,
      totalLossByStoreId,
      metrics,
      showMessage,
      currency,
      storeName,
    },
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const exitModal = () => {
    setIsLoading(false);

    closeModal();
  };

  useEffect(() => {
    (async function launchExport() {
      setProgress(0);

      try {
        const turnoverSheetData = [];
        const unitSheetData = [];
        const contextSheetData = [];

        for (const [index, store] of stores.entries()) {
          const result = await fetchAnalytics([store.id], startDate, endDate, metrics);

          setProgress(((index + 1) / stores.length) * 100);

          metrics.forEach((metric) => {
            result.data[metric.key].forEach((element) => {
              const childItems = get(element, 'childItems', []);
              const category = get(element, 'name', null);

              childItems.forEach((entity) => {
                const result = {
                  ...entity,
                  category,
                  storeName: store.name,
                  unit: getEntityUnit(entity.unit, false),
                };

                if (metric.key === METRIC_KEY.TURNOVER) {
                  turnoverSheetData.push(result);
                }

                if (metric.key === METRIC_KEY.UNIT) {
                  unitSheetData.push(result);
                }
              });
            });
          });

          const turnover = get(turnoverByStoreId, `[${store.id}].value`, null);

          const lossRate = formatNumberToExcelUsage(
            (get(totalLossByStoreId, `${store.id}.total`, null) / turnover) * 100,
            2,
          );

          contextSheetData.push({
            storeName: store.name,
            startDate,
            endDate,
            turnover: get(turnoverByStoreId, `[${store.id}].value`),
            lossRate: isNaN(lossRate) || !turnover ? '' : `${lossRate} %`,
          });
        }

        const turnoverSheet = utilsXLS.generateDefaultSheet(
          i18next.t('ORDERS.BY_CATEGORY.EXPORT_TURNOVER_SHEET_NAME', { currency: currency.name }),
          getMainSheetColumnsSettings('turnover', storeName, {
            groupByStore: true,
            isCentralKitchenMode: false,
          }),
          sortBy(turnoverSheetData, ['storeName', 'category', 'name']),
          currency,
        );

        const unitSheet = utilsXLS.generateDefaultSheet(
          i18next.t('GENERAL.UNIT'),
          getMainSheetColumnsSettings('unit', storeName, {
            groupByStore: true,
            isCentralKitchenMode: false,
          }),
          sortBy(unitSheetData, ['storeName', 'category', 'name']),
          currency,
        );

        const contextSheet = utilsXLS.generateDefaultSheet(
          i18next.t('ORDERS.BY_CATEGORY.EXPORT_CONTEXT_SHEET_NAME'),
          getContextSheetColumnsSettings(),
          contextSheetData,
          currency,
        );

        generateFile(
          i18next.t('ORDERS.BY_CATEGORY.EXPORT_ANALYTICS_BY_STORE_FILENAME'),
          turnoverSheet,
          unitSheet,
          contextSheet,
        );

        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch {
        showMessage(i18next.t('GENERAL.EXPORT_FAILURE'), 'error');
        exitModal();
      }
    })();
  }, []);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(OrderAnalyticsByCategoryByStoreExportModal);
