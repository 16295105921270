import PropTypes from 'prop-types';
import React from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Container, LabelContainer, TooltipContainer } from './styledComponents';

/**
 * Used mainly in Food Cost analytics, to have the Turnover without Taxes and the Loss ratio
 */
const KpiDisplay = ({ label, tooltip, currency, value, isLoading, isPercentage }) => (
  <Container>
    <LabelContainer hasTooltip={tooltip}>
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
        {label}
      </Text>
      {tooltip && (
        <TooltipContainer>
          <Tooltip text={tooltip} />
        </TooltipContainer>
      )}
    </LabelContainer>

    {isLoading ? (
      <DeepsightComponentLoader height={16} width={16} />
    ) : isPercentage ? (
      <Text>{formatNumber(value, 2)}%</Text>
    ) : (
      <DisplayNumber currency={currency} number={value} displayCurrencyCode withoutDecimals />
    )}
  </Container>
);

KpiDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  currency: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPercentage: PropTypes.bool,
};

KpiDisplay.defaultProps = {
  tooltip: null,
  isPercentage: false,
};

export default KpiDisplay;
