import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

function getSuppliersOfClient(clientId, withLinkedData = true, filterByUserCatalog = false) {
  return APIcaller.apiGet(
    `/admin/clients/${clientId}/suppliers?withLinkedData=${withLinkedData}&filterByUserCatalog=${filterByUserCatalog}`,
  );
}

function getSupplierPriceInputs(clientId, supplierId) {
  return APIcaller.apiGet(`/admin/clients/${clientId}/suppliers/${supplierId}/inputs-price`);
}

function patchSupplier(supplier) {
  return axios
    .patch(baseApiUrl + '/Suppliers', supplier, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSuppliersOfStore(storeId) {
  return axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/suppliers`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSuppliersOfStores(storeIds, { withLinkedData = true } = {}) {
  return APIcaller.apiPost(`/admin/stores/suppliers`, {
    storeIds,
    withLinkedData,
  });
}

function getSuppliersFromHistoricalOrdersMadeOnStores(storeIds) {
  return axios
    .post(`${baseApiUrl}/order/suppliers`, {
      storeIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSuppliersCategories(clientId) {
  return axios
    .get(baseApiUrl + '/admin/suppliersCategories', {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchMultipleSuppliers(suppliers) {
  return axios
    .patch(
      baseApiUrl + '/Suppliers/many',
      {
        data: suppliers,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      let resp = response.data;
      if (response.data.data) resp = response.data.data;
      return resp;
    })
    .catch(function (error) {
      throw error;
    });
}

function changeSuppliersStatus(supplierIds, active) {
  return axios
    .patch(
      baseApiUrl + '/admin/suppliers/status',
      {
        supplierIds,
        active,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getStoresByAccountIdAndSupplierId(supplierId) {
  return axios
    .get(`${baseApiUrl}/admin/suppliers/${supplierId}/stores`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const updateSupplierPropertyByBatch = (suppliers) =>
  axios
    .patch(
      `${baseApiUrl}/admin/suppliers`,
      {
        suppliersToUpdate: { updates: suppliers },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const supplier = {
  patchSupplier,
  getSuppliersOfClient,
  getSupplierPriceInputs,
  getSuppliersOfStore,
  getSuppliersCategories,
  getSuppliersOfStores,
  patchMultipleSuppliers,
  changeSuppliersStatus,
  updateSupplierPropertyByBatch,
  getStoresByAccountIdAndSupplierId,
  getSuppliersFromHistoricalOrdersMadeOnStores,
};
