import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import ExportModalContent from '@lib/inpulse/ExportModal';

import invoiceService from '@services/invoice';

import { exportGapAnalysis } from '@src/routes/invoice/commons/export';
import {
  formatAssociatedOrdersDataForExport,
  formatICLGapsDataForExport,
  formatICLInformationDataForExport,
} from '@src/routes/invoice/commons/export/format';

export const InvoiceControlListsAnalyseAndExportModal = (props) => {
  const {
    closeModal,
    params: { invoiceControlListIds, currency, storeName },
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [iclsGapsdata, setIclsGapsData] = useState([]); // gaps sheet
  const [iclsInformationData, setIclsInformationData] = useState([]); // info sheet
  const [iclsAssociatedOrdersData, setIclsAssociatedOrdersData] = useState([]); // associated orders sheet
  const [ICLTotalFetchedCount, setICLTotalFetchedCount] = useState(0);

  const ICLTotalToFetchNb = invoiceControlListIds.length;

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(
    i18next.t('INVOICE.INVOICE_CONTROLS.CONTENT_EXPORT_MODAL_TITLE'),
  );

  const exitModal = () => {
    setLoading(false);
    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const updateProgress = (totalFetched) => {
    const invoiceControlsLeft = ICLTotalToFetchNb - totalFetched;
    const updatedProgress = 100 - (invoiceControlsLeft / ICLTotalToFetchNb) * 100;

    setProgress(updatedProgress);
  };

  const getInvoiceControlListDetailsToBeExported = async (invoiceControlListId) => {
    try {
      const [invoiceControlList, fetchedGapsAnalysisData, associatedOrders] = await Promise.all([
        invoiceService.getById(invoiceControlListId),
        invoiceService.analyseAndExportInvoiceControlListDetails(invoiceControlListId),
        invoiceService.getOrdersByInvoiceControlListId(invoiceControlListId),
      ]);

      const formattedICLsGapsData = formatICLGapsDataForExport(fetchedGapsAnalysisData);

      const formattedICLInformationData = formatICLInformationDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      const formattedAssociatedOrdersData = formatAssociatedOrdersDataForExport(
        invoiceControlList,
        associatedOrders,
      );

      const updatedFetchedNb = ICLTotalFetchedCount + 1;

      setIclsGapsData([...iclsGapsdata, ...formattedICLsGapsData]);
      setIclsInformationData([...iclsInformationData, formattedICLInformationData]);
      setIclsAssociatedOrdersData([...iclsAssociatedOrdersData, ...formattedAssociatedOrdersData]);

      setICLTotalFetchedCount(updatedFetchedNb);
      updateProgress(updatedFetchedNb);
    } catch {
      exportFailure();
    }
  };

  const exportData = () => {
    exportGapAnalysis(
      iclsGapsdata,
      iclsInformationData,
      iclsAssociatedOrdersData,
      storeName,
      currency,
    );

    return exportReady();
  };

  useEffect(() => {
    if (isLoading && ICLTotalFetchedCount < ICLTotalToFetchNb) {
      // Get invoice control list gap analysis data one by one
      const nextInvoiceControlListId = invoiceControlListIds[ICLTotalFetchedCount];

      (async () => {
        await getInvoiceControlListDetailsToBeExported(nextInvoiceControlListId);
      })();

      return;
    }

    exportData();
  }, [ICLTotalFetchedCount]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={title}
    />
  );
};

export default InvoiceControlListsAnalyseAndExportModal;
