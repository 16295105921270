import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';
import DeepsightFiltersButton from '@commons/DeepsightAnalyticsHeaders/components/DeepsightFiltersButton';
import EmptyState from '@commons/EmptyState';

import { StoreListViewContainer } from './styledComponents';

const SupplierProfileModalAssociations = (props) => {
  const {
    columns,
    data,
    defaultOrderBy,
    defaultOrderType,
    disableSelection,
    forceEnableSelection,
    emptyStateText,
    setSelectedItems,
    groups,
    isLoading = false,
  } = props;

  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [applyFilters, setApplyFilters] = useState(true);
  const [filters, setFilters] = useState(null);

  const [selectedGroups, setSelectedGroups] = useState(groups);
  const [dataToDisplay, setDataToDisplay] = useState(data);

  useEffect(() => {
    setDataToDisplay(data);
  }, [data]);

  useEffect(() => {
    setSelectedGroups(groups);
  }, [groups]);

  useEffect(() => {
    if (!applyFilters || !groups.length) {
      return;
    }

    const storesIdsToDisplay = selectedGroups.reduce((acc, group) => {
      group.storeIds.forEach((storeId) => {
        acc.add(storeId);
      });

      return acc;
    }, new Set());

    setDataToDisplay(data.filter((item) => storesIdsToDisplay.has(item.id)));
  }, [applyFilters, selectedGroups, data]);

  return (
    <StoreListViewContainer>
      <ListView
        columns={columns}
        data={dataToDisplay}
        defaultOrderBy={defaultOrderBy}
        defaultOrderType={defaultOrderType}
        disableSelection={disableSelection}
        forceEnableSelection={forceEnableSelection}
        isLoading={isLoading}
        padding={'0px'}
        renderEmptyState={() => <EmptyState label={emptyStateText} />}
        renderFilterButton={() => {
          if (!groups.length || !data.length) {
            return null;
          }

          return (
            <DeepsightFiltersButton
              advancedFilters={[]}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              groups={groups}
              selectedGroups={selectedGroups}
              setAdvancedFilters={() => null}
              setApplyFilters={setApplyFilters}
              setColumnsFilterList={setColumnsFilterList}
              setFilters={setFilters}
              setSelectedGroups={setSelectedGroups}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              disableAdvancedFilter
            />
          );
        }}
        setSelectedItems={setSelectedItems}
        disableFooter
      />
    </StoreListViewContainer>
  );
};

export default SupplierProfileModalAssociations;
