import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS from '@commons/utils/makeXLS';

import {
  getAssociatedOrdersSheet,
  getGapsSheet,
  getICLExportListSheet,
  getICLInformationSheet,
  getICLOcerizationSheet,
} from './sheets';

// ICL list export
export const exportICLList = async (formatICLsListData, currency, clientStoreName) => {
  const filename = i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_FILE_NAME', {
    date: moment().format(DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR),
  });

  const exportListSheet = getICLExportListSheet(formatICLsListData, clientStoreName, currency);

  utilsXLS.makeXLS(filename, [exportListSheet]);
};

// Ocerization export
export const exportICLsOcerization = (
  formattedICLsOcerizationData,
  formatICLsListData,
  clientStoreName,
  currency,
  { customFileName = null } = {},
) => {
  const fileName =
    customFileName || i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_OCERIZATION_FILENAME');

  const ocerizationSheet = getICLOcerizationSheet(
    formattedICLsOcerizationData,
    clientStoreName,
    currency,
  );

  const iclListSheet = getICLExportListSheet(formatICLsListData, clientStoreName, currency, {
    title: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_SECOND_SHEET_NAME'),
  });

  utilsXLS.makeXLS(fileName, [ocerizationSheet, iclListSheet]);
};

// Gap Analysis export
export const exportGapAnalysis = (
  formattedICLsGapsData,
  formattedICLsInformationData,
  formattedAssociatedOrdersData,
  clientStoreName,
  currency,
  { customFileName = null } = {},
) => {
  const fileName =
    customFileName || i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_ANALYSIS_FILENAME');

  const gapsSheet = getGapsSheet(formattedICLsGapsData, currency);

  const iclsInformationSheet = getICLInformationSheet(
    formattedICLsInformationData,
    clientStoreName,
    currency,
  );

  const associatedOrdersSheet = getAssociatedOrdersSheet(
    formattedAssociatedOrdersData,
    clientStoreName,
    currency,
  );

  utilsXLS.makeXLS(fileName, [gapsSheet, iclsInformationSheet, associatedOrdersSheet]);
};
